import { useRef } from "react";
import { message } from "antd";

/*
  Coté client, on réduit à 3 cas possibles : 
    1) Non archivé, et échéance non dépassée -> affichage normal (LeadActif)
    2) J+1 < échéance < J+30 -> affichage en lecture seule des offres + incitation à republier (LeadObsolete)
    3) échéance > J+30 -> affichage "cet espace client n'existe pas (LeadArchive) 
  */

function useClient({ useJsqel, match }) {
  const lien_unique = match.params.id_client || "id-inconnu";

  const [sources, refreshSources] = useJsqel("client/sources", {
    sendItNow: false,
  });

  const [lead, refreshLead] = useJsqel("client/lead", {
    sendItNow: true,
    client: lien_unique,
  });

  const [offres, refreshOffres] = useJsqel("client/offres", {
    sendItNow: true,
    client: lien_unique,
    callback: ({ results }) =>
      results &&
      results.length &&
      refreshSources({ source_id: results[0].source_id }),
  });

  const offreValidee = (offres?.results || []).some(
    (o) => o.etat === "selectionne"
  );

  const [, setLeadStateToArchived] = useJsqel(
    "client/set_lead_state_to_archived",
    {
      sendItNow: false,
      client: lien_unique,
      callback: () => refreshLead(),
    }
  );

  const apresValidation = ({ error }) => {
    if (error)
      message.error(
        "Erreur lors de la validation de l'offre. Merci de prendre contact avec ID Home"
      );
    else {
      message.success("Nous avons pris en compte votre choix.");
      refreshOffres();
    }
  };

  const [, mettreAJourChoixClient] = useJsqel("client/choix_offre", {
    sendItNow: false,
    callback: apresValidation,
  });

  const aideEnLigne = useRef();

  // Fonction de tri des devis :
  // D'abord les premium, puis les id
  const triDevis = (d1, d2) =>
    d1.premium ? -5 : d1.devis_id < d2.devis_id ? -1 : 1;

  // calcul de l'échéance
  const echeance =
    lead.results && lead.results.length
      ? Math.round(
          (new Date(lead.results[0].echeance) - new Date()) /
            (1000 * 60 * 60 * 24)
        )
      : 0;
  const echeanceDepasse = echeance < -1;

  // Etat du lead
  const etatLead =
    (lead.results && lead.results.length) > 0 ? lead.results[0].etat : "";

  // Prénom du Lead
  const prenomLead =
    (lead.results && lead.results.length) > 0 ? lead.results[0].prenom : "";

  const scrollToAide = () =>
    window.scrollTo({
      top: aideEnLigne.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  const scrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const actions = {
    scrollToAide,
    scrollToTop,
    mettreAJourChoixClient,
    triDevis,
    archiverLead: setLeadStateToArchived,
  };

  return {
    etatLead,
    prenomLead,
    offres,
    sources,
    echeanceDepasse,
    lien_unique,
    refreshOffres,
    echeance,
    actions,
    aideEnLigne,
    etatAffichageLead: calculerEtatAffichageLead(etatLead, echeance),
    offreValidee,
  };
}

export { useClient };

function calculerEtatAffichageLead(etatLead, echeance) {
  if (echeance < -30) return "LeadArchive";
  if (etatLead === "archive" || echeance < -1) return "LeadObsolete";
  return "LeadActif";
}
