import React from "react";
import { Popconfirm, Button } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import { useStore } from "../../../store/store";
import { useMobile } from "../../../helpers/helpers";
import { Link } from "react-router-dom";

const MessageConfirmation = ({ prix }) => (
  <span>
    En participant à cette demande, vous allez être débité de {prix} crédits.
    <br />
    Les coordonnées du client seront placées dans l’onglet "Mes Contacts".
    <br /> Confirmez-vous cette action ?
  </span>
);

const BoutonAchatLead = ({ prix, actions, loading, id }) => {
  const { state } = useStore();
  const solde = state.solde;

  const { isMobile } = useMobile();

  if (Number.parseFloat(solde) < prix)
    return (
      <Link to="/packs">
        <Button loading={loading} className="bouton-participe">
          {isMobile ? prix + " crédits" : "Solde insuffisant"}
        </Button>
      </Link>
    );

  return (
    <Popconfirm
      title={<MessageConfirmation prix={prix} />}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={() =>
        id ? actions.refreshAchatLeads(id) : actions.refreshAchatLeads()
      }
      cancelText="Non"
      okText="Oui je confirme"
      placement="top"
    >
      <Button loading={loading} className="bouton-participe">
        Acheter
      </Button>
    </Popconfirm>
  );
};

export default BoutonAchatLead;
