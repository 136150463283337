import React from 'react';
import { Spin } from 'antd';

const Loading = props => {
  if (props.error) {
    return <div className="loader-container">Error! Please refresh the page</div>;
  } else if (props.pastDelay) {
    return <div className="loader-container"> <Spin size="large" /> </div>;
  } else {
    return null; // Avoiding Flash Of Loading Component (<200ms)
  }
}

export default Loading;

