import React from "react";
import { Row, Col, Button, Card, Empty } from "antd";
import RedoOutlined from "@ant-design/icons/RedoOutlined";
import TitreSection from "../../layout/TitreSection";
import DemandesEcarteesHelper from "./DemandesEcarteesHelper";
import { Redirect } from "react-router-dom";
import DetailLeadEcarte from "./DetailLeadEcarte";

const DemandesEcartesLayout = (props) => {
  const { actions, loading, listeLeads, error, idleadDetail } = props;

  const rowStyleMargin = { marginTop: "1rem" };
  if (error) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <Row
        type="flex"
        justify="space-between"
        align="stretch"
        style={{ marginBottom: "1rem" }}
      >
        <Col xs={24} xl={9} style={{ alignSelf: "flex-end" }}>
          <Row gutter={{ md: 4, xl: 16 }}>
            <Col style={rowStyleMargin}>
              <TitreSection
                titre="Demandes écartées"
                accroche="Participez à ces demandes"
              />
            </Col>
          </Row>
        </Col>

        <Col xs={0} xl={8}>
          <DemandesEcarteesHelper nb_leads={listeLeads.length} />
        </Col>
      </Row>
      <Card loading={loading}>
        {listeLeads.length ? (
          listeLeads.map((l) => (
            <DetailLeadEcarte
              key={l.id}
              record={l}
              loading={loading}
              actions={actions}
              detailVisible={idleadDetail === l.id}
            />
          ))
        ) : (
          <Empty
            description={
              <div>
                <h3>Il n'a pas encore de demande écartée.</h3>
                <p>
                  Vous trouverez dans cette liste toutes les demandes
                  correspondant à vos critères. Il est encore temps de changer
                  d'avis !
                </p>
              </div>
            }
          />
        )}
      </Card>
    </div>
  );
};

export default DemandesEcartesLayout;
