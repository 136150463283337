import React, { useState } from "react";
import { Redirect } from "react-router";
import { Button, Tooltip } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import FormOutlined from "@ant-design/icons/FormOutlined";
import SyncOutlined from "@ant-design/icons/SyncOutlined";
import SmileOutlined from "@ant-design/icons/SmileOutlined";
import FrownOutlined from "@ant-design/icons/FrownOutlined";
import WarningOutlined from "@ant-design/icons/WarningOutlined";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";

const TooltipBoutonOffre = ({ libelle }) => (
  <>
    &nbsp;
    <Tooltip title={libelle}>
      <QuestionCircleOutlined />
    </Tooltip>
  </>
);

const BoutonOffre = ({ devis_id, etat, size = "middle" }) => {
  const [faireOffre, setFaireOffre] = useState(false);

  if (faireOffre) return <Redirect push to={"/offre/" + devis_id} />;

  switch (etat) {
    case "achete":
      return (
        <span>
          <Button
            type="primary"
            icon={<FormOutlined />}
            onClick={() => setFaireOffre(true)}
            size={size}
          >
            <span className="show-desktop-hide-mobile">Faire une offre</span>{" "}
          </Button>
          <TooltipBoutonOffre libelle="Vous devez envoyer votre offre au client en cliquant sur ce bouton. Vous pouvez joindre votre devis au format PDF." />
        </span>
      );

    case "devis_envoye":
      return (
        <span>
          <Button
            icon={<SyncOutlined />}
            type="primary"
            onClick={() => setFaireOffre(true)}
            style={{ backgroundColor: "orange", borderColor: "orange" }}
            size={size}
          >
            <span className="show-desktop-hide-mobile">Offre envoyée</span>
          </Button>
          <TooltipBoutonOffre libelle="Votre offre a été envoyée au client. Vous pouvez la modifier tant que le client n’a pas sélectionné une offre." />
        </span>
      );

    case "selectionne":
      return (
        <span>
          <Button
            icon={<SmileOutlined />}
            type="primary"
            onClick={() => setFaireOffre(true)}
            style={{ backgroundColor: "green", borderColor: "green" }}
            size={size}
          >
            <span className="show-desktop-hide-mobile">Offre acceptée</span>
          </Button>
          <TooltipBoutonOffre libelle="Votre offre a été acceptée par le client. Contactez le pour finaliser votre intervention." />
        </span>
      );
    case "refus":
      return (
        <span>
          <Button
            icon={<FrownOutlined />}
            type="primary"
            style={{ backgroundColor: "red", borderColor: "red" }}
            size={size}
          >
            <span className="show-desktop-hide-mobile">Demande terminée</span>{" "}
          </Button>
          <TooltipBoutonOffre libelle="Cette demande n'est plus active. Le client a refusé votre offre ou il a mis fin à sa demande." />
        </span>
      );
    case "urgent":
      return (
        <span>
          <Button
            icon={<WarningOutlined />}
            type="primary"
            onClick={() => setFaireOffre(true)}
            style={{ backgroundColor: "red", borderColor: "red" }}
            size={size}
          >
            <span className="show-desktop-hide-mobile">
              Urgent - Faire une offre
            </span>{" "}
          </Button>
          <TooltipBoutonOffre libelle="Dépêchez-vous de faire votre offre au client, un concurrent vient d'envoyer son devis au client. Si le client accepte son offre immédiatement, vous ne pourrez pas envoyer votre offre." />
        </span>
      );
    case "timesup":
      return (
        <span>
          <Button
            icon={<ClockCircleOutlined />}
            type="primary"
            style={{ backgroundColor: "#bec8c9", borderColor: "#bec8c9" }}
            size={size}
          >
            <span className="show-desktop-hide-mobile">Time's up</span>{" "}
          </Button>
          <TooltipBoutonOffre libelle="Dommage, un concurrent vient de remporter cette offre auprès du client. Soyez plus réactif une prochaine fois pour envoyer votre devis." />
        </span>
      );

    default:
      return <div>Etat de l'offre non géré</div>;
  }
};

export default BoutonOffre;
