import React from "react";
import { Button, Row, Col, Card, Rate } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import ValiderOffre from "./ValiderOffre";

const DetailOffreFichier = ({ fichier }) => (
  <p>
    <a href={"/uploads/" + fichier} target="_blank" rel="noopener noreferrer">
      <Button>
        {" "}
        <SearchOutlined /> Détail de l'offre{" "}
      </Button>
    </a>
  </p>
);

const OffreClient = ({ offre, actions, lectureSeule }) => {
  const {
    id_client,
    devis_id,
    etat,
    entreprise,
    nb_devis,
    nb_devis_remportes,
    annee_creation,
    forme_juridique,
    code_postal,
    ville,
    telephone,
    email,
    remarques,
    prix,
    devise,
    tarification,
    fichier,
    premium,
    profil,
    numero,
    score,
  } = offre;
  const styleCarte = premium
    ? {
        marginBottom: "1rem",
        backgroundColor: "rgba(1, 188, 212, 0.08)",
        borderColor: "#ffc53d",
      }
    : { marginBottom: "1rem", backgroundColor: "#F5F5F5" };

  return (
    <Card
      className={
        premium ? "ribbon-container ribbon-warning " : "ribbon-container"
      }
      style={styleCarte}
    >
      <div className="ribbon-wrapper">
        <div className="ribbon"> Offre n° {numero} </div>
      </div>

      <Row gutter={16} type="flex">
        <Col xs={24} xl={8} className="col-md-border">
          <h3 className="article-title-idhome">{entreprise}</h3>
          <div className="divider border-primary mb-4"></div>
          {profil && (
            <img
              src={"/uploads/" + profil}
              alt={entreprise}
              style={{ maxWidth: "200px", height: "auto" }}
            />
          )}
          <div>Nombre de participations : {nb_devis}</div>
          {score > 0 ? (
            <>
              {" "}
              Avis : <Rate disabled defaultValue={score} /> ({score} / 5)
            </>
          ) : (
            <></>
          )}
          <br />
          <div>
            <b>Détails de l'entreprise</b>
          </div>
          <div className="divider divider-short border-primary mb-4"></div>
          <div>Nom : {entreprise}</div>
          <div>Date de création : {annee_creation}</div>
          {forme_juridique && <div>Forme juridique : {forme_juridique}</div>}
          <p>
            Adresse : {code_postal} {ville}
          </p>
          <div>Tél : {telephone}</div>
          <div>Email : {email}</div>
        </Col>

        <Col xs={24} xl={8} className="col-md-border">
          <h3 className="article-title-idhome">Commentaires</h3>
          <div className="divider border-primary mb-4"></div>
          <p>
            {remarques &&
              remarques.split("\n").map((p, i) => (
                <span key={i}>
                  {p}
                  <br />
                </span>
              ))}
          </p>
        </Col>

        <Col xs={24} xl={8}>
          <h3 className="article-title-idhome">Montant de l'offre</h3>
          <div className="divider border-primary mb-4"></div>
          {etat === "achete" ? (
            <p>Cette entreprise peut prendre contact avec vous.</p>
          ) : prix > 0 ? (
            <p>
              <Button>
                {prix} {devise}
              </Button>
              &nbsp; {tarification}
            </p>
          ) : (
            <p>Le montant n'a pas été renseigné.</p>
          )}
          {fichier && <DetailOffreFichier fichier={fichier} />}
          <p>
            <ValiderOffre
              mettreAJourChoixClient={actions.mettreAJourChoixClient}
              etat={etat}
              client={id_client}
              devis={devis_id}
              lectureSeule={lectureSeule}
            />
          </p>
          {premium && <p style={{ color: "#ffc53d" }}>PARTENAIRE PREMIUM</p>}
        </Col>
      </Row>
    </Card>
  );
};

export default OffreClient;
