import React from 'react';
import AideEnLigne from './AideEnLigne'

// Ce serait mieux de placer ces données en BD
const aideEnLigneFournisseur = [
    { id:1, gauche:false, image:"assets/faq-fonctionnement-pro.jpg"},
    { id:2, gauche:true, image:"assets/faq-relation-client-pro.jpg"},
    { id:3, gauche:false, image:"assets/faq-compte-utilisateur.jpg"},
    { id:4, gauche:true, image:"assets/faq-prix-pro.jpg"},
]

const AideEnLignePro = () => <AideEnLigne data={aideEnLigneFournisseur} menu={true}/>

export default AideEnLignePro

