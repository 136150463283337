import React from "react";
import { Button } from "antd";
import FilePdfOutlined from "@ant-design/icons/FilePdfOutlined";
import { downloadPDF } from "../../api/downloadPDF";

const LienFacturePdf = (props) => (
  <div>
    <Button
      type="danger"
      icon={<FilePdfOutlined />}
      disabled={!props.fichier_genere}
      onClick={() =>
        downloadPDF({
          fileName: props.nom_fichier,
          routeAPI: `factures/pdf?id=${props.id}`,
        })
      }
    >
      Télécharger en PDF
    </Button>
  </div>
);

export default LienFacturePdf;
