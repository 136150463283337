import React from "react";
import { Row, Col, Avatar } from "antd";
import BoutonAchatLead from "../../demandes/Presentation/BoutonAchatLead";
import BoutonAfficherLead from "./BoutonAfficherLead";
import BoutonAchatCredits from "../../demandes/Presentation/BoutonAchatCredits";

const style = {
  header: {
    marginLeft: "-2px",
    marginRight: "-2px",
    background: "#EEEEEE",
    padding: "16px 8px",
    alignItems: "center",
  },
  badge: {
    borderRadius: "50px",
    display: "flex",
    margin: "auto",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#1b90fb",
    width: "70px",
    height: "70px",
    color: "#FFF",
    textAlign: "center",
  },
  prestation: {
    fontSize: 18,
    fontWeight: "500",
    margin: "auto",
    color: "#000",
  },
  info: {
    margin: "auto",
    color: "rgb(74, 74, 74)",
    fontWeight: "400",
  },
};

const HeaderLeadEcarte = ({
  id,
  prestation,
  logo_prestation,
  code_postal_intervention,
  ville_intervention,
  actions,
  credit,
  loading,
  echeance,
}) => {
  return (
    <Row style={style.header} gutter={4} justify="center" align="middle">
      <Col xs={24} md={4} xl={2}>
        <Avatar
          shape="square"
          src={`/uploads/${logo_prestation}`}
          style={{ width: "75px", height: "75px" }}
        />
      </Col>
      <Col xs={24} md={20} xl={10}>
        <p style={style.info}>
          Réf. {id} - Echéance{" "}
          {echeance && new Date(echeance).toLocaleDateString()}
        </p>
        <p style={style.prestation}>{prestation}</p>
        <p style={style.info}>
          {code_postal_intervention} {ville_intervention}
        </p>
      </Col>
      <Col xs={24} md={10} xl={12}>
        <Row justify="center" align="middle" gutter={[8, 8]}>
          <Col>
            <BoutonAchatLead
              prix={credit}
              actions={actions}
              loading={loading}
              id={id}
            />
          </Col>
          <Col>
            <BoutonAfficherLead actions={actions} loading={loading} id={id} />
          </Col>
          <Col xs={24} md={24} xl={8}>
            <BoutonAchatCredits credit={credit} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderLeadEcarte;
