import React from "react";
import { Row, Col, Modal } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Cadenas from "../../layout/Cadenas";
import HeaderLeadEcarte from "./HeaderLeadEcarte";

const complementInfoFraisValidation = () =>
  Modal.info({
    title: "A quoi correspondent ces frais ?",
    content: (
      <div>
        <p>
          Lorsqu’un client valide l’offre d’une entreprise, des frais de
          traitement sont déduits à l’entreprise.
        </p>
        <p>
          Ces frais sont exprimés en nombre de Crédits, et sont uniquement
          appliqués à l’entreprise ayant remporté le mandat auprès du client
        </p>
      </div>
    ),
    onOk() {},
    okText: "Compris !",
  });

const splitTexte = (texte) => {
  const splitted1 = texte.split("\n");
  const splitted2 = texte.split(String.fromCharCode(10));
  return splitted1.length > splitted2.length ? splitted1 : splitted2;
};

const style = {
  titre: {
    color: "rgba(49, 49, 49, 0.85",
    fontWeight: "700",
    padding: "16px 8px",
  },
  header: {
    marginLeft: "-2px",
    marginRight: "-2px",
    background: "#EEEEEE",
    padding: "16px 8px",
    alignItems: "center",
  },
  section: { color: "#0a84b7", fontWeight: "700", marginTop: "1rem" },
  compteur: {
    marginTop: "1rem",
    background: "#fafafa",
    padding: "1rem",
    borderRadius: "5px",
    marginRight: "auto",
  },
};
const DetailLeadEcarte = ({ record, actions, loading, detailVisible }) => {
  return (
    <>
      <HeaderLeadEcarte
        id={record.id}
        prestation={record.prestation}
        logo_prestation={record.vignette}
        code_postal_intervention={record.code_postal_intervention}
        ville_intervention={record.ville_intervention}
        actions={actions}
        credit={record.prix}
        loading={loading}
        echeance={record.echeance}
      />
      {detailVisible && (
        <Row>
          <Col xs={24} xl={6}>
            <Row style={style.section}>Dates et échéances</Row>
            <Row>
              <b>Publication de la demande :</b>{" "}
              {new Date(record.date_validation).toLocaleDateString()}
            </Row>
            <Row>
              <b>Echéance du client :</b>{" "}
              {new Date(record.echeance).toLocaleDateString()}
            </Row>
            <Row style={style.section}>Informations</Row>

            <Row align="middle">
              <QuestionCircleOutlined onClick={complementInfoFraisValidation} />{" "}
              &nbsp; <b>Frais de validation :</b>
              {record.prix_commission || 0} crédits
            </Row>
          </Col>

          <Col xs={24} xl={7}>
            <Row style={style.section}>Coordonnées du client</Row>
            <Row>
              <b>Nom : </b>
              <Cadenas />
            </Row>
            <Row>
              <b>Prénom : </b>
              <Cadenas />
            </Row>
            <Row>
              <b>N° de téléphone : </b>
              <Cadenas />
            </Row>
            <Row>
              <b>Email : </b>
              <Cadenas />
            </Row>
            <Row>
              <b>Adresse : </b>
              <Cadenas />
            </Row>
            {record.ville_destination && (
              <Row>
                <b>Adresse de destination : </b>
                <Cadenas />
              </Row>
            )}
            {record.ville_destination && (
              <Row>
                <b>Ville de destination :</b> {record.ville_destination}
              </Row>
            )}
            {record.code_postal_destination && (
              <Row>
                <b>Code postal de destination : </b>{" "}
                {record.code_postal_destination}
              </Row>
            )}
          </Col>
          <Col xs={24} xl={11}>
            <Row style={style.section}>Informations du client</Row>
            <Row>
              <Col>
                {record.detail
                  ? splitTexte(record.detail).map((l, i) => (
                      <div key={i}>{l}</div>
                    ))
                  : "Pas de détail"}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <br />
    </>
  );
};

export default DetailLeadEcarte;
