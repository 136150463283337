import React from "react";
import { Result, Button } from "antd";
import FrownOutlined from "@ant-design/icons/FrownOutlined";
import { t } from "../../../../Internationalisation/traduction";

const LienSite = () => {
  return (
    <>
      <div>
        Si vous souhaitez republier votre demande pour 10 jours, adressez-nous
        un email à l'adresse suivante :
      </div>
      <br />
      <Button
        type="primary"
        href={"mailto:" + t("fr", "email_site")}
        alt={t("fr", "marque")}
      >
        {t("fr", "email_site")}
      </Button>
    </>
  );
};

const RepublierAnnonce = () => (
  <Result
    title="Désolé que vous n'ayez pas trouvé de prestataire pour votre demande."
    subTitle={<LienSite />}
    icon={<FrownOutlined style={{ color: "#fd8200" }} />}
    style={{ height: "60vh" }}
  />
);

export default RepublierAnnonce;
