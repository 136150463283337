import React from "react";

const BoutonAchatCredits = ({ credit }) => (
  <div style={{ textAlign: "right", width: "100%" }}>    
      <div className="ant-btn bouton-achat-credits">
        {credit} {credit > 1 ? "crédits" : "crédit"}
      </div>
   
  </div>
);

export default BoutonAchatCredits;
