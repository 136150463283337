import React from "react";
import { Link } from "react-router-dom";
import { Menu, Badge } from "antd";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
import BarsOutlined from "@ant-design/icons/BarsOutlined";
import ScheduleOutlined from "@ant-design/icons/ScheduleOutlined";
import WalletOutlined from "@ant-design/icons/WalletOutlined";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import RetweetOutlined from "@ant-design/icons/RetweetOutlined";

import { useStore } from "../../store/store";
import { t } from "../../Internationalisation/traduction";

const SideNavMenu = ({ selectedMenu, clicCallback }) => {
  const { state } = useStore();
  const achat_website = !!(state?.achat_website)
  return (
    <Menu
      
      mode="inline"
      selectedKeys={[selectedMenu]}
      defaultSelectedKeys={["/dashboard"]}
      className="bg-idhome-side-menu"
    >
      <Menu.Item
        key="/dashboard"
        onClick={() => clicCallback && clicCallback()}
      >
        <Link to="/dashboard">
          <HomeOutlined />
          <span className="nav-text">Dashboard</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/devis" onClick={() => clicCallback && clicCallback()}>
        <Link to="/devis">
          <BarsOutlined />
          <span className="nav-text">Demandes disponibles</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/refus" onClick={() => clicCallback && clicCallback()}>
        <Link to="/refus">
          <RetweetOutlined />
          <span className="nav-text">Demandes écartées</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/achats" onClick={() => clicCallback && clicCallback()}>
        <Link to="/achats">
          <ScheduleOutlined />
          <span className="nav-text">Mes contacts </span>
          <Badge count={state.achete || 0}></Badge>
        </Link>
      </Menu.Item>
      <Menu.Item key="/packs" onClick={() => clicCallback && clicCallback()}>
        <Link to="/packs">
          <WalletOutlined />
          <span className="nav-text">{t("fr", "titre_menu_credit", "Créditer mon Compte")}</span>
        </Link>
      </Menu.Item>
      {achat_website ? <Menu.Item key="/website" onClick={() => clicCallback && clicCallback()}>
        <Link to="/website">
          <ScheduleOutlined />
          <span className="nav-text">My website </span>
        </Link>
      </Menu.Item>: null}
      <Menu.Item key="/messages" onClick={() => clicCallback && clicCallback()}>
        <Link to="/messages">
          <MessageOutlined />
          <span className="nav-text">Support par chat</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/support" onClick={() => clicCallback && clicCallback()}>
        <Link to="/support">
          <InfoCircleOutlined />
          <span className="nav-text">FAQs</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default SideNavMenu;
