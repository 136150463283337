import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Button, Popconfirm, Card } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

import ChangementMotPasse from "./ChangementmotPasse";
import MesNotifications from "./MesNotifications";
import { useJsqel } from "../../api/jsqel";
import { useStore } from "../../store/store";

const MesParametres = () => {
  const { dispatch } = useStore();
  const [compteSupprime, setCompteSupprime] = useState(false);

  const apresSuppressionCompte = ({ error }) => {
    dispatch({ type: "LOGOUT" });
    setCompteSupprime(true);
  };

  const [{ loading }, refresh] = useJsqel("inscription/supprime_compte", {
    sendItNow: false,
    callback: apresSuppressionCompte,
  });

  if (compteSupprime) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <Row type="flex" gutter={16} style={{ marginBottom: "1rem" }}>
        <Col xs={24} xl={12}>
          <MesNotifications />
        </Col>
        <Col xs={24} xl={12}>
          <ChangementMotPasse />
        </Col>
      </Row>

      <div className="callout callout-danger">
        <h4>Supprimer mon compte</h4>
        <p>
          Attention ! En supprimant votre compte, l’accès à votre compte et à
          vos factures seront supprimées.
          <br />
          Si vous possédez un solde restant sur le site, ce dernier ne vous sera
          pas rétrocédé.
          <br />
          Cette action est irréversible, nous ne pourrons pas récupérer votre
          compte après sa suppression.
        </p>
        <Popconfirm
          title={`Vous allez supprimer votre compte. cette action est IRREVERSIBLE  Confirmez-vous ?`}
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={() => refresh()}
          cancelText="Annuler"
        >
          <Button loading={loading} type="danger">
            Supprimer mon compte
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default MesParametres;
