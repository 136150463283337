import React from "react";
import Cover from "../../layout/Cover";

export const PacksCoverWebsite = () => (
  <Cover
    img="/assets/banner-pack-website.png"
    hauteur_top_em="2"
    positionTexte="droite"
    titre={<span className="titre-pack-website">
      Nous créons votre site web
      <br />
      <img
        src="/assets/logo-paiement.png"
        alt="Paiement par CB et Bancontact" />
    </span>}
    extra={<span className="soustitre-pack-website">
      Votre site internet optimisé pour mobiles, tablettes et ordinateurs.
      <br />
      Développez facilement votre présence en ligne à moindre coût.
      <br />
      <br />
      All inclusive : hébergement, mise à jour, sécurisation.
    </span>} />
);
