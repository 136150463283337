import React from "react";
import ReactMarkdown from "react-markdown";

const InformationsClients = ({ detail }) => (
  <ReactMarkdown
    components={{
      p({ node, className, children, ...props }) {
        return (
          <p
            className={className}
            {...props}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {children}
          </p>
        );
      },
      li({ node, className, children, ...props }) {
        return (
          <li
            className={className}
            {...props}
            style={{ marginLeft: "1rem" }}
          >
            {children}
          </li>
        );
      },
    }}
  >
    {detail || ""}
  </ReactMarkdown>
);

export default InformationsClients;
