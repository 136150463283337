import React from "react";
import { Card, Select, Button } from "antd";
import PlusCircleTwoTone from "@ant-design/icons/PlusCircleTwoTone";

const { Option } = Select;
const cardStyle = { marginBottom: "1rem" };

const CardSelect = ({
  title,
  loading,
  onChange,
  value,
  list,
  editionEnCours,
  onSave,
  disabled,
  id,
}) => {
  return (
    <div id={id}>
      <Card
        title={title}
        style={cardStyle}
        extra={
          editionEnCours && (
            <Button type="primary" onClick={onSave}>
              Enregistrer
            </Button>
          )
        }
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          loading={loading}
          value={value}
          onChange={onChange}
          disabled={!!disabled}
          suffixIcon={
            <b>
              <PlusCircleTwoTone
                style={{ fontSize: 16, pointerEvents: "none" }}
                twoToneColor="#65ae43"
              />
            </b>
          }
          showArrow={true}
        >
          {list.map((p) => (
            <Option key={p.id} value={p.id}>
              {p.libelle}
            </Option>
          ))}
        </Select>
      </Card>
    </div>
  );
};

export default CardSelect;
