import { Modal } from "antd";
import { t } from "../../../Internationalisation/traduction";

export const complementInfoSpam = () =>
  Modal.info({
    title: "Bien recevoir tous les mails.",
    content: (
      <div>
        <p>
          Selon votre boîte email, il peut arriver que nos demandes atterrissent
          dans le dossier Courrier indésirable (également appelé <b>spam</b>) de
          votre messagerie.
          <br />
          Les algorithmes de qualification des messageries apprennent en continu
          et peuvent faire des erreurs.
        </p>
        <p>
          Afin d’éviter que nos demandes de travaux ne terminent dans votre
          dossier Spam et pour être tenu informé au quotidien de nos nouvelles
          demandes clients, il est important que vous acceptiez notre email{" "}
          <b>{t("fr", "email_devis")}</b> comme l’un de vos contacts de
          messagerie.
        </p>
        <p>
          Nous vous expliquons dans nos{" "}
          <a href="/support" alt="FAQ">
            FAQs
          </a>{" "}
          comment faire sur chaque messagerie pour que nos demandes e-mails
          n'atterrissent plus dans vos spams.
        </p>
      </div>
    ),
    onOk() {},
    okText: "Compris !",
  });
