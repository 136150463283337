import React from "react";
import { Badge, Card, Col, Row } from "antd";
import CheckOutlined from "@ant-design/icons/CheckOutlined";

const stylebadge = {
  borderRadius: "50px",
  display: "flex",
  margin: "auto",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#65ae43",
  width: "70px",
  height: "70px",
  color: "#FFF",
  textAlign: "center",
};

const DemandesEcarteesHelper = ({ nb_leads }) => {
  if (nb_leads === 0) {
    return <></>;
  }

  return (
    <Card style={{ height: "100%" }}>
      <Row justify="space-around" align="middle" gutter={[16, 16]}>
        <Col md={24} xl={24}>
          <b>Etes-vous sûr de refuser {nb_leads > 1 ? "ces demandes" : "cette demande"} ?</b>
          <p>
            <span style={{ color: "green" }}>✓ Participer </span>
            pour obtenir les coordonnées du client.
          </p>
        </Col>
      </Row>
    </Card>
  );
};

export default DemandesEcarteesHelper;
