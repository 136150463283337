import React from "react";
import { Card } from "antd";
import {
  StarFilled,
  EyeOutlined,
  CheckCircleOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const ReservezEnLigne = () => {
  return (
    <Card>
      <h3 className="article-title-idhome">
        <StarFilled style={{ color: "#82B53F" }} /> Réservez en ligne !
      </h3>
      <div className="divider border-primary mb-4 mt-3"></div>
      <p>
        <EyeOutlined /> En un clin d'oeil, accédez à toutes les informations
        pour choisir votre futur prestataire sereinement
      </p>
      <p>
        <CheckCircleOutlined /> Choisissez votre entreprise en un clic
      </p>
      <p>
        <PhoneOutlined /> Si votre demande est urgente ou que vous êtes
        difficilement joignable, nous vous invitons à contacter les
        prestataires.
      </p>
    </Card>
  );
};

export default ReservezEnLigne;
