import React from "react";
import { Upload, Button, message } from "antd";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import urlApi from "../../../api/url";

/* **********************************************
 ** Input field customisé pour gérer une gallerie
 ** d'images.
 ** L'état de l'input est une liste de noms de
 ** fichiers (sans le chemin)
 ** **********************************************/

function UploadGalleryInput({ value, onChange, repertoireUpload = "uploads/website" }) {
  const token = window.localStorage.getItem("idhome_token");
  const headers = { Authorization: `Bearer ${token}` };

  const listeFichiers = (value || []).map((fichier) => ({
    uid: fichier,
    name: fichier,
    status: "done",
    url: `/${repertoireUpload}/${fichier}`,
  }));

  const ajoutImage = (listeModifiee) => {
    const imagesFormulaire = listeModifiee?.fileList;
    const nouvellesImages =
      Array.isArray(imagesFormulaire) && imagesFormulaire.length
        ? imagesFormulaire
            .map(
              (image) =>
                image?.status === "done" &&
                (image?.response?.filename || image?.name)
            )
            .filter(Boolean)
        : [];

    onChange(nouvellesImages);
  };

  return (
    <Upload
      name="website"
      listType="picture"
      defaultFileList={[...listeFichiers]}
      headers={headers}
      action={urlApi() + "website/upload"}
      beforeUpload={beforeUpload}
      onChange={ajoutImage}
    >
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Seul le format PNG ou JPG est autorisé");
  }
  const isLt3M = file.size / 1024 / 1024 < 3;
  if (!isLt3M) {
    message.error("La taille max de l'image est de 3Mo");
  }
  return isJpgOrPng && isLt3M;
}

export default UploadGalleryInput;
