import React from "react";
import { Button } from "antd";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";

const CustomExpandIcon = (props) => (
  <Button
    type="primary"
    className="expand-row-icon"
    style={{
      backgroundColor: "#65ae43",
      borderColor: "#65ae43",
      width: "100%",
    }}
    onClick={(e) => props.onExpand(props.record, e)}
    icon={props.expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
  >
    Détail
  </Button>
);

export default CustomExpandIcon;
