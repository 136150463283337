import React from 'react';
import { Redirect } from "react-router-dom"
import { useStore } from '../../store/store'

const Logout = () => {
    const {dispatch} = useStore()
    dispatch({type:"LOGOUT"})
    return <Redirect to='/login' />
}

export default Logout
