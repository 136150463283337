import { useEffect, useState } from "react";

const pad = (num, size) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

// const isMobileDevice = () => (window.innerWidth <= 765)

const useMobile = () => {
  const isClient = typeof window === "object";
  const [size, setSize] = useState(
    isClient ? { isMobile: window.innerWidth <= 765, width: window.innerWidth, height: window.innerHeight } : undefined
  );

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    const checkSize = () =>
      isClient
        ? { isMobile: window.innerWidth <= 765, width: window.innerWidth, height: window.innerHeight }
        : undefined;
    const handleResize = () => setSize(checkSize);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient]);

  return size;
};

function uneDecimale(x) {
  return Number.parseFloat(x).toFixed(1);
}

function monetaire(x) {
  return Number.parseFloat(x).toFixed(2);
}

export { pad, useMobile, uneDecimale, monetaire };
