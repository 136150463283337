import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Spin, Row, Col } from "antd";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import { useJsqel } from "../../api/jsqel";
import TitreSection from "../layout/TitreSection";

import NouveauMessage from "./NouveauMessage";
import { t } from "../../Internationalisation/traduction";

const TimelineMessage = ({ id, recu, date_envoi, libelle }) => (
  <article className={"tl-item" + (recu ? " alt" : "")}>
    <div className="tl-body">
      <div className="tl-entry">
        <div className="d-none d-md-flex tl-time">
          {new Date(date_envoi).toLocaleString()}{" "}
        </div>
        <div className="tl-icon icon-btn-round icon-btn icon-btn-thin btn-info">
          <MessageOutlined />
        </div>
        <div className="tl-content">
          <h4 className="tl-title text-primary">
            {recu ? "Mon message" : "Réponse de " + t("fr", "marque")}
          </h4>
          <p>{libelle}</p>
        </div>
      </div>
    </div>
  </article>
);

const Timeline = () => {
  const [{ results, error, loading }, refresh] = useJsqel(
    "chat/chat_messages",
    { sendItNow: true }
  );
  const [fermeture, setFermeture] = useState(true);

  const handleFermetureNouveauMessage = (etat) => {
    setFermeture(etat);
    refresh();
  };

  if (error) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <Row
        type="flex"
        justify="space-between"
        align="bottom"
        style={{ marginBottom: "1rem" }}
      >
        <Col xs={24} xl={16}>
          <Row gutter={{ md: 4, xl: 16 }}>
            <Col>
              <TitreSection
                titre="Support par Chat"
                accroche="Dialoguez en ligne avec notre support"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="container-fluid container-mw-xl py-3">
        <section className="ui-timeline">
          <article className="tl-item">
            <div className="tl-body">
              <div className="tl-entry">
                <div className="tl-caption">
                  {loading ? (
                    <Spin />
                  ) : (
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => setFermeture(false)}
                    >
                      Ecrire un message
                    </button>
                  )}
                </div>
              </div>
            </div>
          </article>
          <NouveauMessage
            fermeture={fermeture}
            setFermeture={handleFermetureNouveauMessage}
          />

          {results && results.length
            ? results.map((r) => <TimelineMessage key={r.id} {...r} />)
            : null}
        </section>
      </div>
    </div>
  );
};

export default Timeline;
