import React from "react";
import { Button, message } from "antd";
import CopyOutlined from "@ant-design/icons/CopyOutlined";

const CopierDansClipboard = ({ valeur }) => {
  if (!valeur) return null;
  return (
    <Button
      type="link"
      size="small"
      icon={<CopyOutlined />}
      style={{ padding: 0 }}
      onClick={() => {
        navigator.clipboard.writeText(valeur);
        message.success("Copié dans le presse-papier !")
      }}
    />
  );
};

export default CopierDansClipboard;
