import React from 'react';
import { useDashboard } from './Controller/useDashboard';
import DashboardLayout from "./Presentation/DashboardLayout"
import { useJsqel } from "../../api/jsqel"

const Dashboard = () => {
    return (
        <DashboardLayout {...useDashboard({ useJsqel })} />
    );
};

export default Dashboard;

