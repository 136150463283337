import React from "react";
import { withRouter, Link, Switch, Route } from "react-router-dom";
import QueueAnim from "rc-queue-anim";
import LoginFormulaire from "./LoginFormulaire";
import NouveauPwdFormulaire from "./NouveauPwdFormulaire";
import ReinitPwdFormulaire from "./ReinitPwdFormulaire";
import FooterContent from "../layout/FooterContent";
import Header from "./Header";
import { Button, Col, Row } from "antd";
import { t } from "../../Internationalisation/traduction";
import "./login.scss";

const backgroundLoginImageUrl = `url('${
  process.env.PUBLIC_URL + "/assets/bg-img-login.png"
}')`;

const Login = (props) => {
  const styleFooter = { textAlign: "center" };
  const styleBlocBouton = { marginBottom: "1rem" };

  return (
    <QueueAnim type="bottom" className="ui-animate ">
      <Header />
      <div className="idhome-login" key="1">
        <div className="idhome-login-container">
          <section className="idhome-login-background form-card-page row no-gutters">
            <div
              className="form-card__img form-card__img--left col-lg-6"
              style={{ backgroundImage: backgroundLoginImageUrl }}
            >
              <Row className="text-login">
                <Col className="titre-login">{t("fr", "hero_login")}</Col>
              </Row>
              <Row className="text-login">
                <Col className="soustitre-login">
                  {t("fr", "hero_login_soustitre")}
                </Col>
              </Row>
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div></div>
              <div className="p-5 px-xl-8 w-100 d-flex align-items-center flex-column">
                <section
                  className="form-v1-container bg-white p-4"
                  style={{ borderRadius: "0.375rem" }}
                >
                  <h2>
                    <img
                      style={{ maxWidth: "250px" }}
                      src="/assets/logo-home-login.png"
                      alt="logo IDhome"
                    />
                  </h2>
                  <p className="lead col-lg-10 mx-lg-auto">
                    {t("fr", "login_texte_formulaire")}
                  </p>

                  <div>
                    <Switch>
                      <Route
                        exact
                        path="/login"
                        render={() => (
                          <LoginFormulaire history={props.history} />
                        )}
                      />
                      <Route
                        exact
                        path="/lost"
                        component={NouveauPwdFormulaire}
                      />
                      <Route
                        exact
                        path="/pwd/:lien"
                        render={() => <ReinitPwdFormulaire />}
                      />
                    </Switch>

                    <Row style={styleFooter} type="flex" justify="flex-start">
                      <Col xs={24} style={styleBlocBouton}>
                        <div className="additional-info">
                          Mot de passe oublié ?
                        </div>
                        <div>
                          <Link to="/lost">
                            <Button>Nouveau mot de passe</Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </section>
                <Row gutter={16} align="middle" justify="center">
                  <Col
                    xs={24}
                    md={12}
                    lg={12}
                    style={{ marginTop: "1rem", textAlign: "center" }}
                  >
                    <a
                      href={t("fr", "url_app_android")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/bt-ggplay.png"
                        alt="button google play"
                        style={{ width: "85%" }}
                      />
                    </a>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    lg={12}
                    style={{ marginTop: "1rem", textAlign: "center" }}
                  >
                    <a
                      href={t("fr", "url_app_ios")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/bt-apple-store.png"
                        alt="button apple store"
                        style={{ width: "85%" }}
                      />
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </div>
      <FooterContent client={false} />
    </QueueAnim>
  );
};

export default withRouter(Login);
