import React, { useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { useJsqel } from "../../api/jsqel";

const ReinitPwdFormulaire = (props) => {
  const [end, setEnd] = useState(false);
  const lien = props.match.params.lien;

  const connexionValide = ({ error, results }) => {
    if (error || !results || results.length === 0) {
      message.error(
        "Ce lien n'est plus valide. Votre mot de passe est inchangé."
      );
    } else {
      message.success("Votre mot de passe a été changé.");
    }
    clear();
    setEnd(true);
  };

  const [, refresh, clear] = useJsqel("inscription/update_mot_passe_perdu", {
    sendItNow: false,
    lien,
    callback: connexionValide,
  });

  if (end) return <Redirect to="/login" />;

  return (
    <Form onFinish={refresh} className="form-v1" layout="vertical">
      <Form.Item
        name="mot_passe"
        label="Entrez votre nouveau mot de passe :"
        rules={[{ required: true, message: "Renseignez un mot de passe" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-cta btn-block">
          Réinitialiser
        </Button>
      </Form.Item>
    </Form>
  );
};

export default withRouter(ReinitPwdFormulaire);
