import React from "react";
import { useDemandesEcartees } from "./controller/useDemandesEcartees";
import DemandesEcarteesLayout from "./presentation/DemandesEcarteesLayout";
import { useJsqel } from "../../api/jsqel";

const DemandesEcartees = () => {
  return <DemandesEcarteesLayout {...useDemandesEcartees({ useJsqel })} />;
};

export default DemandesEcartees;
