import React from "react";
import { Input } from "antd";

// callback est une fonction String => ()

const FiltreContactVilles = ({ callback }) => {
  return (
    <Input.Search
      style={{ marginBottom: "0.5rem" }}
      placeholder="Rechercher un contact ou un lieu"
      onSearch={callback}
      enterButton
    />
  );
};

export default FiltreContactVilles;
