import React from "react";
import {
  Input,
  Form
} from "antd";

export const FormulaireInformationsSite = () => (
  <>
    <Form.Item label="Nom du site" name="nomSite">
      <Input />
    </Form.Item>

    <Form.Item label="Téléphone" name="telephone">
      <Input />
    </Form.Item>
    <Form.Item label="E-Mail" name="email">
      <Input />
    </Form.Item>
    <Form.Item label="Adresse" name="adresse">
      <Input />
    </Form.Item>
    <Form.Item label="Ville" name="ville">
      <Input />
    </Form.Item>
  </>
);
