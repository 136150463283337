import { useLeads } from "../../../api/dashboard";
import { useState } from "react";

function useContacts({ useJsqel }) {
  useLeads(); // permet la mise à jour des matchings, grâce à l'envoi d'une requête leads/get_leads

  const [filtre, setFiltre] = useState(""); // Filtre sur la ville ou le code postal
  const [filtrePrestation, setFiltrePrestation] = useState(null); // Filtre sur les type de prestation
  const [afficheDetailMobile, setAfficheDetailMobile] = useState(null); // Affichage du détail sur mobile

  const [{ results, error, loading }, refreshGetAchats] = useJsqel(
    "leads/get_achats",
    {
      sendItNow: true,
    }
  );

  const [archive_achat, refreshArchiveAchat] = useJsqel("leads/archive_achat", {
    sendItNow: false,
    callback: refreshGetAchats,
  });

  const toggleDetailMobile = (devis_id) =>
    devis_id === afficheDetailMobile
      ? setAfficheDetailMobile(null)
      : setAfficheDetailMobile(devis_id);

  const dataSource = results || [];

  const dataSourceFiltre = dataSource
    .filter((r) =>
      filtre === ""
        ? true
        : (r.code_postal_intervention &&
            r.code_postal_intervention.toLowerCase().search(filtre) > -1) ||
          (r.ville_intervention &&
            r.ville_intervention.toString().toLowerCase().search(filtre) >
              -1) ||
          (r.nom && r.nom.toString().toLowerCase().search(filtre) > -1) ||
          (r.prenom && r.prenom.toString().toLowerCase().search(filtre) > -1)
    )
    .filter((r) =>
      filtrePrestation ? r.prestation_id === filtrePrestation : true
    );

  const choix_prestations = dataSource.reduce(
    (acc, val) =>
      acc.find((p) => p.prestation_id === val.prestation_id)
        ? acc
        : [
            { prestation_id: val.prestation_id, prestation: val.prestation },
            ...acc,
          ],
    []
  );

  const actions = {
    refreshArchiveAchat,
    refreshGetAchats,
    toggleDetailMobile,
    setFiltre,
    setFiltrePrestation,
  };

  return {
    actions,
    loading: archive_achat.loading && loading,
    dataSourceFiltre,
    choix_prestations,
    afficheDetailMobile,
    error,
  };
}

export { useContacts };
