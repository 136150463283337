import { Button } from "antd";


const styleBadgeDetailOffres = {
    backgroundColor: "#65ae43",
    borderColor: "#65ae43",
    color: "white",
  };

// export const BoutonInfoWebsite = () => <Badge style={styleBadgeDetailOffres} count={"Détail des offres"} />

export const BoutonInfoWebsite = () => <Button style={styleBadgeDetailOffres} >Détail des offres</Button>
