import React from "react";
import { Link } from "react-router-dom";
import { Badge} from "antd";

const stylebadge = {
    backgroundColor: "rgb(255, 77, 79)",
    borderColor: "rgb(255, 77, 79)",
    color: "white",
    marginRight:"1rem"
  };

const BoutonRecharge = () => <Link to="/packs" ><Badge style={stylebadge} count={"Recharge"} /></Link>

export default BoutonRecharge

