import React from "react";
import { Avatar } from "antd";

import ShopOutlined from "@ant-design/icons/ShopOutlined";
import TagOutlined from "@ant-design/icons/TagOutlined";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import LineChartOutlined from "@ant-design/icons/LineChartOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import CreditCardOutlined from "@ant-design/icons/CreditCardOutlined";
import ProfileOutlined from "@ant-design/icons/ProfileOutlined";
import EuroCircleOutlined from "@ant-design/icons/EuroCircleOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";

const getStyle = (titre) => {
  switch (titre) {
    case "devis":
      return { icone: <ShopOutlined />, backgroundColor: "#1890ff" };
    case "achats":
      return { icone: <CheckCircleOutlined />, backgroundColor: "#87d068" };
    case "credits":
      return { icone: <LineChartOutlined />, backgroundColor: "#9f6629" };
    case "packs":
      return { icone: <CreditCardOutlined />, backgroundColor: "#9f6629" };
    case "factures":
      return { icone: <ProfileOutlined />, backgroundColor: "#9f6629" };
    case "support":
      return { icone: <InfoCircleOutlined />, backgroundColor: "#87d068" };
    case "chat":
      return { icone: <EuroCircleOutlined />, backgroundColor: "#722ed1" };
    case "echus":
      return { icone: <ClockCircleOutlined />, backgroundColor: "#ff4d4f" };
    case "reduction":
      return { icone: <TagOutlined />, backgroundColor: "#fec43c" };
    case "refus":
      return {
        icone: <ExclamationCircleOutlined />,
        backgroundColor: "red",
      };

    default:
      return { icone: "shop", backgroundColor: "#1890ff" };
  }
};

const IconIdhome = ({ titre, size = "default" }) => {
  const { icone, backgroundColor } = getStyle(titre);

  return (
    <Avatar
      size={size}
      icon={icone}
      style={{ backgroundColor, marginBottom: "3px" }}
    />
  );
};

export default IconIdhome;
