import React from "react";
import { Select } from "antd";

// choix_prestations est un tableau de { prestation_id, prestation }
// callback est une fonction prestation_id => ()

const FiltrePrestations = ({ choix_prestations, callback }) => {
  return (
    <Select
      style={{ width: "100%", marginBottom: "0.5rem" }}
      onChange={callback}
      placeholder="Filtrer par prestation"
    >
      <Select.Option key={0} value={null}>
        Pas de filtre
      </Select.Option>
      {choix_prestations?.map((choix) => (
        <Select.Option key={choix.prestation_id} value={choix.prestation_id}>
          {choix.prestation}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FiltrePrestations;
