import React from "react";
import { Button, Table, Card } from "antd";

const ListeDesOffres = ({ offres, client, actions }) => {
  const columns = [
    {
      title: "Votre demande",
      dataIndex: "libelle",
      key: "name",
    },
    {
      title: "Entreprise",
      dataIndex: "entreprise",
      key: "entreprise",
    },
    {
      key: "action",
      render: (value, record) => (
        <Button
          type="primary"
          onClick={() =>
            actions.selectionnerDevis({
              devis: record.devis_id,
            })
          }
        >
          J'ai choisi ce prestataire
        </Button>
      ),
    },
  ];
  return (
    <Card
      title="Vous avez été mis en relation avec ces professionnels."
      style={{ maxWidth: 800, margin: "auto", minHeight: "60vh" }}
    >
      <p>Avez-vous finalisé votre demande avec l'un d'entre eux ?</p>
      <Table columns={columns} dataSource={offres} pagination={false} />
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Button type="primary" onClick={() => actions.marquerFinEnquete()}>
          Aucune des prestations
        </Button>
      </div>
    </Card>
  );
};

export default ListeDesOffres;
