import React from "react";
import { List, Button } from "antd";
import ShopOutlined from "@ant-design/icons/ShopOutlined";
import TagOutlined from "@ant-design/icons/TagOutlined";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";

import { useJsqel } from "../../api/jsqel";

const CustomIcon = ({ type }) => {
  switch (type) {
    case "shop":
      return <ShopOutlined />;
    case "tag":
      return <TagOutlined />;
    case "clock-circle":
      return <ClockCircleOutlined />;
    case "check-circle":
      return <CheckCircleOutlined />;
    case "exclamation-circle":
      return <ExclamationCircleOutlined />;
    default:
      return <InfoCircleOutlined />;
  }
};

const Notifications = ({ results, loading, callback }) => {
  const [, refreshToutLu] = useJsqel("dashboard/tout_lu", { sendItNow: false });

  const handleBoutonToutLu = () => {
    refreshToutLu({ callback: () => callback() });
  };

  return (
    <div className="app-header-notifications">
      <List
        footer={
          <Button
            block
            icon={<CheckCircleOutlined />}
            type="link"
            onClick={handleBoutonToutLu}
          >
            Tout marquer comme lu
          </Button>
        }
        loading={loading}
        itemLayout="horizontal"
        dataSource={results}
        locale={{ emptyText: "Pas de message" }}
        renderItem={(item) => (
          <List.Item>
            <div className="list-style-v1">
              <div className="list-item">
                <div className="icon-btn icon-btn-round mr-3 bg-info text-body-reverse">
                  <CustomIcon type={item.icone} />
                </div>
                <div className="list-item__body">
                  <div className="list-item__title">{item.libelle}</div>
                  <div className="list-item__datetime">
                    {new Date(item.date_creation).toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Notifications;
