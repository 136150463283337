import React from "react";
import { Row, Col, Button } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import BoutonOffre from "./BoutonOffre";
import { pad } from "../../../helpers/helpers";
import InformationsClients from "../../demandes/Presentation/InformationsClients"

const style = {
  section: { color: "#0a84b7", fontWeight: "700", marginTop: "1rem" },
  button: { backgroundColor: "#65ae43", borderColor: "#65ae43" },
  spaced: { marginBottom: "1rem" },
};

const DetailLeadsAvecCoordonnees = ({ record }) => (
  <Row style={{ marginLeft: "-16px", marginRight: "-16px" }}>
    <Col xs={24} xl={6}>
      <Row style={style.section}>Dates</Row>
      <Row>
        <b>Date d'achat :&nbsp;</b>{" "}
        {new Date(record.date_achat).toLocaleDateString()}
      </Row>
      <Row>
        <b>Echéance du client :&nbsp;</b>{" "}
        {new Date(record.echeance).toLocaleDateString()}
      </Row>
      <Row>
        <b>Référence :&nbsp;</b> {pad(record.id, 5)}
      </Row>
    </Col>
    <Col xs={24} xl={6}>
      <Row style={style.section}>Coordonnées du client</Row>
      <Row>
        <b>Nom :&nbsp;</b> {record.nom}
      </Row>
      <Row>
        <b>Prénom :&nbsp;</b>
        {record.prenom}
      </Row>
      <Row>
        <b>Adresse :&nbsp;</b>
        {record.adresse_intervention} {record.code_postal_intervention}{" "}
        {record.ville_intervention}
      </Row>
    </Col>
    <Col xs={24} xl={6}>
      <Row style={style.section}>Contacter le client</Row>
      <Row style={style.spaced}>
        <a href={`tel:${record.telephone}`}>
          <Button type="primary" style={style.button} icon={<PhoneOutlined />}>
            {record.telephone}
          </Button>
        </a>
      </Row>
      {record.email && (
        <Row style={style.spaced}>
          <a href={`mailto:${record.email}`}>
            <Button type="primary" style={style.button} icon={<MailOutlined />}>
              {record.email}
            </Button>
          </a>
        </Row>
      )}
      <Row style={style.spaced}>
        <BoutonOffre devis_id={record.devis_id} etat={record.etat_devis} />
      </Row>
    </Col>
    <Col xs={24} xl={6}>
      <Row style={style.section}>Informations du client</Row>
      {record.adresse_destination && (
        <Row>
          <b>Adresse de destination :</b>
          {record.adresse_destination}{" "}
        </Row>
      )}
      {record.ville_destination && (
        <Row>
          <b>Ville de destination :</b> {record.ville_destination}{" "}
        </Row>
      )}
      {record.code_postal_destination && (
        <Row>
          <b>Code postal de destination : </b> {record.code_postal_destination}{" "}
        </Row>
      )}
      {record.detail
        ? <InformationsClients detail={record.detail} />
        : "Pas de détail"}
    </Col>
  </Row>
);

export default DetailLeadsAvecCoordonnees;
