import { Tag } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import { Link } from "react-router-dom";

const LigneInfo = ({ icon, libelle, texteLien, lien, tags, infobulle }) => (
  <div style={{ marginBottom: "0.5rem" }}>
    {icon && <span style={{ paddingRight: "0.5rem" }}>{icon}</span>}
    {libelle && (
      <span
        className="couleur-textes-dashboard"
        style={{ paddingRight: "0.3rem" }}
      >
        {libelle}
      </span>
    )}
    {lien ? (
      <Link to={lien} className="couleur-primaire">
        {texteLien}
      </Link>
    ) : (
      <span className="couleur-primaire">{texteLien} {infobulle ? <QuestionCircleOutlined onClick={infobulle} /> : null}</span>
    )}
    {tags && <div>{tags.slice(0, 10).map(tag => <Tag key={tag.id}>{tag.libelle}</Tag>)} {tags.length>9 ? "..." : ""}</div>}
  </div>
);

export default LigneInfo;
