import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Layout } from "antd";

import { initialStore, reducer } from "./store/reducer";
import { withStore, useStore } from "./store/store";
import { useCredit } from "./api/credits";
import { useMobile } from "./helpers/helpers";

import Login from "./components/login/Login";
import Logout from "./components/login/Logout";
import Dashboard from "./components/dashboard/Dashboard";
import SideNavMobile from "./components/layout/SideNavMobile";
import SimpleSideNav from "./components/layout/SimpleSidenav";
import AppHeader from "./components/layout/AppHeader";
import Demandes from "./components/demandes/Demandes";
import Contacts from "./components/contacts/Contacts";
import Offre from "./components/offre/Offre";
import CrediterMonCompte from "./components/packs/CrediterMonCompte";
import Timeline from "./components/chat/Timeline";
import MonCompte from "./components/compte/MonCompte/MonCompte";
import MesParametres from "./components/compte/MesParametres";
import AideEnLignePro from "./components/aide/AideEnLignePro";
import FooterContent from "./components/layout/FooterContent";
import ProtectionDonnees from "./components/inscription/ProtectionDonnes";
import Confirmation from "./components/client/Confirmation/Confirmation";
import Feedback from "./components/client/FeedBack/Feedback";
import Client from "./components/client/Client/Client";
import DemandesEcartees from "./components/demandes_ecartes/DemandesEcartees";
import Website from "./components/website/Website";

// Ant design avec custom theme
import "./styles/custom_ant.css";

// Bootstrap épuré
import "./styles/bootstrap/bootstrap.scss";

// custom
import "./styles/layout.scss";
import "./styles/theme.scss";
import "./styles/ui.scss";
import "./styles/responsivetext.scss";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let isAuthenticated = false;
  const token = window.localStorage.getItem("idhome_token");
  if (token) {
    // Vérification de la non expiration du token
    let decodedToken = token && token.split(".");
    let decodedData = JSON.parse(
      atob(decodedToken[0], "base64").toString("utf8")
    );
    if (
      decodedData.exp &&
      Number(decodedData.exp) > Date.now() &&
      decodedData.role &&
      decodedData.role === "Fournisseur"
    )
      isAuthenticated = true;
  }

  const next = (pathname) => {
    if (pathname && pathname.length) {
      if (pathname !== "/login" && pathname !== "/") {
        return "?next=" + pathname.slice(1);
      }
    }
    return "";
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/login" + next(props.location.pathname)} />
        )
      }
    />
  );
};

const RestrictedPanel = () => {
  const { Content } = Layout;
  const { state } = useStore();
  const { isMobile } = useMobile();
  const collapsedNav = state.collapsedNav ? true : false;

  useCredit(true); // Mise à jour des informations du fournisseur connecté

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* <SideNavDesktop /> */}
      {isMobile ? <SideNavMobile /> : <SimpleSideNav />}

      <Layout
        style={{
          marginLeft: isMobile ? 0 : collapsedNav ? 80 : 250,
          transition: "all .2s",
        }}
      >
        <AppHeader />
        <Content style={{ marginTop: "60px" }}>
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/devis" component={Demandes} />
            <Route exact path="/refus" component={DemandesEcartees} />
            <Route exact path="/achats" component={Contacts} />
            <Route exact path="/offre/:id_offre" component={Offre} />
            <Route exact path="/packs" component={CrediterMonCompte} />
            <Route exact path="/website" component={Website} />
            <Route exact path="/messages" component={Timeline} />
            <Route exact path="/compte" component={MonCompte} />
            <Route exact path="/parametres" component={MesParametres} />
            <Route exact path="/support" component={AideEnLignePro} />
            <Route exact path="/rgpd" component={ProtectionDonnees} />
            <Redirect exact from="/" to="/dashboard" />
            <Route component={Login} />
          </Switch>
        </Content>
        <FooterContent />
      </Layout>
    </Layout>
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/inscription/:id" component={Inscription} /> * /}
        {/* <Route exact path="/merci"  component={InscriptionValidee} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/2/:id_client" component={Client} />
        <Route exact path="/client/:id_client" component={Client} />
        <Route exact path="/1/:id_client" component={Confirmation} />
        <Route exact path="/feedback/:id_client" component={Feedback} />

        <Route exact path="/confirmation/:id_client" component={Confirmation} />
        <Route exact path="/rgpd_client" component={ProtectionDonnees} />
        <PrivateRoute
          exact
          path={[
            "/dashboard",
            "/devis",
            "/refus",
            "/achats",
            "/offre/:id_offre",
            "/packs",
            "/messages",
            "/compte",
            "/parametres",
            "/support",
            "/cgv",
            "/rgpd",
            "/website",
            "/",
          ]}
          component={RestrictedPanel}
        />
        <Route component={Login} />
      </Switch>
    </Router>
  );
};

export default withStore(initialStore, reducer)(App);
