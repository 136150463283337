import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Badge, Popover } from "antd";
import MenuUnfoldOutlined from "@ant-design/icons/MenuUnfoldOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import MenuFoldOutlined from "@ant-design/icons/MenuFoldOutlined";
import BellOutlined from "@ant-design/icons/BellOutlined";
import md5 from "blueimp-md5";
import { useStore } from "../../store/store";
import Notifications from "./Notifications";
import Credit from "./Credit";
import { useJsqel } from "../../api/jsqel";
import { useMobile } from "../../helpers/helpers";
import BoutonRecharge from "./BoutonRecharge";

const { Header } = Layout;

const AvatarDropdown = (props) => {
  return (
    <Menu className="app-header-dropdown">
      <Menu.Item key="1">
        <Link to="/parametres" onClick={props.callback}>
          <SettingOutlined style={{ marginRight: "5px" }} />
          Mes paramètres
        </Link>
      </Menu.Item>
      <Menu.Item key="0">
        <Link to="/compte" onClick={props.callback}>
          <InfoCircleOutlined style={{ marginRight: "5px" }} />
          Mon compte
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/support" onClick={props.callback}>
          <QuestionCircleOutlined style={{ marginRight: "5px" }} />
          Aide
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Link to="/logout">
          <LogoutOutlined style={{ marginRight: "5px" }} />
          Déconnexion
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const headerStyle = (isMobile, collapsedNav, width) => ({
  width: width - (isMobile ? 0 : collapsedNav ? 80 : 200),
  height: "60px",
  position: "fixed",
  top: 0,
  left: isMobile ? 0 : collapsedNav ? 80 : 200,
  transition: "all .2s",
  zIndex: "15",
  padding: 0,
});

const AppHeader = (props) => {
  const { state, dispatch } = useStore();
  const [{ results, error, loading }, refresh] = useJsqel(
    "dashboard/notifications",
    { sendItNow: true }
  );

  const [visibleDropDown, setVisibleDropDown] = useState(false);
  const { isMobile, width } = useMobile();
  const collapsedNav = state.collapsedNav ? true : false;

  // Toutes les minutes, on vérifie les nouvelles notifications
  useEffect(() => {
    const timer = setTimeout(() => {
      refresh();
    }, 60000);
    return () => clearTimeout(timer);
  }, [refresh]);

  if (error) return <Redirect to="/login" />;

  return (
    <Header
      style={headerStyle(isMobile, collapsedNav, width)}
      className="bg-idhome-header"
    >
      <div className="app-header-inner bg-idhome">
        <div className="header-left">
          <div className="list-unstyled list-inline">
            <a
              href="#"
              className="list-inline-item d-none d-md-inline-block"
              onClick={() => dispatch({ type: "TOGGLE_NAV" })}
            >
              <div className="list-icon">
                {state.collapsedNav ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined />
                )}
              </div>
            </a>

            <a
              href="#"
              className="list-inline-item d-md-none"
              onClick={() => dispatch({ type: "TOGGLE_NAV" })}
            >
              <div className="list-icon">
                <MenuFoldOutlined />
              </div>
            </a>
          </div>
        </div>

        <div className="header-right">
          <div className="list-unstyled list-inline">
            <BoutonRecharge />
            <Credit disposition="badge-seul" />

            <Popover
              placement="bottomRight"
              content={
                <Notifications
                  results={results}
                  loading={loading}
                  callback={refresh}
                />
              }
              trigger="click"
              overlayClassName="app-header-popover"
            >
              <a href="/" className="list-inline-item">
                <Badge count={results ? results.length || 0 : 0}>
                  <BellOutlined className="list-notification-icon" />
                </Badge>
              </a>
            </Popover>

            <Dropdown
              open={visibleDropDown}
              onOpenChange={setVisibleDropDown}
              className="list-inline-item"
              overlay={
                <AvatarDropdown
                  prenom={state.prenom}
                  callback={() => setVisibleDropDown(false)}
                />
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <a className="ant-dropdown-link no-link-style" href="/">
                <Avatar
                  src={`https://www.gravatar.com/avatar/${md5(
                    state.email
                  )}?d=retro`}
                  size="small"
                />
                <span className="avatar-text d-none d-md-inline text-white">
                  {state.prenom}
                </span>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
