const InfobulleDns = () => (
  <div>
    <p>
      <b>Prérequis : posséder le nom de domaine</b>
    </p>
    <p>
      Le nom de domaine doit vous appartenir, cela veut dire que vous devez
      acheter un nom de domaine de votre choix auprès d'un registrar.
    </p>
    <p>
      Vous devez ensuite associer votre nom de domaine, à des serveurs de noms
      externes (dns1.tizoo.com et dns2.tizoo.com).
    </p>
    <p>
      Pour passer les DNS de votre nom de domaine sur des serveurs de noms
      externes, suivez les instructions de votre registrar.
    </p>
    <p>
      Vous pouvez consulter nos FAQ, pour trouver les procédures des principaux
      registrar.
    </p>
    <p>
      Dans l'option <b>serveur de noms</b> de votre nom de domaine, indiquez les
      serveurs (copier/coller depuis votre compte IDhome.app) :{" "}
      <b>dns1.tizoo.com</b> et <b>dns2.tizoo.com</b>
    </p>

    <p>
      Cette étape est prioritaire pour créer votre site internet. Sans cette
      opération, nous ne pourrons pas traiter la création de votre site
      internet.
    </p>
    <p>
      En cas de problème, vous pouvez nous contacter par Support Chat pour cette
      procédure.
    </p>
  </div>
);

export default InfobulleDns;
