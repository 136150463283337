import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useJsqel } from "../../api/jsqel";
import { useLeads } from "../../api/dashboard";
import {
  Input,
  Form,
  InputNumber,
  Select,
  message,
  Button,
  Modal,
  Spin,
  Typography,
} from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

import UploadFormItem from "./UploadFormItem";

const { Text } = Typography;

const complementInfoDevis = () =>
  Modal.info({
    title:
      "Bien rédiger un devis est une étape essentielle pour obtenir un mandat",
    content: (
      <div>
        <p>
          Vous pouvez télécharger votre devis au format PDF. Ce format de
          fichier garantit que le client pourra le télécharger depuis son
          compte.
        </p>
        <p>
          Quelques conseils pour décrocher un client et vous aider à rédiger un
          devis irréprochable !
        </p>
        <p>
          La présentation de votre devis engendre un impact conséquent auprès de
          vos clients. Il est donc primordial de la soigner.{" "}
        </p>
        <ul>
          <li>
            Soyez le plus précis possible sur la prestation. Cela vous permettra
            d’éviter les ambiguïtés et vous permettra d’échanger avec votre
            client pour cerner au mieux sa demande.
          </li>
          <li>
            Evitez les caractères trop petits pour rédiger les détails de la
            prestation. Mettez en gras les mots les plus importants.
          </li>
          <li>
            Aérez la description de votre devis et évitez les fautes
            d’orthographes.
          </li>
          <li>
            Émettez un devis avec une TVA claire, si vous êtes imposable à la
            TVA, indiquez votre numéro de TVA. Le cas échéant faites un devis HT
          </li>
          <li>
            Numérotez vos devis, cela vous permettra de mieux vous organiser en
            classant vos devis mais aussi de les retrouver plus vite au besoin.{" "}
          </li>
        </ul>
      </div>
    ),
    onOk() {},
    okText: "Compris !",
  });

const OffreFormulaire = ({ devis_id }) => {
  const [{ results, error, loading }] = useJsqel("leads/get_devis", {
    sendItNow: true,
    devis_id,
  });
  const [, refreshDevis] = useJsqel("leads/update_devis", {
    sendItNow: false,
    devis_id,
  });
  const [, refreshDashboard] = useLeads(); // Pour actualiser le badge du menu

  const [devisFinalise, setDevisFinalise] = useState(false);
  const [erreurMsgUpload, setErreurMsgUpload] = useState("");

  const { Option } = Select;

  const devis = results && results.length ? results[0] : {};

  const devisNonModifiable = devis && devis.etat && ['selectionne', 'timesup', 'refus'].includes(devis.etat)

  const apresMiseAJourDevis = ({ results, error }) => {
    if (error) {
      message.error(
        "Une erreur est survenue lors de la mise à jour du devis. Merci de ré-essayer et de contacter votre administrateur si le problème persiste"
      );
    } else {
      message.success("Le devis a été mis à jour !");
      refreshDashboard();
      setTimeout(() => {
        setDevisFinalise(true);
      }, 1500);
    }
  };

  const handleSubmit = (values) => {
    // Pour passer au formulaire suivant, tout doit être valide

    // fichier et remarques sont optionnels
    refreshDevis(
      Object.assign({}, { fichier: "", remarques: "" }, values, {
        callback: apresMiseAJourDevis,
      })
    );
  };

  const normFile = (file) => {
    if (file && file.status === "done" && file.response)
      return file.response.filename;
    return "";
  };

  const valideMontant = (rule, value, cb) => {
    if (value === undefined || value === null) return cb();

    const montant = parseInt(value);
    if (montant && montant > 1) return cb();
    return cb("Ce montant ne semble pas valide");
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
    style: {
      marginBottom: "12px",
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 14,
        offset: 6,
      },
    },
    style: {
      marginBottom: "24px",
    },
  };

  if (error) return <Redirect to="/login" />;

  if (devisFinalise) return <Redirect to="/achats" />;

  if (loading) return <Spin />;


  return (
    <Form
      className="form-v1"
      onFinish={handleSubmit}
      initialValues={{ prix: 0, ...devis }}
    >
      <Form.Item
        {...formItemLayout}
        name="entreprise"
        label={<span>Entreprise&nbsp;</span>}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="siret"
        label={<span>N° R.C.&nbsp;</span>}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="ville"
        label={<span>Ville&nbsp;</span>}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="code_postal"
        label={<span>Code postal&nbsp;</span>}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={<span>Tarification&nbsp;</span>}
        name="tarification"
        rules={[
          { required: true, message: "Indiquez la tarification retenue" },
        ]}
      >
        <Select placeholder="Sélectionnez une tarification" disabled={devisNonModifiable}>
          <Option key="forfaitaire" value="forfaitaire">
            forfaitaire
          </Option>
          <Option key="horaire" value="horaire">
            horaire
          </Option>
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="prix"
        label={<span>Montant&nbsp;</span>}
        rules={[{ validator: valideMontant }]}
      >
        <InputNumber
          style={{ width: "100%" }}
          parser={(value) => value.replace(/[$(EUR)(CHF)]\s?|(,*)/g, "")}
          disabled={devisNonModifiable}
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="fichier"
        label={
          <span>
            Devis&nbsp; <QuestionCircleOutlined onClick={complementInfoDevis} />
          </span>
        }
        extra={
          erreurMsgUpload ? (
            <Text type="danger">{erreurMsgUpload}</Text>
          ) : (
            "Joignez votre devis au format pdf"
          )
        }
        rules={[
          {
            pattern: /\w+\.(pdf|PDF)$/,
            message: "Il faut un fichier pdf de moins de 5 Mo",
          },
        ]}
        getValueFromEvent={normFile}
      >
        <UploadFormItem
         disabled={devisNonModifiable}
          erreurCallback={setErreurMsgUpload}
          nomFichier="devis"
          nomFichierInitial={{
            uid: 1,
            name: devis.fichier,
            status: "done",
            url: "/uploads/" + devis.fichier,
          }}
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="remarques"
        label="Message"
        rules={[{ max: 400, message: "Pas plus de 400 caractères" }]}
      >
        <Input.TextArea
          rows={4}
          placeholder="Insérez un message pour le client"
          style={{ ":placeholder": "#424141" }}
          disabled={devisNonModifiable}
        />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ marginBottom: "1rem" }}
          disabled={devisNonModifiable}
        >
          {devisNonModifiable ? "Cette offre n'est plus modifiable" :  "Envoyer l'offre au client"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OffreFormulaire;
