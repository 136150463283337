import React from "react";
import { Card, Upload, message, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import urlApi from "../../../../api/url";

const cardStyle = { marginBottom: "1rem" };

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Seul le format PNG ou JPG est autorisé");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("La taille max de l'image est de 300ko");
  }
  return isJpgOrPng && isLt2M;
}

const UploadButton = ({ loading }) => (
  <div>
    {loading ? <LoadingOutlined /> : <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Charger</div>
  </div>
);

const CardProfil = ({ loading, imageUrl, title, onChange, editionEnCours, onSave }) => {

  const token = window.localStorage.getItem("idhome_token");
  const headers = { Authorization: `Bearer ${token}` };

  return (
    <Card
      title={title}
      style={cardStyle}
      extra={
        editionEnCours && (
          <Button type="primary" onClick={onSave}>
            Enregistrer
          </Button>
        )
      }
    >

      <Upload
        name="devis"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        multiple={false}
        headers={headers}
        action={urlApi() + "services/upload"}
        beforeUpload={beforeUpload}
        onChange={onChange}
      >
        {imageUrl ? (
          <img
            src={"/uploads/" + imageUrl}
            alt="Mon profil"
            style={{ width: "100%" }}
          />
        ) : (
          <UploadButton loading={loading} />
        )}
      </Upload>
    </Card>
  );
};

export default CardProfil;
