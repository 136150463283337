import React from "react";
import { Redirect } from "react-router-dom";
import { Table, Card } from "antd";
import { useJsqel } from "../../api/jsqel";
import { pad } from "../../helpers/helpers";
import { t } from "../../Internationalisation/traduction";
import LienFacturePdf from "./LienFacturePdf";
import BoutonArchiveFacture from "./BoutonArchiveFacture";

const columns = (callback) => [
  {
    title: "Pack",
    dataIndex: "libelle",
  },
  {
    title: "Date",
    dataIndex: "date_achat",
    sorter: (a, b) => {
      const c =
        new Date(a.date_achat).valueOf() - new Date(b.date_achat).valueOf();
      return c;
    },
    render: (v) => new Date(v).toLocaleDateString(),
  },
  {
    title: "Montant",
    dataIndex: "montant",
    render: (text, record) =>
      record.montant + " " + record.libelle_devise + " HT",
  },
  {
    title: "Numéro",
    dataIndex: "id",
    render: (text, record) => pad(record.id, 5) + "-" + record.code,
  },
  {
    title: t("fr", "titre_ecran_factures"),
    render: (text, record) =>
      record.etat_facture === "valide" ? (
        <LienFacturePdf {...record} />
      ) : (
        "En attente de validation"
      ),
  },
  {
    title: "Archiver",
    render: (text, record) => (
      <BoutonArchiveFacture facture_id={record.id} callback={callback} />
    ),
  },
];

const Factures = (props) => {
  const [{ results, error, loading }, refresh] = useJsqel(
    "comptabilite/factures",
    { sendItNow: true }
  );

  if (error) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "2rem", marginBottom: "3rem" }}
    >

      <Card>
        <Table
          columns={columns(refresh)}
          dataSource={results || []}
          loading={loading}
          rowKey="id"
          style={{ overflowX: "auto" }}
        />
      </Card>
    </div>
  );
};

export default Factures;
