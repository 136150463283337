import React from "react";
import ReactMarkdown from "react-markdown";
import { Check } from "./Check";

const DescriptionPack = ({ description }) => (
  <ReactMarkdown
    components={{
      p({ node, className, children, ...props }) {
        return (
          <p
            className={className}
            {...props}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {children}
          </p>
        );
      },
      li({ node, className, children, ...props }) {
        return (
          <li
            className={className}
            {...props}
          >
            <Check /> {children}
          </li>
        );
      },
      ul({ node, className, children, ...props }) {
        return (
          <ul
            className={className}
            {...props}
            style={{ marginBottom: "1rem" }}
          >
            {children}
          </ul>
        );
      },
    }}
  >
    {description || ""}
  </ReactMarkdown>
);

export default DescriptionPack;
