import React from "react";
import FooterContent from "../../layout/FooterContent";
import { Layout } from "antd";
import Logo from "../../layout/Logo";
import { t } from "../../../Internationalisation/traduction";

const LayoutPageClient = ({ children }) => {
  const { Content, Header } = Layout;
  return (
    <Layout style={{ minHeight: "100vh" }} className="bg-white">
      <Header className="app-header">
        <div className="app-header-inner bg-idhome">
          <div className="brand">
            <Logo />
            <span>{t("fr", "marque")}</span>
          </div>
        </div>
      </Header>
      <Content>
        <div className="container-fluid container-mw-xxl chapter">
          {children}
        </div>
      </Content>
      <FooterContent client={true} />
    </Layout>
  );
};

export default LayoutPageClient;
