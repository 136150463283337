import React from "react";
import { Button } from "antd";
import EyeOutlined from "@ant-design/icons/EyeOutlined";

const BoutonAfficherLead = ({ actions, loading, id }) => {
  return (
    <Button
      loading={loading}
      onClick={() => actions.clicDetailLead(id)}
      className="bouton-afficher"
    >
      Afficher
    </Button>
  );
};

export default BoutonAfficherLead;
