import React from "react";
import { Badge } from "antd";

const BadgeCredit = ({ valeur }) => {
  const valeurFloat = Number.parseFloat(valeur);

  if (Math.abs(valeurFloat) < 0.001) {
    return <Badge style={{ backgroundColor: "grey", color: "white" }} count={"Info"} />;
  }

  if (valeurFloat < 0) {
    return <Badge style={{ backgroundColor: "red", color: "white" }} count={"Débit"} />;
  }

  return <Badge style={{ backgroundColor: "green", color: "white" }} count={"Crédit"} />;
};

export default BadgeCredit;
