import React from "react";
import { Popconfirm, Button } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

const BoutonArchive = ({
  libelle = "Archiver",
  icone = "close",
  actions,
  loading,
  devis_id
}) => {

  return (
    <Popconfirm
      title={`Vous allez archiver ce lead. Il ne sera plus visible dans cette liste. Confirmez-vous ?`}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={() => actions.refreshArchiveAchat({ devis_id: devis_id })}
      cancelText="Non"
    >
      <Button loading={loading} type="primary" icon={icone}>
        {libelle}
      </Button>
    </Popconfirm>
  );
};

export default BoutonArchive;
