import React from "react";
import { Redirect } from "react-router-dom";
import { Card, Button, Form, Switch, message } from "antd";

import { useJsqel } from "../../api/jsqel";

const cardStyle = { marginBottom: "1rem", height: "100%" };

const MesNotifications = () => {
  const [compte, refreshCompte] = useJsqel("inscription/get_notifications", {
    sendItNow: true,
  });
  const [{ loading }, refresh] = useJsqel("inscription/update_notifications", {
    sendItNow: false,
  });
  const data =
    compte.results && compte.results.length
      ? compte.results[0]
      : { notifications_mail: true, notifications_push: true };

  const apresMiseAJour = ({ results, error }) => {
    if (error) message.error("Erreur lors de la mise à jour");
    else message.success("Vos préférences ont été changées");
    refreshCompte();
  };

  const handleModifieNotifications = (values) => {
    refresh(Object.assign({}, values, { callback: apresMiseAJour }));
  };

  if (compte.error) return <Redirect to="/login" />;

  return (
    <Card style={cardStyle} loading={compte.loading} title="Mes notifications">
      <Form onFinish={handleModifieNotifications} initialValues={data}>
        <Form.Item
          name="notifications_mail"
          label="Recevoir les notifications par mail"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Changer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default MesNotifications;
