import React from "react";
import { Redirect } from "react-router-dom";
import { Table, Card } from "antd";
import { useJsqel } from "../../api/jsqel";
import { useCredit } from "../../api/credits";
import BadgeCredit from "./BadgeCredit";
import { uneDecimale } from "../../helpers/helpers";

const columns = [
  {
    title: "Libelle",
    dataIndex: "libelle",
    // sorter: true,
  },
  {
    title: "Date",
    dataIndex: "date_mouvement",
    // sorter: true,
    render: (v) => new Date(v).toLocaleDateString(),
  },
  {
    title: "Nature",
    render: (text, record) => <BadgeCredit valeur={record.prix} />,
  },
  {
    title: "Montant",
    dataIndex: "prix",
    render: (v) =>
      uneDecimale(v) > 0
        ? uneDecimale(v) + " crédits"
        : -uneDecimale(v) + " crédits",
  },
  {
    title: "Ancien solde",
    dataIndex: "solde",
    render: (v) => uneDecimale(v) + " crédits",
  },
  {
    title: "Nouveau solde",
    dataIndex: "solde",
    render: (text, record) =>
      `${uneDecimale(
        Number.parseFloat(record.solde) + Number.parseFloat(record.prix)
      )} crédits`,
    key: "nouveau solde",
  },
];

const Credits = (props) => {
  const [{ results, error, loading }] = useJsqel("comptabilite/credits", {
    sendItNow: true,
  });
  useCredit(true);

  if (error) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "2rem", marginBottom: "3rem" }}
    >
      <Card>
        <Table
          columns={columns}
          dataSource={results || []}
          loading={loading}
          rowKey={(record) => record.id}
          style={{ overflowX: "auto" }}
        />
      </Card>
    </div>
  );
};

export default Credits;
