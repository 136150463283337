import React from "react";
import { Collapse, Card } from "antd";

const { Panel } = Collapse;

const CarteFaq = ({ titre, faqs, background="white", loading=false }) => (
  <Card bordered={false} style={{background}} loading={loading}>
    {titre && <h3 className="article-title article-title--loose">{titre}</h3>}
    <Collapse>
      {faqs.map((f, i) => (
        <Panel key={i} header={f.question}>
          <p style={{textAlign:"initial"}}>
            {f.reponse &&
              f.reponse.split("\n").map((r, j) => (
                <span key={j}>
                  {r}
                  <br />
                </span>
              ))}
          </p>{" "}
        </Panel>
      ))}
    </Collapse>
  </Card>
);

export default CarteFaq;