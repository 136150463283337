import React from "react";
import { Col, Row, Card } from "antd";
import { t } from "../../../Internationalisation/traduction";

const SectionApp = () => {
  return (
    <>
      <p>
        Téléchargez gratuitement notre application {t("fr", "nom_app_mobile")}{" "}
        pour iPhone ou Android et contactez vos clients ou que vous soyez !
      </p>
      <Row>
        <Col xs={0} md={4} lg={4}></Col>
        <Col xs={24} md={10} lg={10}>
          <a
            href={t("fr", "url_app_android")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/bt-ggplay.png"
              alt="button google play"
              style={{ width: "85%" }}
            />
          </a>
        </Col>
        <Col xs={24} md={10} lg={10}>
          <a
            href={t("fr", "url_app_ios")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/bt-apple-store.png"
              alt="button apple store"
              style={{ width: "85%" }}
            />
          </a>
        </Col>
      </Row>
    </>
  );
};

export default SectionApp;
