import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Col, Row } from "antd";
import { useStore } from "../../store/store";

const Credit = ({ disposition }) => {
  const { state } = useStore();

  const stylebadge = {
    backgroundColor: "#65ae43",
    borderColor: "#65ae43",
    color: "white",
  };

  const CreditCard = ({ solde }) => (
    <Card style={{ height: "100%" }}>
      <Row>
        <Col md={24} xl={15}>
          <b>Votre solde actuel :</b>
        </Col>
        <Col md={24} xl={9}>
          <Badge style={stylebadge} count={"" + (solde || "0") + " crédits"} />
        </Col>
      </Row>
      <Row>
        <Col md={24} xl={18}>
          <Link to="/packs">Créditer mon compte</Link>{" "}
        </Col>
      </Row>
    </Card>
  );

  const CreditCardSansLien = ({ solde }) => (
    <Card>
      <Row>
        <Col md={24} xl={15}>
          Votre solde actuel :{" "}
        </Col>
        <Col md={24} xl={9}>
          <Badge style={stylebadge} count={"" + (solde || "0") + " crédits"} />
        </Col>
      </Row>
    </Card>
  );

  const CreditBadge = ({ solde }) => (
    <Badge style={stylebadge} count={"" + (solde || "0") + " crédits"} />
  );

  switch (disposition) {
    case "card":
      return <CreditCard solde={state.solde} />;
    case "card_sans_lien":
      return <CreditCardSansLien solde={state.solde} />;
    case "badge":
    default:
      return <CreditBadge solde={state.solde} />;
  }
};

export default Credit;
