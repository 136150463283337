import React from "react";
import { Card, Button, Form, Input, message } from "antd";

import { useJsqel } from "../../api/jsqel";

const cardStyle = { marginBottom: "1rem", height: "100%" };

const ChangementMotPasse = () => {
  const [{ loading }, refresh] = useJsqel("inscription/update_mot_passe", {
    sendItNow: false,
  });

  const apresMiseAJour = ({ results, error }) => {
    if (!error) {
      message.success("Votre mot de passe a été changé");
    } else {
      message.error("Votre mot de passe ne peut pas être changé");
    }
  };

  const handleModifieMotPasse = (values) => {
    refresh(Object.assign({}, values, { callback: apresMiseAJour }));
  };

  return (
    <Card style={cardStyle} title="Changer mon mot de passe">
      <Form layout="inline" onFinish={handleModifieMotPasse}>
        <Form.Item
          name="mot_passe"
          label={<span>Nouveau mot de passe&nbsp;</span>}
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Renseignez un mot de passe" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Changer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ChangementMotPasse;
