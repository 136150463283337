import React from "react";
import { Row, Col, Avatar } from "antd";
import BoutonAchatLead from "./BoutonAchatLead";
import BoutonPasInteresse from "./BoutonPasInteresse";
import BoutonAchatCredits from "./BoutonAchatCredits";

/*
badge: {
    borderRadius: "50px",
    display: "flex",
    margin: "auto",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#1b90fb",
    width: "70px",
    height: "70px",
    color: "#FFF",
    textAlign: "center",
  },
  */

const style = {
  header: {
    marginLeft: "-2px",
    marginRight: "-2px",
    background: "#EEEEEE",
    padding: "16px 8px",
    alignItems: "center",
  },
  prestation: {
    fontSize: 18,
    fontWeight: "500",
    margin: "auto",
    color: "#000",
  },
  info: {
    margin: "auto",
    color: "rgb(74, 74, 74)",
    fontWeight: "400",
  },
};

const HeaderLead = ({
  id,
  prestation,
  logo_prestation,
  code_postal_intervention,
  ville_intervention,
  actions,
  credit,
  loading,
}) => {
  

  return (
    <Row style={style.header} gutter={4} justify="center" align="middle">
      <Col xs={24} md={4} xl={2}>
        <Avatar
          shape="square"
          src={`/uploads/${logo_prestation}`}
          style={{ width: "75px", height: "75px" }}
        />
      </Col>
      <Col xs={24} md={20} xl={10}>
        <p style={style.info}>Réf. {id}</p>
        <p style={style.prestation}>{prestation}</p>
        <p style={style.info}>
          {code_postal_intervention} {ville_intervention}
        </p>
      </Col>
      <Col xs={24} md={10} xl={12}>
        <Row justify="center" align="middle" gutter={[8, 8]}>
          <Col>
            <BoutonAchatLead
              prix={credit}
              actions={actions}
              loading={loading}
            />
          </Col>
          <Col>
            <BoutonPasInteresse
              prix={credit}
              actions={actions}
              loading={loading}
            />
          </Col>
          <Col xs={0} md={24} xl={8}>
            <BoutonAchatCredits credit={credit} cliquable={false}/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderLead;
