import { Button, Typography, Card } from "antd";
import { Link } from "react-router-dom";

const { Title } = Typography;

// Message affiché dans le Dashboard
export const MessagesPromotions = ({ messages }) =>
  (messages || []).map((message) => (
    <div>
      <p>
        <b>{message.titre}</b>
      </p>
      <p>{message.texte.split('\n').map(ligne => <div>{ligne}</div>)}</p>
      <Link to="/packs">
        <Button className="bouton-participe">Afficher l'offre</Button>
      </Link>
    </div>
  ));

// Message affiché dans l'écran des packs
export const MessagesPromotionsPack = ({ messages }) => messages && messages.length ? (
  <Card style={{height:"100%"}}>
    {(messages || []).map((message) => (
      <div>
        <Title level={3} className="titre-message-promotion">
          {message.titre}
        </Title>
        <div className="texte-message-promotion">
          {message.texte.split('\n').map(ligne => <div>{ligne}</div>)}
        </div>
      </div>
    ))}
  </Card>
) : null;
