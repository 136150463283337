import React from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Spin } from "antd";
import { useJsqel } from "../../api/jsqel";
import { useCredit } from "../../api/credits";
import PackTemplate from "./PackTemplate";
import TitreSection from "../layout/TitreSection";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "./pack.scss";
import AideEcranPack from "../aide/AideEcranPack";
import { PacksCover } from "./PacksCover";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeKey = process.env.REACT_APP_STRIPE_ENDPOINT_PUBLIC_KEY || "not-set";

const stripePromise = loadStripe(stripeKey);

const Packs = ({
  typeAutorises = ["premium", "business"],
  avecFaq = true,
  cover = <PacksCover />,
}) => {
  const [{ results: listePacks, error, loading }] = useJsqel(
    "comptabilite/packs",
    {
      sendItNow: true,
    }
  );

  const listePacksAutorises = listePacks.filter((p) =>
    typeAutorises.includes(p.template)
  );

  useCredit(true);

  if (error) return <Redirect to="/login" />;

  return (
    <Elements stripe={stripePromise}>
      <div
        className="container-fluid container-mw-xxl chapter"
        style={{ minHeight: "490px" }}
      >
        <Row gutter={16}>
          {loading ? <Spin /> : null}
          {listePacksAutorises.map((p) => (
            <Col
              key={p.id}
              xs={24}
              md={12}
              xl={8}
              xxl={6}
              style={{ marginBottom: "1rem" }}
            >
              {loading ? null : <PackTemplate {...p} />}{" "}
            </Col>
          ))}
        </Row>
      </div>
      <div
        className="container-fluid chapter"
        style={{
          backgroundColor: "#e7e8e9",
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {avecFaq ? (
          <Row>
            <Col xl={6} md={0}></Col>
            <Col xl={12} md={24} style={{ textAlign: "center" }}>
              <TitreSection
                titre="Questions fréquemment posées"
                accroche="Besoin de conseils ?"
              />
              <AideEcranPack />
            </Col>
            <Col xl={6} md={0}></Col>
          </Row>
        ) : null}
      </div>

      {cover}
    </Elements>
  );
};

export default Packs;
