import { useCredit } from "../../../api/credits";
import { useLeads } from "../../../api/dashboard";

function useDemandes({ useJsqel }) {
  // Solde des crédits et des refus
  const [refreshCredits] = useCredit();

  // Liste des matchings actifs et en attente
  const [matchings, refreshMatching] = useJsqel("leads/get_matchings", {
    sendItNow: false,
    callback: refreshCredits,
  });

  const [leads, refreshLeads] = useLeads(true, refreshMatching);
  const listeLeads =
    leads.results && leads.results.length ? leads.results[0] : null;

  // Alerte est vrai s'il existe au moins une demande en attente d'échéance plus proche que l'échéance actuelle
  const alerteDemandeEnAttente =
    listeLeads &&
    matchings.results &&
    matchings.results.length &&
    matchings.results.some(
      (m) => new Date(m.echeance) < new Date(listeLeads.echeance)
    );

  const debutPresentation = new Date(listeLeads?.debut_presentation);
  // Echance : 4 jours à compter de la date de présentation, sauf si l'échéance du lead est avant
  const echeance = new Date(
    Math.min(
      new Date(debutPresentation.setDate(debutPresentation.getDate() + 4)),
      new Date(listeLeads?.echeance)
    )
  );
  const maintenant = new Date();
  const nbJoursRestants = Math.floor(
    Math.abs(maintenant - echeance) / (1000 * 60 * 60 * 24)
  );
  const nbHeuresRestantes =
    Math.ceil(Math.abs(maintenant - echeance) / (1000 * 60 * 60)) -
    nbJoursRestants * 24;

  const [achat_leads, refreshAchatLeads] = useJsqel("leads/achat_leads", {
    sendItNow: false,
    callback: refreshLeads,
  });

  const [ignore_leads, refreshIgnoreLeads] = useJsqel("leads/ignore_leads", {
    sendItNow: false,
    callback: refreshLeads,
  });

  const actions = {
    refreshLeads,
    refreshAchatLeads: () => refreshAchatLeads({ id: listeLeads.id }),
    refreshIgnoreLeads: () => refreshIgnoreLeads({ id: listeLeads.id }),
  };

  return {
    actions,
    loading: leads.loading && achat_leads.loading && ignore_leads.loading,
    alerteDemandeEnAttente,
    listeLeads,
    nbHeuresRestantes,
    nbJoursRestants,
    error: leads.error,
  };
}

export { useDemandes };
