const EtatWebsite = ({ etat }) => {
  switch (etat) {
    case "achete":
      return <span>🚀 Configurez votre site pour le mettre en ligne</span>;
    case "online":
      return <span>✅ Votre site est en ligne</span>;
    case "suspendu" :
      return <span>❌ Votre site est suspendu. Contacter le support pour régulariser la situation</span>;
    case "initialise":
    default:
      return <span></span>;
  }
};

export default EtatWebsite;
