import { Card } from "antd";

const CarteInfo = ({ titre, children, hauteurVariable }) => (
  <Card
    title={titre}
    className={ !!hauteurVariable ? "header-cartes-info-dashboard" : "header-cartes-info-dashboard hauteur-fixe" }
  >
    {children}
  </Card>
);

export default CarteInfo;
