import React from 'react';
import { Card } from "antd";
import {ClockCircleOutlined} from '@ant-design/icons';

const DureeValidite = ({echeanceDepasse, echeance}) => {
    return (
        <Card>
        <h4>
          <ClockCircleOutlined style={{ color: "#82B53F" }} /> Durée de
          validité
        </h4>
        <div className="divider  border-primary mb-4"></div>
        <p>
          Cette page a un accès unique et privé qui regroupe l'ensemble
          de vos offres.
        </p>
        {echeanceDepasse ? (
          <p>Votre date d'échéance est dépassée</p>
        ) : (
          <p>
            Selon votre date d'échéance, cet accès temporaire sera clos
            <span style={{ color: "#CA0202" }}>
              {echeance > 1
                ? " dans " + echeance + " jours"
                : echeance > 0
                ? " demain"
                : " aujourd'hui"}
            </span>
            .
          </p>
        )}
      </Card>
    );
};

export default DureeValidite;