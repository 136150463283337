import React, { useState } from "react";
import "./header.scss";
import { t } from "../../Internationalisation/traduction";

const Header = () => {
  const [onClickDropdown, setOnClickDropdown] = useState(null);
  return (
    <header className="header_wrap fixed-top light_skin hover_menu_style3 bg-header-login-page">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href="/">
            <img
              className="logo_light"
              src="/assets/logo-home-login.png"
              alt="Logo IDhome"
            />
          </a>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          {t("fr", "avec_menu_login") ? (
            <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav">
                <li className="dropdown">
                  <a className="nav-link" href="https://www.idhome.io/">
                    Accueil
                  </a>
                </li>

                <li
                  className="dropdown"
                  onClick={() =>
                    setOnClickDropdown(
                      onClickDropdown === "acces" ? null : "acces"
                    )
                  }
                >
                  <a className="dropdown-toggle nav-link" href="#">
                    Accès PRO
                  </a>
                  <div
                    className={
                      (onClickDropdown === "acces" ? "active" : "") +
                      " dropdown-menu"
                    }
                  >
                    <ul>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          href="https://www.idhome.io/inscription/"
                        >
                          Inscription
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          href="https://idhome.app/login"
                        >
                          Connexion
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item nav-link nav_item"
                          href="https://idhome.app/lost"
                        >
                          Mot de passe perdu
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            <div className="collapse navbar-collapse justify-content-end">
              <a href={t("fr", "url_menu_login")} className="lien-site-header">
                {t("fr", "titre_menu_login")}
              </a>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
