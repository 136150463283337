import React from "react";
import { Row, Col, Card, Empty } from "antd";
import DetailLeads from "./DetailLeads";
import TitreSection from "../../layout/TitreSection";
import { Redirect } from "react-router-dom";
import DemandesAttentes from "./DemandesAttentes"

const DemandesLayout = (props) => {
  const {
    actions,
    loading,
    alerteDemandeEnAttente,
    listeLeads,
    error,
    nbHeuresRestantes,
    nbJoursRestants,
  } = props;

  const rowStyleMargin = { marginTop: "1rem" };
  if (error) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <Row
        type="flex"
        justify="space-between"
        align="stretch"
        style={{ marginBottom: "1rem" }}
      >
        <Col xs={24} xl={9} style={{ alignSelf: "flex-end" }}>
          <Row gutter={{ md: 4, xl: 16 }}>
            <Col style={rowStyleMargin}>
              <TitreSection
                titre="Demandes disponibles"
                accroche="Boostez votre clientèle"
              />
            </Col>
          </Row>
        </Col>

        <Col xs={0} xl={8}>
          <DemandesAttentes />
        </Col>
      </Row>
      <Card loading={loading}>
        {listeLeads ? (
          <DetailLeads
            actions={actions}
            record={listeLeads}
            nbHeuresRestantes={nbHeuresRestantes}
            nbJoursRestants={nbJoursRestants}
            alerte={alerteDemandeEnAttente}
          />
        ) : (
          <Empty
            description={
              <div>
                <h3>Il n'a pas encore de demande.</h3>
                <p>
                  Vous serez prévenu par mail dès l'arrivée d'un nouveau devis
                  correspondant à vos critères.
                </p>
              </div>
            }
          />
        )}
      </Card>
    </div>
  );
};

export default DemandesLayout;
