import {setToken} from '../api/jsqel'

const reducer = (state, action) => {
    
    switch(action.type) {
        case 'LOGIN':
            setToken(action.token)
            return { nom: action.nom, prenom: action.prenom,email : action.email, token:action.token }
        case 'LOGOUT' :
            setToken('')
            return { nom: '', prenom:'', token:'', email:'' }
        case 'TOGGLE_NAV' :
            return Object.assign( {}, state, {collapsedNav : !state.collapsedNav} )
        case 'UPDATE_CREDITS' :
            return Object.assign( {}, state, action.payload )
        case 'UPDATE_DASHBOARD' :
            return Object.assign( {}, state, action.payload )
      default:
        return state
    }
  }

const initialStore = { collapsedNav:false }

export {reducer, initialStore}


