import React from 'react';
import MonCompteLayout from "./presentation/MonCompteLayout"
import { useJsqel } from "../../../api/jsqel";
import { useMonCompte } from './controller/useMonCompte';

const MonCompte = () => {
    return (
        <MonCompteLayout {...useMonCompte({ useJsqel })} />
    );
};

export default MonCompte;


