import React from "react";
import {  Form, Select, Space } from "antd";
import { SelecteurCouleur } from "./SelecteurCouleur"

const templates = [
  { id: "W-1", urlDemo: "https://w1.bweb.website/", vignette: "w1.jpg" },
  { id: "W-2", urlDemo: "https://w2.bweb.website/", vignette: "w2.jpg" },
  { id: "W-3", urlDemo: "https://w3.bweb.website/", vignette: "w3.jpg" },
  { id: "W-4", urlDemo: "https://w4.bweb.website/", vignette: "w4.jpg" },
  { id: "W-5", urlDemo: "https://w5.bweb.website/", vignette: "w5.jpg" },
];

const optionsTemplate = templates.map((template) => ({
  value: template.id,
  label: (
    <Space align="center" className="template-option-container">
      <img src={`/assets/${template.vignette}`} style={{width:"auto", height:"60px"}} alt={template.id} />
      <div className="template-description-container">
        <h4>{template.id}</h4>
        <a href={template.urlDemo} target="_blank" rel="noreferrer">
          Voir la démo
        </a>
      </div>
    </Space>
  ),
}));

const formItemLayoutColorPicker = {
  labelCol: {
    span: 18,
  },
  wrapperCol: {
    span: 6,
  },
};

const formItemLayoutTemplate = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export const FormulaireCouleursTemplate = ({enLigne}) => (
  <>
    <Form.Item
      label="Couleur principale"
      name="couleurPrincipale"
      {...formItemLayoutColorPicker}
    >
      <SelecteurCouleur disabled={enLigne}/>
    </Form.Item>
    <Form.Item
      label="Couleur secondaire"
      name="couleurSecondaire"
      {...formItemLayoutColorPicker}
    >
      <SelecteurCouleur disabled={enLigne}/>
    </Form.Item>
   
    <Form.Item  {...formItemLayoutTemplate}>
      Template :
    </Form.Item>
    <Form.Item name="template" {...formItemLayoutTemplate}>
      <Select size="large" placeholder={<span style={{fontSize:"14px"}}>Sélection du thème</span>} options={optionsTemplate} className="selecteur-template" disabled={enLigne}/>
    </Form.Item>
  </>
);
