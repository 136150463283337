import { useJsqel } from "../../api/jsqel";
import { t } from "../../Internationalisation/traduction";
import { MessagesPromotionsPack } from "../dashboard/Presentation/MessagesPromotions";
import Packs from "./Packs";
import Factures from "../comptabilite/Factures";
import Credits from "../comptabilite/Credits";
import EcranAvecMenuTab from "../layout/EcranAvecMenuTab";

const items = [
  {
    label: "Packs",
    key: "packs",
    titre: t("fr", "titre_ecran_pack", "Choisissez un pack"),
    accroche: t("fr", "sous_titre_ecran_pack", "Packs sans abonnement. Payez uniquement ce que vous consommez !") ,
    children: <Packs />,
  },
  {
    label: "Factures",
    key: "factures",
    titre: t("fr", "titre_ecran_factures"),
    accroche: t("fr", "soustitre_ecran_factures"),
    children: <Factures />,
  },
  {
    label: "Mouvements",
    key: "mouvements",
    titre: "Mouvements",
    accroche: "Consultez tous vos mouvements",
    children: <Credits />,
  },
];

const CrediterMonCompte = () => {
  const [{ results: messages_promotion }] = useJsqel(
    "dashboard/message_promotions",
    {
      sendItNow: true,
    }
  );

  return (
    <EcranAvecMenuTab items={items} defaultKey="packs">
      <MessagesPromotionsPack messages={messages_promotion} />
    </EcranAvecMenuTab>
  );
};

export default CrediterMonCompte;
