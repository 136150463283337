import React from "react";
import { Row, Col, Result } from "antd";
import LayoutPageClient from "../../PresentationCommun/LayoutPageClient";
import QuestionsFrequentes from "../../PresentationCommun/QuestionsFrequentes";
import DureeValidite from "../../PresentationCommun/DureeValidite";
import ReservezEnLigne from "../../PresentationCommun/ReservezEnLigne";
import Loading from "../../../layout/Loading";

const Confirmation = (props) => {
  const { lead, echeanceDepasse, echeance } = props;

  if (lead.loading || lead.error) return <Loading />;

  return (
    <LayoutPageClient>
      {lead.results?.libelle ? (
        <Row style={{ paddingTop: "1rem" }}>
          <Col xs={24} xl={16}>
            <h3 className="article-title-idhome">{lead.results.libelle}</h3>
            <div className="divider border-primary mb-4"></div>
          </Col>
        </Row>
      ) : null}
      <Row gutter={16}>
        <Col xs={24} xl={16}>
          <Result
            status="success"
            title={`Votre demande a été confirmée.`}
            subTitle="Nos partenaires pourront soumettre leur offre en ligne très prochainement."
          />
        </Col>
        <Col xs={24} xl={8}>
          <ReservezEnLigne />
          <br />
          <DureeValidite
            echeanceDepasse={echeanceDepasse}
            echeance={echeance}
          />
          <br />
          <QuestionsFrequentes />
        </Col>
      </Row>
    </LayoutPageClient>
  );
};

export default Confirmation;
