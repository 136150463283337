import React from 'react';
import { useDemandes } from './Controller/useDemandes';
import DemandesLayout from "./Presentation/DemandesLayout"
import { useJsqel } from "../../api/jsqel"

const Demandes = () => {
  return (
    <DemandesLayout {...useDemandes({ useJsqel })} />
  );
};

export default Demandes;
