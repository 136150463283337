import React from "react";
import { Popconfirm, Button } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";

import { useJsqel } from "../../api/jsqel";

const BoutonArchiveFacture = ({ facture_id, callback }) => {
  const [{ loading }, refresh] = useJsqel("comptabilite/archive_facture", {
    sendItNow: false,
    facture_id,
    callback,
  });

  return (
    <Popconfirm
      title={`Vous allez archiver cette facture. Elle ne sera plus visible dans cette liste. Confirmez-vous ?`}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={() => refresh()}
      cancelText="Non"
    >
      <Button loading={loading} type="primary" icon={<CloseCircleOutlined />}>
        <span className="show-desktop-hide-mobile">Archiver</span>{" "}
      </Button>
    </Popconfirm>
  );
};

export default BoutonArchiveFacture;
