import React from "react";
import { Redirect } from "react-router-dom";
import CardCompte from "./CardCompte";
import CardProfil from "./CardProfil";
import CardSelect from "./CardSelect";
import { t } from "../../../../Internationalisation/traduction";

const MonCompteLayout = (props) => {
  const {
    error,
    initialValues,
    loadinglistePrestations,
    loadingListeRegions,
    donneesMonCompte,
    loadingCompte,
    loadingLogo,
    editionEnCours,
    actions,
    listeRegions,
    listePrestations,
  } = props;

  if (error) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <CardCompte
        loading={loadingCompte}
        title={"Coordonnées de votre entreprise"}
        onSave={actions.enregistrerLesChangements}
        editionEnCours={editionEnCours}
        callback={actions.modificationDunChampDeMonCompte}
        liste={donneesMonCompte}
      />
      <CardProfil
        title={"Mon logo / Mon profil"}
        callback={actions.modificationDunChampDeMonCompte}
        imageUrl={initialValues.profil}
        loading={loadingLogo}
        onChange={actions.modificationDuLogo}
        onSave={actions.enregistrerLesChangements}
        editionEnCours={editionEnCours}
      />
      <CardSelect
        id="secteurs"
        title={"Mes prestations"}
        disabled={t("fr", "desactivation_choix_prestation")}
        loading={loadinglistePrestations}
        onChange={actions.modifierSelection("SELECT_PRESTATION")}
        onSave={actions.enregistrerLesChangements}
        editionEnCours={editionEnCours}
        value={initialValues.prestations ? initialValues.prestations : []}
        list={listePrestations}
      />
      <CardSelect
        id="regions"
        title={"Mes régions"}
        loading={loadingListeRegions}
        onChange={actions.modifierSelection("SELECT_REGION")}
        onSave={actions.enregistrerLesChangements}
        editionEnCours={editionEnCours}
        value={initialValues.regions ? initialValues.regions : []}
        list={listeRegions}
      />
    </div>
  );
};

export default MonCompteLayout;
