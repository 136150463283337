import React from "react";
import { Result } from "antd";
import SmileOutlined from "@ant-design/icons/SmileOutlined";
import { t } from "../../../../Internationalisation/traduction";

const LienSite = () => {
  return (
    <span>
      Si vous recherchez un professionnel, nous vous invitons à publier votre
      demande sur notre site{" "}
      <a href={t("fr", "url_site")} alt={t("fr", "marque")}>
        {t("fr", "marque")}
      </a>
    </span>
  );
};

const MessageRemerciement = () => (
  <Result
    title="Merci d'avoir pris le temps de répondre à notre formulaire de satisfaction"
    subTitle={<LienSite />}
    icon={<SmileOutlined style={{ color: "#13db13" }} />}
    style={{ height: "60vh" }}
  />
);

export default MessageRemerciement;
