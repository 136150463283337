import React from "react";
import { List, Card } from "antd";
import DescriptionLead from "./DescriptionLead";
import { Link } from "react-router-dom";

// headStyle={{ backgroundColor: "$header-cartes-dashboard-bg", color: "$header-cartes-dashboard-color" }}

const DerniersDevis = ({ results, loading, titre, link }) => {
  return (
    <Card title={titre} className="header-cartes-dashboard">
      <List
        itemLayout="horizontal"
        dataSource={results}
        loading={loading}
        renderItem={(item, i) =>
          link && i + 1 === results.length ? (
            <Link to={link}>
              <List.Item>
                <List.Item.Meta
                  title={item.prestation}
                  description={<DescriptionLead {...item} />}
                />
              </List.Item>
            </Link>
          ) : (
            <List.Item>
              <List.Item.Meta
                title={item.prestation}
                description={<DescriptionLead {...item} />}
              />
            </List.Item>
          )
        }
      />
    </Card>
  );
};

export default DerniersDevis;
