import React from "react";
import { useJsqel } from "../../api/jsqel";
import CarteFaq from "./CarteFaq";

const AideEcranPack = () => {
    const [{ loading, results }] = useJsqel("faqs/faqsFiltre", {
        sendItNow: true,
        type_faq:'packs'
      });

      return <CarteFaq faqs={results} background="transparent" loading={loading} />
}

export default AideEcranPack

