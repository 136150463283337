import React from "react";
import { withRouter } from "react-router-dom";
import { Layout } from "antd";
import Logo from "./Logo";
import SideNavMenu from "./SideNavMenu";
import { useStore } from "../../store/store";

const siderStyle = {
  overflow: "auto",
  height: "100vh",
  position: "fixed",
  left: 0,
  transition: "all .2s",
};

const siderHeaderStyle = {
  display: "flex",
  flexDirection: "row",
  flex: "0 0 auto",
  height: "60px",
  paddingTop: "12px",
  paddingLeft: "0px",
  paddingRight: "24px",
  color: "white",
  fontSize: "20px",
  //justifyContent:'space-between',
};

const SimpleSideNav = (props) => {
  const { state } = useStore();
  const collapsedNav = state.collapsedNav ? true : false;
  const selectedMenu = props.location.pathname || "/dashboard";
  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsedNav}
      width={250}
      style={siderStyle}
      className="d-none d-md-block bg-idhome-side-menu"
    >
      <div style={siderHeaderStyle} className=" bg-idhome-header">
        <Logo full={!collapsedNav} />
      </div>

      <SideNavMenu selectedMenu={selectedMenu} />
    </Layout.Sider>
  );
};

export default withRouter(SimpleSideNav);
