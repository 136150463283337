import React from "react";
import { useJsqel } from "../../../api/jsqel";
import ConfirmationLayout from "./Presentation/ConfirmationLayout"
import { useConfirmationClient } from "./Controller/useClientConfirmation"

const Confirmation = ({ match }) => {
  return (
    <ConfirmationLayout {...useConfirmationClient({ match, useJsqel })} />
  );
};

export default Confirmation;