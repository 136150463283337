import React from "react";
import { Result } from "antd";
import FrownOutlined from "@ant-design/icons/FrownOutlined";
import { PropositionRepublication } from "./PropositionRepublication";

const OffreArchivee = ({ sources }) => (
  <Result
    title="Cette demande n'existe plus."
    subTitle={<PropositionRepublication sources={sources} />}
    icon={<FrownOutlined style={{ color: "#fd8200" }} />}
    style={{ marginBottom: "30%" }}
  />
);

export default OffreArchivee;
