import React from 'react';
import { Drawer } from 'antd';
import { useStore } from '../../store/store'
import { withRouter} from 'react-router-dom';

import SideNavMenu from './SideNavMenu'
import Logo from './Logo'

const siderHeaderStyle = {
    display : 'flex',
    flexDirection : 'row',
    flex: '0 0 auto',
    height: '60px',
    background: '#0A84B7',
    paddingTop:'12px',
    paddingLeft:'0px',
    paddingRight:'24px',
    color:'white',
    fontSize:'20px',
    //justifyContent:'space-between',
}

const SideNavMobile = props =>  {
    const {state, dispatch} = useStore()
    const collapsedNav = state.collapsedNav ? true : false    
    const selectedMenu = props.location.pathname || '/dashboard'

    return (
        <Drawer
            visible={collapsedNav}
            placement="left"
            className="d-md-none app-drawer app-sidenav"
            closable={true}
            onClose={()=>dispatch({type:"TOGGLE_NAV"})}
        >
            <div style={siderHeaderStyle}>
                <Logo />
            </div>
            
            <SideNavMenu selectedMenu={selectedMenu} clicCallback={()=>dispatch({type:"TOGGLE_NAV"})}/>
        </Drawer>
    )
}


export default withRouter(SideNavMobile)
