function useConfirmationClient({ useJsqel, match }) {
  const lien_unique = match.params.id_client || "id-inconnu";

  const [lead] = useJsqel("client/confirme_lead", {
    sendItNow: true,
    client: lien_unique,
  });

  // calcul de l'échéance
  const echeance =
    lead.results && lead.results.echeance
      ? Math.round(
          (new Date(lead.results.echeance) - new Date()) / (1000 * 60 * 60 * 24)
        )
      : 0;
  const echeanceDepasse = echeance < -1;

  // Date de création du lead
  const dateCreation =
    lead.results && lead.results.date_creation
      ? new Date(lead.results.date_creation).toLocaleDateString("fr-FR")
      : "";

  return {
    lead,
    echeanceDepasse,
    echeance,
    dateCreation,
  };
}

export { useConfirmationClient };
