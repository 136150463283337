import React from "react";
import { useJsqel } from "../../../api/jsqel";
import { useFeedback } from "./controller/useFeedBack";
import FeedbackLayout from "./Presentation/FeedbackLayout";

const Feedback = ({ match }) => {
  return <FeedbackLayout {...useFeedback({ match, useJsqel })} />;
};

export default Feedback;
