import React from "react";
import { Input, Form } from "antd";
import FacebookOutlined from "@ant-design/icons/FacebookOutlined";
import InstagramOutlined from "@ant-design/icons/InstagramOutlined";
import LinkedinOutlined from "@ant-design/icons/LinkedinOutlined";

const socialItemLayoutColorPicker = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
  colon: false,
};

export const FormulaireReseaux = () => (
  <>
    <Form.Item
      name="facebook"
      label={<FacebookOutlined />}
      {...socialItemLayoutColorPicker}
    >
      <Input placeholder="Facebook" />
    </Form.Item>
    <Form.Item
      name="instagram"
      label={<InstagramOutlined />}
      {...socialItemLayoutColorPicker}
    >
      <Input placeholder="Instagram" />
    </Form.Item>
    <Form.Item
      name="linkedin"
      label={<LinkedinOutlined />}
      {...socialItemLayoutColorPicker}
    >
      <Input placeholder="Linkedin" />
    </Form.Item>
  </>
);
