import React from 'react';
import DateLocale from '../../layout/DateLocale'


const DescriptionLead = ({ echeance, code_postal_intervention, ville_intervention }) => {
    return (
        <span>
            {code_postal_intervention} - {ville_intervention} <br />
            <DateLocale echeance={echeance} prefixe="Date d'échéance :" />
        </span>
    )
}

export default DescriptionLead

