import { Colorpicker } from "antd-colorpicker";

const colorBoxStyle = (couleur) => ({
  width: "50px",
  height: "20px",
  display: "inline-flex",
  border: "2px solid rgb(255, 255, 255)",
  boxShadow: "rgb(204, 204, 204) 0px 0px 0px 1px",
  background: couleur,
});

export const SelecteurCouleur = (props) => {
  if (props.disabled) {
    return <div style={colorBoxStyle(props.value || "white")}></div>;
  }
  return <Colorpicker popup {...props} />;
};
