import React from "react";
import { Form, Rate, Card, Button } from "antd";
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};

const FormulaireEnquete = ({ offre, actions }) => {
  return (
    <Card title="VOTRE AVIS EST IMPORTANT POUR NOUS !">
      <h4>
        Qu'avez-vous pensez de la prestation avec {offre.entreprise} pour un(e){" "}
        {offre.libelle} ?{" "}
      </h4>
      <Form
        name="validate_other"
        {...layout}
        style={{ marginTop: "1rem" }}
        onFinish={actions.repondreEnquete}
        initialValues={{
          "input-number": 3,
          "checkbox-group": ["A", "B"],
          rate: 3.5,
        }}
      >
        <Form.Item name="score_satisfaction" label="Satisfaction" required>
          <Rate />
        </Form.Item>
        <Form.Item name="score_tarif" label="Tarif" required>
          <Rate />
        </Form.Item>
        <Form.Item name="score_disponibilite" label="Disponibilité" required>
          <Rate />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 16, offset: 10 }}>
          <Button type="primary" htmlType="submit">
            Envoyer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default FormulaireEnquete;
