import { useRef } from "react";

export function useScrollToTop() {
  const aideEnLigne = useRef();

  const scrollToAide = () =>
    window.scrollTo({
      top:
        aideEnLigne && aideEnLigne.current ? aideEnLigne.current.offsetTop : 0,
      left: 0,
      behavior: "smooth",
    });
  const scrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return {
    scrollToAide,
    scrollToTop,
    aideEnLigne,
  };
}
