import Packs from "../packs/Packs";
import EcranAvecMenuTab from "../layout/EcranAvecMenuTab";
import InfoWebsite from "./presentation/InfoWebsite";
import { PacksCoverWebsite } from "./presentation/PacksCoverWebsite";
import { t } from "../../Internationalisation/traduction";
import { BoutonInfoWebsite } from "./presentation/BoutonInfoWebsite";

const items = [
  {
    label: "Website",
    key: "website",
    titre: "Choisissez votre site",
    accroche: "Sélectionnez votre forfait",
    children: (
      <Packs
        typeAutorises={["website"]}
        cover={<PacksCoverWebsite />}
        avecFaq={false}
      />
    ),
  },
  {
    label: "Infos",
    key: "info",
    titre: "Infos website",
    accroche: "Paramétrez votre site",
    children: <InfoWebsite />,
  },
  {
    label: "Options",
    key: "options",
    titre: "Ajoutez des options",
    accroche: "Faites évoluer votre site selon vos besoins",
    children: (
      <Packs
        typeAutorises={["services"]}
        cover={<PacksCoverWebsite />}
        avecFaq={false}
      />
    ),
  },
];

const extra = [
  {
    label: (
      <a
        style={{ color: "inherit" }}
        href={t("fr", "cgv_offres_web_url", "/")}
        target="_blank"
        rel="noreferrer"
      >
        CGV Website
      </a>
    ),
    key: "cgv",
  },
  {
    label: (
      <a
        target="_blank"
        href={t("fr", "detail_offres_web_url", "/")}
        rel="noreferrer"
      >
        <BoutonInfoWebsite />
      </a>
    ),
    key: "cgv",
  },
];

const Website = () => {
  return (
    <EcranAvecMenuTab items={items} defaultKey="website" extra={extra} />
      
  );
};

export default Website;
