import React, { useReducer, useState, useCallback } from "react";
import { message, Rate } from "antd";
import { t } from "../../../../Internationalisation/traduction";

const actionTypes = {
  mettre_a_jour: "METTRE_A_JOUR",
  select_prestation: "SELECT_PRESTATION",
  select_region: "SELECT_REGION",
};

const monCompteReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.mettre_a_jour:
      return Object.assign({}, state, action.payload);
    case actionTypes.select_prestation: {
      return Object.assign({}, state, { prestations: action.payload });
    }
    case actionTypes.select_region: {
      return Object.assign({}, state, { regions: action.payload });
    }
    default:
      return state;
  }
};

function useMonCompte({ reducer = monCompteReducer, useJsqel }) {
  const [editionEnCours, setEditionEnCours] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [initialValues = {}, dispatch] = useReducer(reducer, {});

  const [compte, refresh] = useJsqel("inscription/get_compte", {
    sendItNow: true,
    callback: ({ results }) =>
      results &&
      results.length &&
      dispatch({
        type: "METTRE_A_JOUR",
        payload: {
          ...results[0],
          prestations: results[0].prestations
            .filter((p) => p.actif === true)
            .map((p) => p.id),
          regions: results[0].regions
            .filter((p) => p.actif === true)
            .map((p) => p.id),
        },
      }),
  });

  const listeRegions =
    compte.results && compte.results.length ? compte.results[0].regions : [];
  const listePrestations =
    compte.results && compte.results.length
      ? compte.results[0].prestations
      : [];

  const [, UpdateCompteFournisseur] = useJsqel("inscription/update_compte", {
    sendItNow: false,
  });
  const [, SynchroniseWordpress] = useJsqel("wordpress/annuaire", {
    sendItNow: false,
  });

  const modificationDunChampDeMonCompte = useCallback((id, str) => {
    dispatch({ type: "METTRE_A_JOUR", payload: { [id]: str } });
    setEditionEnCours(true);
  }, []);

  const modifierSelection = (type) => (value, option) => {
    dispatch({ type, payload: value });
    setEditionEnCours(true);
  };

  const sauvegardeReussie = () => {
    message.success("Les changements ont été pris en compte.");
    SynchroniseWordpress();
    setEditionEnCours(false);
    refresh();
  };

  const enregistrerLesChangements = (e) => {
    const parametresRequetes = Object.assign(
      { profil: "" },
      initialValues,
      {
        email_modifie: initialValues.email,
        nom_modifie: initialValues.nom,
        prenom_modifie: initialValues.prenom,
      },
      {
        callback: ({ results, error }) =>
          results && results.length
            ? sauvegardeReussie()
            : message.error("Erreur lors de l'enregistrement."),
      }
    );
    UpdateCompteFournisseur(parametresRequetes);
  };

  const modificationDuLogo = (info) => {
    if (info.file.status === "uploading") {
      setLoadingLogo(true);
      return;
    }
    if (info.file.status === "done") {
      const nomFichierSurServeur =
        info.file && info.file.response && info.file.response.filename;
      setLoadingLogo(false);
      modificationDunChampDeMonCompte("profil", nomFichierSurServeur);
    }
  };

  const actions = {
    modificationDunChampDeMonCompte,
    modifierSelection,
    enregistrerLesChangements,
    modificationDuLogo,
  };

  const listeDesDonnesMonEntreprise = Object.keys(initialValues)
    .filter((k) => k !== "prestations" && k !== "regions" && k !== "profil")
    .map((k) => ({
      id: k,
      editable: k !== "score",
      label: t("fr", k),
      valeur:
        k === "score" ? (
          initialValues[k] ? (
            <>
              <Rate disabled defaultValue={initialValues[k]} /> (
              {initialValues[k]} / 5)
            </>
          ) : (
            "Pas d'avis"
          )
        ) : (
          initialValues[k]
        ),
    }));

  return {
    error: compte.error,
    initialValues,
    donneesMonCompte: listeDesDonnesMonEntreprise,
    listeRegions,
    listePrestations,
    loadinglistePrestations: listePrestations.loading,
    loadingListeRegions: listeRegions.loading,
    loadingCompte: compte.loading,
    loadingLogo: loadingLogo,
    editionEnCours,
    actions,
  };
}

export { useMonCompte };
