import React from "react";

const TitreSection = ({ titre, accroche }) => (
  <div>
    <span className="article-subtitle">{accroche}</span>
    <h2 className="article-title">{titre}</h2>
  </div>
);

export default TitreSection;
