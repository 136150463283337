import React from "react";

import OffreFormulaire from "./OffreFormulaire";

const imgLeft = {
  backgroundImage: "url(/assets/faire-offre.jpg)",
};

const Offre = ({ match }) => {
  return (
    <article className="article">
      <section className="hero hero-loose text-center">
        <div className="container">
          <div className="col-md-8 mx-auto">
            <h1 className="hero-title">Faites votre offre en ligne</h1>
            <p className="hero-lead">
              Effectuez un devis auprès du client en un clin d'oeil!
            </p>
          </div>
        </div>
      </section>

      <section className="feature-callout feature-content-right bg-white">
        <div
          className="col-12 col-md-6 feature-callout-image-pull"
          style={imgLeft}
        />
        <div className="container-fluid container-mw-xl">
          <div className="col-12 col-md-6 offset-md-6">
            <div className="callout-feature-content">
              <h2>Envoyez votre proposition</h2>
              <div>
                <p>
                  Envoyez rapidement votre offre au client. Il sera
                  automatiquement informé de votre proposition en temps réel.{" "}
                </p>
                <p>
                  Si votre offre est validée par le client, vous serez informé
                  par mail et alerté dans votre compte par un changement de
                  statut de votre offre en "Offre validée"
                </p>
              </div>
            </div>

            <OffreFormulaire devis_id={parseInt(match.params.id_offre)} />
          </div>
        </div>
      </section>
    </article>
  );
};

export default Offre;
