import { useEffect, useState, useReducer } from "react";
import axios from "axios";
import urlApi from "./url";

// React hook to use a query
// const [{results, error, loading}, refresh, clear] = useJsqel('http://localhost:5000', 'private_hello', { sendItNow:true, filter : filter })
// refresh can be used like this :
// refresh()                    -> send the query with the same parameters
// refresh( {filter:'F%'} )     -> send the query with updated parameters
// refresh({ sendItNow:true, filter : filter }) --> send the query if sendItNow was initially false
// clear : set results to null

const url = urlApi();

const setToken = (token) => {
  window.localStorage.setItem("idhome_token", token);
};

const removeToken = () => {
  if (window.localStorage.getItem("idhome_token"))
    window.localStorage.removeItem("idhome_token");
};

const jsqelReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        loading: true,
        error: null,
        query: action.payload.query,
        apiParameters: action.payload.apiParameters,
      };
    case "FETCH_SUCCESS":
      return { ...state, loading: false, error: null, results: action.payload };
    case "FETCH_FAILURE":
      // console.log("Error payload : ", action.payload.response);
      if (action.payload.response && action.payload.response.status === 401)
        removeToken();
      return { ...state, loading: false, error: action.payload };
    case "CLEAR":
      return { ...state, loading: false, error: null, results: null };
    default:
      return state;
  }
};

const useJsqel = (query, props = {}, initialResults = []) => {
  const [apiParameters, setApiParameters] = useState({ query, props });

  const [state, dispatch] = useReducer(jsqelReducer, {
    loading: false,
    error: null,
    results: initialResults,
  });

  // console.log("useJsqel is processing :", query, state, apiParameters.props && apiParameters.props.sendItNow)
  // Traitmenent du cas on l'on exécute une deuxième fois la même requête, avec les mêmes paramètres alors que la première n'est pas terminée
  // const requeteDoublon  = state.loading && state.query === apiParameters.query && deepEqual(state.apiParameters , apiParameters)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let cancel = () => true; // fonction permettant d'abandonner la requête
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT", payload: { query, apiParameters } });
      try {
        // Add token if any
        const token = window.localStorage.getItem("idhome_token");

        //console.log('useJsqel is sending :', apiParameters.query)
        const result = await axios.post(
          url + apiParameters.query,
          apiParameters.props,
          token
            ? {
                headers: { Authorization: `Bearer ${token}` },
                cancelToken: new CancelToken((c) => (cancel = c)),
              }
            : { cancelToken: new CancelToken((c) => (cancel = c)) }
        );
        //console.log('-- Resultat de la requete :', query,result)
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        if (apiParameters.props && apiParameters.props.callback)
          apiParameters.props.callback({ error: null, results: result.data });
      } catch (error) {
        console.log("error :", error);

        // Inutile d'alle plus loin si la requête a été abandonnée, car le composant n'existe plus.
        if (axios.isCancel(error)) {
          // console.log("Request canceled");
          return;
        }

        dispatch({ type: "FETCH_FAILURE", payload: error });

        if (apiParameters.props && apiParameters.props.callback)
          apiParameters.props.callback({ error: error, results: null });
      }
    };
    if (apiParameters.props && apiParameters.props.sendItNow) fetchData();
    // Si le composant disparaît avant le retour de la requête SQL, abandonner la requête
    return () => cancel("Unmounted component");
  }, [apiParameters, query]); // Cette dépendance assure que la même requête avec les mêmes paramètres ne sera pas envoyée 2 fois.
  // Subsiste cependant le pb des race conditions (voir https://sebastienlorber.com/handling-api-request-race-conditions-in-react)

  const refresh = (newParams = {}) =>
    setApiParameters({
      query: query,
      props: Object.assign({}, apiParameters.props, newParams, {
        sendItNow: true,
      }),
    });

  const clear = () => dispatch({ type: "CLEAR" });

  return [state, refresh, clear];
};

export { useJsqel, setToken };
