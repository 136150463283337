import React from 'react';
import IconIdhome from '../layout/IconIdhome'

const DateLocale = ({echeance, prefixe=''}) => {
    const dateEcheance = new Date(echeance)
    const bientotEchu = ((dateEcheance - new Date())/(1000*60*60*24)) < 7
    return (
        <>
            {prefixe + " " + dateEcheance.toLocaleDateString() }
            &nbsp;
            { bientotEchu && <IconIdhome titre="echus" size="small" />}
        </>
    )
}

export default DateLocale

