import React from "react";
import { Badge, Card, Col, Row } from "antd";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { useStore } from "../../../store/store";
import { useLeads } from "../../../api/dashboard";

const stylebadge = {
  backgroundColor: "#65ae43",
  borderColor: "#65ae43",
  color: "white",
};

const DemandesAttentes = () => {

  useLeads(); // envoi d'une requpête get_leads pour actualiser les matchings
  const { state } = useStore();
  const { nb_leads } = state;

  const nbDemandes = nb_leads || 0;

  if (nbDemandes < 1) {
    return null
  }

  return (
    <Card>
      <Row>
        <Col md={24} xl={15}>
          <b>Demandes en attentes :</b>
        </Col>
        <Col md={24} xl={9}>
          <Badge
            style={stylebadge}
            count={
              "" +
              (nbDemandes || "0") +
              " demande" +
              (nbDemandes > 1 ? "s" : "")
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={24} xl={18}>
          <span className="texte-participe">
            <CheckOutlined /> Participer
          </span>{" "}
          ou{" "}
          <span className="texte-refus">
            <CloseOutlined />
            passer{" "}
          </span>{" "}
          une demande pour afficher les suivantes.
        </Col>
      </Row>
    </Card>
  )
};

export default DemandesAttentes;
