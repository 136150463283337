import { useStore } from "../../../store/store";
import { useLeads } from "../../../api/dashboard";

function useDashboard({ useJsqel }) {
  useLeads(); // envoi d'une requête get_leads
  const { state } = useStore();

  const [{ results: compte_utilisateur, loading: loading_compte_utilisateur }] =
    useJsqel("inscription/get_compte", {
      sendItNow: true,
    });

  const compte =
    compte_utilisateur && compte_utilisateur.length
      ? {
          ...compte_utilisateur[0],
          prestations: compte_utilisateur[0].prestations.filter(
            (p) => p.actif === true
          ),
          regions: compte_utilisateur[0].regions.filter(
            (p) => p.actif === true
          ),
        }
      : {};

  const [{ results: liste_devis, loading: loading_liste_devis }] = useJsqel(
    "dashboard/liste_devis",
    {
      sendItNow: true,
    }
  );

  const [
    { results: liste_demandes_ecartees, loading: loading_demandes_ecartees },
  ] = useJsqel("dashboard/liste_ecartes", {
    sendItNow: true,
  });

  const [{ results: messages_promotion, loading: loading_message_promotion }] =
    useJsqel("dashboard/message_promotions", {
      sendItNow: true,
    });

  const { entreprise, solde, nb_demandes_recues } = state;
  const achete = state.achete || 0;

  const actions = {};

  return {
    compte,
    liste_devis,
    liste_demandes_ecartees,
    loading:
      loading_liste_devis ||
      loading_demandes_ecartees ||
      loading_compte_utilisateur ||
      loading_message_promotion,
    entreprise,
    achete,
    solde,
    nb_demandes_recues,
    actions,
    messages_promotion,
  };
}

export { useDashboard };
