import React, { useState } from "react";
import { Upload, Button } from "antd";
import UploadOutlined from "@ant-design/icons/UploadOutlined";

import urlApi from "../../api/url";

const UploadFormItem = React.forwardRef((props, ref) => {
  const { nomFichier, nomFichierInitial, onChange, erreurCallback } = props;
  const [nbFichiers, setNbFichiers] = useState(
    nomFichierInitial && nomFichierInitial.name ? 1 : 0
  );
  const token = window.localStorage.getItem("idhome_token");
  const headers = { Authorization: `Bearer ${token}` };

  const onChangeUploadList = ({ fileList, file, event }) => {
    setNbFichiers(fileList && fileList.length ? fileList.length : 0);

    // On supprime les fichiers de plus de 5 Mo
    // const limitedFileList = fileList.filter(f => f.size < 51000000)
    if (file && file.size && file.size > 5000000) {
      erreurCallback("Le fichier doit avoir une taille < 5Mo");
    } else {
      erreurCallback("");
      onChange(file);
    }
  };

  const beforeUploadList = (file, fileList) => {
    if (file && file.size && file.size > 5000000) return false;
    return true;
  };

  const ajoutAutorise = nbFichiers < 1;

  return (
    <Upload
      ref={ref}
      name={nomFichier}
      action={urlApi() + "services/upload"}
      onChange={onChangeUploadList}
      beforeUpload={beforeUploadList}
      headers={headers}
      listType="text"
      multiple={false}
      accept=".pdf"
      defaultFileList={
        nomFichierInitial && nomFichierInitial.name ? [nomFichierInitial] : null
      }
    >
      <Button disabled={!ajoutAutorise}>
        <UploadOutlined /> Télécharger
      </Button>
    </Upload>
  );
});

export default UploadFormItem;

/*
onChange

The function will be called when uploading is in progress, completed or failed.
When uploading state change, it returns:

{
  file: {  },
  fileList: [ ],
  event: {  },
}

*/
