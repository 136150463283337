import React from "react";
import LockOutlined from "@ant-design/icons/LockOutlined";
import { Typography } from "antd";

const Cadenas = () => (
  <div>
    <LockOutlined style={{ color: "red" }} />{" "}
    <Typography.Text disabled>(verrouillé avant achat)</Typography.Text>
  </div>
);

export default Cadenas;
