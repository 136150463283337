import React from "react";
import { t } from "../../../../Internationalisation/traduction";

export const PropositionRepublication = ({ sources }) => {
  if (sources.results && sources.results.length) {
    return (
      <span>
        Nous vous invitons à republier votre demande sur notre site{" "}
        <a href={"https://" + sources.results[0].libelle} alt="IDhome">
          {sources.results[0].libelle}
        </a>
      </span>
    );
  } else {
    return (
      <span>
        Nous vous invitons à republier votre demande sur notre site{" "}
        <a href={t("fr", "url_site")} alt={t("fr", "marque")}>
          {t("fr", "marque")}
        </a>
      </span>
    );
  }
};
