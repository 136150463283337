import React from "react";

const Cover = ({
  img,
  titre,
  sousTitre,
  extra,
  avecLiseret = false,
  positionTexte,
  hauteur_top_em,
  hauteur_bottom_em,
  hauteur_top_max_px,
}) => {
  const styleCover = {
    paddingTop: hauteur_top_em && hauteur_top_em + "em",
    paddingBottom: hauteur_bottom_em && hauteur_bottom_em + "em",
    maxHeight: hauteur_top_max_px && hauteur_top_max_px + "px",
  };
  // container-mw-xl
  return (
    <section
      className={avecLiseret ? "cover cover-liseret" : "cover"}
      style={styleCover}
    >
      <div
        className="cover-bg-img d-none d-md-block cover-bg-img-idhome"
        style={{ backgroundImage: "url(" + img + ")" }}
      ></div>
      <div className="container-fluid container-mw-xxl">
        <div className="row">
          {positionTexte === "droite" && <div className="col-lg-6" />}
          <div className="col-xl-5 col-lg-6">
            <h1 style={{ fontSize: "1.9rem" }}>{titre}</h1>
            <p className="lead">{sousTitre}</p>
            {extra && (
              <>
                <div className="divider divider-short border-primary my-4"></div>
                <p>{extra}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cover;
