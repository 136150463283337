import React from "react";
import { Redirect, Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";
import { Row, Col, Button, Card, Menu } from "antd";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";

import Loading from "../layout/Loading";
import Faqs from "./Faq";
import TitreSection from "../layout/TitreSection";

import { useJsqel } from "../../api/jsqel";

const faqHeaderClient = {
  background: "#3f82b2",
  color: "white",
  border: "3 px solid",
  borderColor: "white",
  marginBottom: "1rem",
};

const AideEnLigne = ({ data, menu, callback }) => {
  const [{ loading, results, error }] = useJsqel("faqs/faqs", {
    sendItNow: true,
  });
  const FaqsRubrique = (id_rubrique) =>
    results ? results.filter((r) => r.id === id_rubrique) : [];
  const TitreRubrique = (id_rubrique) => {
    if (results) {
      const faq = results.find((r) => r.id === id_rubrique);
      if (faq) return faq.libelle;
      else return "";
    }
  };
  const rowStyleMargin = { marginTop: "1rem" };
  const rowStyleCenteredText = { textAlign: "right" };

  const scrollToRef = (key) => {
    window.scrollTo({
      top:
        (document.getElementById("menu-" + key) &&
          document.getElementById("menu-" + key).offsetTop) ||
        0,
      left: 0,
      behavior: "smooth",
    });
  };

  if (error) return <Redirect to="/login" />;

  if (loading) return <Loading />;

  if (data.length !== 4)
    return (
      <Card>Erreur : il doit y avoir 4 rubriques dans l'aide en ligne</Card>
    );

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      {menu ? (
        <Row gutter={{ md: 4, xl: 16 }} style={{ marginBottom: "1rem" }}>
          <Col xs={24} xl={6} style={rowStyleMargin}>
          <TitreSection
                titre="Questions - Réponses"
                accroche="Support FAQ en ligne"
              />
          </Col>

          <Col xs={24} xl={18} style={rowStyleMargin}>
            <Menu
              mode="horizontal"
              onClick={({ item, key }) =>
                key > 0 && key < 5 && scrollToRef(key - 1)
              }
            >
              {data.map((d) => (
                <Menu.Item key={d.id}>{TitreRubrique(d.id)}</Menu.Item>
              ))}
              <span>&nbsp;</span>
              <Menu.Item key={5}>
                <Link to="/messages">
                  {" "}
                  <Button icon={<MessageOutlined />} type="primary">
                    Aide en ligne{" "}
                  </Button>{" "}
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      ) : (
        <Card style={faqHeaderClient}>
          <Row gutter={{ md: 4, xl: 16 }}>
            <Col xs={24} xl={20}>
              <h4>
                Se lancer dans un projet de travaux, soulève souvent une foule
                de questions !{" "}
              </h4>
              <p>
                Le choix des professionnels est difficile, les démarches à
                effectuer ne sont pas toujours connues.
              </p>
              <p>Voici les réponses aux questions les plus fréquentes.</p>
            </Col>
            <Col xs={24} xl={4} style={rowStyleCenteredText}>
              <Button
                icon={<CloseCircleOutlined />}
                type="primary"
                style={{ backgroundColor: "#8eB350", borderColor: "#8eB350" }}
                onClick={() => callback()}
              >
                Retour aux offres
              </Button>
            </Col>
          </Row>
        </Card>
      )}

      <Card>
        <QueueAnim type="bottom" className="ui-animate">
          {data.map((d, i) => (
            <div key={"menu-" + i} id={"menu-" + i}>
              {" "}
              {results && (
                <Faqs
                  titre={TitreRubrique(d.id)}
                  faqs={FaqsRubrique(d.id)}
                  gauche={d.gauche}
                  image={d.image}
                />
              )}{" "}
            </div>
          ))}
        </QueueAnim>
      </Card>
    </div>
  );
};

export default AideEnLigne;
