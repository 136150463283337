import React from 'react';
import { useContacts } from './Controller/useContacts';
import ContactsLayout from "./Presentation/ContactsLayout"
import { useJsqel } from "../../api/jsqel"

const Contacts = () => {
    return (
        <ContactsLayout {...useContacts({ useJsqel })} />
    );
};

export default Contacts;

