import React, { useState } from "react";
import {
  Button,
  Tooltip,
  Radio,
  message,
  Modal,
  Popconfirm,
  Alert,
} from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

import { useJsqel } from "../../api/jsqel";
import { useCredit } from "../../api/credits";
import StripeCheckout from "./StripeCheckout";
import { t } from "../../Internationalisation/traduction";
import DescriptionPack from "./DescriptionPack";
import { Check } from "./Check";

const PackTemplate = ({
  id,
  credits,
  devise,
  prix,
  tva,
  prix_avant_reduction,
  nb_mois_validite,
  refus,
  template,
  libelle,
  texte_description,
  en_avant,
  email,
  fournisseur_id,
  entreprise,
  pays_id,
}) => {
  const valideVirement = ({ error, results }) => {
    
    if (error) {
      message.error(
        "Un problème est survenu lors de votre commande. Merci de vous reconnecter et de faire une nouvelle tentative. En cas d'échec, prenez contact avec l'administrateur."
      );
    } else if (
      results &&
      results.length &&
      !!results[0].ajoute_credit_virement
    ) {
      Modal.info({
        title: "Une demande est déjà en cours",
        content: (
          <div>
            <p>
              Votre commande a bien été enregistrée mais ne peut pas être
              traitée pour le moment car une demande est déjà en cours.
            </p>
          </div>
        ),
        onOk() {},
      });
    } else {
      Modal.info({
        title: "Votre commande par virement est pris en compte",
        content: (
          <div>
            <p>
              Votre commande a bien été enregistrée, notre équipe va la traiter
              dans les plus brefs délais.
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    
  };

  const [
    { results: intentSecret, loading: loadingCB },
    refreshCB,
    clearIntentSecret,
  ] = useJsqel("stripe/payment", {
    sendItNow: false,
  });

  const [{ loading: loadingVirement }, refreshVirement] = useJsqel(
    "stripe/virement",
    {
      sendItNow: false,
      pack_id: id,
      callback: valideVirement,
    }
  );

  const paiementParVirementUniquement =
    t("fr", "cta_achat_par_stripe") === false;
  const paimentBancontactPossible = pays_id === 3; // Bancontact : seulement pour la Belgique
  const [methodePaiement, setMethodePaiement] = useState(
    paiementParVirementUniquement ? 3 : 1
  ); // CB par défaut
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [refreshCredit] = useCredit();

  const feedback = ({ error, text }) => {
    if (text) {
      if (error) {
        setErrorMessage(text);
      } else {
        setSuccessMessage(text);
        // Wait 3 seconde for the Stripe callback then udate credit
        setTimeout(refreshCredit, 2000);
      }
    }
    clearIntentSecret();
  };

  const getIntentSecret = () =>
    refreshCB({
      description: "Achat pack " + credits + " crédits",
      currency: devise,
      amount: prix * 100 + Math.ceil(tva * prix),
      receipt_email: email,
      metadata: { fournisseur_id: fournisseur_id, pack_id: id },
      payment_method_types: paimentBancontactPossible
        ? ["bancontact", "card"]
        : ["card"],
      payment_method_options: {
        bancontact: {
          preferred_language: "fr",
        },
      },
    });

  const estUnPackSansCredit = ["website", "services"].includes(template);

  return (
    <article
      className={
        "pricing-table-v1" + (en_avant ? " pricing-table-highlight" : "")
      }
    >
      <header>
        <h2>{libelle}</h2>
      </header>
      {estUnPackSansCredit ? (
        <p className="pricing-price">
          {prix} {devise} HT
        </p>
      ) : (
        <p className="pricing-price">
          {credits}
          <span>crédits</span>
        </p>
      )}
      {estUnPackSansCredit ? null :
      <p className="pricing-price-detail">
        {prix} {devise}{" "}
        {prix_avant_reduction && (
          <span> {prix_avant_reduction + " " + devise}</span>
        )}{" "}
        HT
      </p> }
      <div className="pricing-plan-details">
        {!estUnPackSansCredit ? (
          <ul>
            <li>
              <b>Inclus</b>
            </li>
            <li>
              <Check /> Validité : {nb_mois_validite} mois
            </li>
            <li>
              <Check /> Nombre de crédits : {credits}
            </li>
            <li>
              <Check /> Coût unitaire :{" "}
              {Number.parseFloat(prix / credits).toFixed(2)}
            </li>
            <li>
              <Check /> Moyenne de prospects : {Math.round(credits / 3)}&nbsp;
              <Tooltip title="Nombre de prospects basé sur une moyenne de 3 crédits par demande.">
                <QuestionCircleOutlined style={{ color: "#a1a8b9" }} />
              </Tooltip>
            </li>
            <li>
              <Check /> Nombre de refus : {refus} &nbsp;
              <Tooltip title="Nombre de refus inclus à ce pack. Vous pouvez refuser une proposition de demande de devis sans être impacté dans votre solde de crédit. Passé ce quota, chaque refus de prendre une demande de travaux vous sera impactée de 0,10 crédit.">
                <QuestionCircleOutlined style={{ color: "#a1a8b9" }} />
              </Tooltip>
            </li>
            {template === "premium" ? (
              <li>
                <Check /> Mise en avant de l'offre &nbsp;
                <Tooltip
                  title={
                    <span>
                      <img
                        width={80}
                        height="auto"
                        src="/assets/tooltip_pack514x456.png"
                        alt="tooltip"
                      />
                      <br />
                      Votre offre est mise en avant avec un fond de couleur bleu
                      et un label “Premium”. Votre offre sort du lot pour se
                      démarquer de vos concurrents.
                    </span>
                  }
                >
                  <QuestionCircleOutlined style={{ color: "#a1a8b9" }} />
                </Tooltip>
              </li>
            ) : null}
          </ul>
        ) : null}
        {texte_description ? (
          <DescriptionPack description={texte_description} />
        ) : (
          <div style={{ marginBottom: "1rem" }} />
        )}
      </div>
      <footer style={{ display: "flex", flexDirection: "column" }}>
        {!paiementParVirementUniquement && (
          <Radio.Group
            onChange={(e) => setMethodePaiement(e.target.value)}
            value={methodePaiement}
            style={{ marginBottom: "1rem" }}
          >
            <Radio value={1}>CB</Radio>
            {paimentBancontactPossible && <Radio value={2}>Bancontact</Radio>}
            <Radio value={3}>Virement</Radio>
          </Radio.Group>
        )}

        {intentSecret && (
          <StripeCheckout
            amount={prix * 100 + Math.ceil(tva * prix)}
            currency={devise}
            intentSecret={intentSecret && intentSecret.client_secret}
            feedback={feedback}
            receipt_email={email}
            entreprise={entreprise}
            libelle={libelle}
            type={methodePaiement === 2 ? "bancontact" : "card"}
            id={id}
          />
        )}

        <div style={{ flex: 1 }}>
          {methodePaiement < 3 ? (
            <Button
              loading={loadingCB}
              size="large"
              style={{ width: "100%", fontSize: "13px" }}
              onClick={() => getIntentSecret()}
            >
              {t("fr", "cta_achat_par_stripe") + " "}
              {methodePaiement === 2 ? "Bancontact" : "carte de crédit"}
            </Button>
          ) : (
            <Popconfirm
              title={`Confirmez-vous cette commande ? `}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => refreshVirement()}
              cancelText="Non"
            >
              <Button
                size="large"
                style={{ width: "100%", fontSize: "13px" }}
                loading={loadingVirement}
              >
                {t("fr", "cta_achat_par_virement")}
              </Button>
            </Popconfirm>
          )}
        </div>
      </footer>

      {successMessage && (
        <Alert
          message={successMessage}
          type="success"
          showIcon
          style={{ marginTop: "1rem" }}
        />
      )}
      {errorMessage && (
        <Alert
          message={errorMessage}
          type="error"
          showIcon
          style={{ marginTop: "1rem" }}
        />
      )}
    </article>
  );
};

export default PackTemplate;
