import React from "react";

const logoStyle = {
  float: "left",
  margin: "0px 15px 0",
};

const mobileLogoStyle = {
  height: "30px",
  width: "auto",
};

const Logo = ({ full }) => (
  <div style={logoStyle}>
    {full ? (
      <img src="/assets/logo-home.png" alt="logo" />
    ) : (
      <img src="/assets/logo-icon.png" alt="logo" style={mobileLogoStyle} />
    )}
  </div>
);

export default Logo;
