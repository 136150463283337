import React from "react";
import { WarningOutlined } from "@ant-design/icons";

export const HeaderOffres = ({
  libelleOffre,
}) => (
  <>
    <h3 className="article-title-idhome">
      Votre demande
      {libelleOffre
        ? " : " + libelleOffre
        : " n'a pas encore reçu de proposition. Vous serez averti par mail dès l'ajout d'une nouvelle offre"}
    </h3>
    <div className="divider border-primary mb-4"></div>
    <p>
      <WarningOutlined style={{ color: "red" }} /> Avant de valider une offre,
      attendez de pouvoir la comparer avec d'autres devis d'entreprises.
      <br />
      La validation d'une offre est définitive. Vous ne pourrez pas en changer.
    </p>
  </>
);
