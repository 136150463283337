import React from "react";
import { Typography, Descriptions, Card, Button } from "antd";

const { Paragraph } = Typography;
const cardStyle = { marginBottom: "1rem" };

const CardCompte = ({
  liste,
  callback,
  editionEnCours,
  title,
  onSave,
  loading,
}) => {
  if (!liste || !liste.length) return null;
  return (
    <Card
      style={cardStyle}
      loading={loading}
      title={title}
      extra={
        editionEnCours && (
          <Button type="primary" onClick={onSave}>
            Enregistrer
          </Button>
        )
      }
    >
      <Descriptions layout="vertical" bordered>
        {liste.map((element) => (
          <Descriptions.Item key={element.id} label={element.label}>
            <Paragraph
              editable={
                callback && element.editable
                  ? { onChange: (t) => callback(element.id, t) }
                  : null
              }
            >
              {element.valeur}
            </Paragraph>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
};

export default CardCompte;
