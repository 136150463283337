import React, { useState, createContext, useContext } from "react";
import { Tabs, Col, Row } from "antd";
import TitreSection from "./TitreSection";

const MenuTabContext = createContext({ activeKey: "", setActiveKey: () => {} });

export const useMenuTabs = () => useContext(MenuTabContext);

const EcranAvecMenuTab = ({ items, defaultKey, children, extra }) => {
  const [activeKey, setActiveKey] = useState(defaultKey);

  const tabItems = [
    ...items.map((item) => ({
      label: item.label,
      key: item.key,
      children: <TitreSection titre={item.titre} accroche={item.accroche} />,
    })),
    ...(extra || []),
  ];
  const selectedItem = items.find((item) => item.key === activeKey);
  const itemsKeys = new Set(items.map((item) => item.key));

  return (
    <MenuTabContext.Provider value={{ activeKey, setActiveKey }}>
      <div>
        <div
          className="container-fluid container-mw-xxl chapter"
          style={{ marginTop: "3rem", marginBottom: "2rem" }}
        >
          <Row
            type="flex"
            justify="space-between"
            align="stretch"
            style={{ marginBottom: "1rem" }}
            gutter={16}
          >
            <Col xs={24} xl={children ? 12 : 16} xxl={12}>
              <Row gutter={{ md: 4, xl: 16 }}>
                <Col xs={24} xl={extra && extra.length ? 24 : 16}>
                  <Tabs
                    items={tabItems}
                    activeKey={activeKey}
                    onChange={(newKey) =>
                      itemsKeys.has(newKey) && setActiveKey(newKey)
                    }
                    style={{ marginBottom: "1rem" }}
                    tabBarGutter={60}
                    tabBarStyle={{
                      backgroundColor: "white",
                      paddingLeft: "1rem",
                      borderRadius: "3px",
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              {children}
            </Col>
          </Row>
        </div>
        <div key={activeKey}>{selectedItem ? selectedItem.children : null}</div>
      </div>
    </MenuTabContext.Provider>
  );
};

export default EcranAvecMenuTab;
