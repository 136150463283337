import { useReducer } from "react";
import { useMenuTabs } from "../../layout/EcranAvecMenuTab";

const reducer = (state, action) => {
  switch (action.type) {
    case "SYNC":
      return {
        key: Date.now(),
        modifie: false,
        configuration: action.website?.configuration || {},
        etat: action.website?.etat || "",
        erreur: null,
      };
    case "ERREUR":
      return { ...state, erreur: action.erreur };
    case "UPDATE":
      return {
        ...state,
        modifie: true,
      };

    default:
      return state;
  }
};

const formulaireInitial = {
  key: 0,
  modifie: false,
  siteActif: false,
  configuration: {},
  erreur: null,
};

export function useFormulaireWebsite(useJsqel) {
  const [formulaire, dispatch] = useReducer(reducer, formulaireInitial);

  // Contexte du tab pour pouvoir le changer
  const { activeKey, setActiveKey } = useMenuTabs();

  const [{ loading }, refresh] = useJsqel("website/obtenir", {
    sendItNow: true,
    callback: ({ results, error }) =>
      results && !error
        ? dispatch({ type: "SYNC", website: results })
        : dispatch({ type: "ERREUR", erreur: error }),
  });

  // Si le site est à l'état "initialise" après enregistrement, alors rediriger vers l'achat d'un pack
  const apresEnregistrement = (results) => {
    if (results && results === "initialise" && activeKey === "info") {
      setActiveKey && setActiveKey("website");
    }
    refresh();
  };
  const [, enregistrerFormulaire] = useJsqel("website/enregistrer", {
    callback: (retour) => {
      retour?.error
        ? dispatch({ type: "ERREUR", erreur: retour.error })
        : apresEnregistrement(retour?.results);
    },
  });

  const enregistrer = (values) => {
    // Les couleurs doivent être transformées
    const couleurPrincipale =
      values.couleurPrincipale?.hex || values.couleurPrincipale;
    const couleurSecondaire =
      values.couleurSecondaire?.hex || values.couleurSecondaire;

    enregistrerFormulaire({
      configuration: {
        ...values,
        couleurPrincipale,
        couleurSecondaire,
      },
    });
  };

  const onChange = (values) =>
    dispatch({ type: "UPDATE", configuration: values });

  return {
    loading: loading,
    key: formulaire.key, // Valeur unique qui change lorsque le formulaire est synchronisé avec le backend
    configuration: formulaire.configuration,
    modifie: formulaire.modifie,
    erreur: formulaire.erreur,
    etat: formulaire.etat,
    onChange,
    enregistrer,
  };
}
