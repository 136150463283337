import React from "react";
import { Layout } from "antd";

import FacebookFilled from "@ant-design/icons/FacebookFilled";
import InstagramOutlined from "@ant-design/icons/InstagramOutlined";
import LinkedinOutlined from "@ant-design/icons/LinkedinOutlined";
import { t } from "../../Internationalisation/traduction";

const { Footer } = Layout;

const numeroVersion = process.env.REACT_APP_VERSION_ENV;
//TODO : vérifier que fournisseur et client ont bien les mêmes documents.

const FooterContent = ({ client = false, bg = "bg-white" }) => (
  <Footer className={"app-footer " + bg}>
    <div className="footer-row footer-inner-v1">
      <ul className="list-inline list-inline-split">
        <li className="list-inline-item">
          <a
            target="_blank"
            rel="noreferrer"
            href={
              client
                ? t("fr", "nom_fichier_cgu_client")
                : t("fr", "nom_fichier_cgu_fournisseur")
            }
            alt="Conditions générales d'utilisation"
          >
            CGU
          </a>
        </li>
        <li className="list-inline-item">
          <a
            target="_blank"
            rel="noreferrer"
            href={t("fr", "nom_fichier_confidentialite")}
            alt="Protection des données"
          >
            Protection des données
          </a>
        </li>
      </ul>
    </div>
    <div className="footer-row footer-inner-v1">
      <span className="footer-copyright">
        <span>
          © 2009-2023 {t("fr", "nom_entreprise_footer")}. Tous droits réservés.
          version {numeroVersion}
        </span>
      </span>

      <ul className="footer-social-list"></ul>
    </div>
  </Footer>
);

export default FooterContent;
