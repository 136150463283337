import { useState } from "react";
import { EtatFeedback } from "./EtatFeedback";

function calculeEtat(devis) {
  if (!devis || !devis.length) return EtatFeedback.INITIALISATION;
  const enqueteDejaRenseignee = devis[0].visite_formulaire_satisfaction;
  switch (true) {
    case !devis.some((d) => d.etat === "selectionne") && !enqueteDejaRenseignee:
      return EtatFeedback.AUCUN_DEVIS_SELECTIONNE;
    case devis.some((d) => d.etat === "selectionne") && !enqueteDejaRenseignee:
      return EtatFeedback.ENQUETE_SATISFACTION;
    case !devis.some((d) => d.etat === "selectionne") && enqueteDejaRenseignee:
      return EtatFeedback.PROPOSITION_REPUBLICATION;
    case devis.some((d) => d.etat === "selectionne") && enqueteDejaRenseignee:
      return EtatFeedback.ENQUETE_SATISFACTION_RENSEIGNEE;
    default:
      return EtatFeedback.INITIALISATION;
  }
}

export function useFeedback({ useJsqel, match }) {
  const lien_unique = match.params.id_client || "id-inconnu";
  const [etat, setEtat] = useState(EtatFeedback.INITIALISATION);
  const [devis, refreshListeDevis] = useJsqel("client/offres", {
    sendItNow: true,
    client: lien_unique,
    callback: ({ results }) => setEtat(calculeEtat(results)),
  });
  const devisNote =
    etat === EtatFeedback.ENQUETE_SATISFACTION
      ? devis.results.find((d) => d.etat === "selectionne")
      : null;

  const [{ loading: loadingFinEnquete }, marquerFinEnquete] = useJsqel(
    "client/visite_page_formulaire_satisfaction",
    {
      client: lien_unique,
      callback: () => refreshListeDevis(),
    }
  );

  const [{ loading: loadingSelection }, selectionnerDevis] = useJsqel(
    "client/choix_offre",
    {
      client: lien_unique,
      callback: () => refreshListeDevis(),
    }
  );

  // doit aussi passer visite_formulaire_satisfaction à true
  const [{ loading: loadingReponse }, repondreEnquete] = useJsqel(
    "client/evaluation_satisfaction_lead",
    {
      client: lien_unique,
      callback: () => refreshListeDevis(),
    }
  );

  const actions = {
    marquerFinEnquete,
    selectionnerDevis,
    repondreEnquete,
  };

  const loading =
    loadingReponse || loadingSelection || loadingFinEnquete || devis.loading;

  return {
    etat,
    actions,
    offres: devis.results,
    devisNote,
    lien_unique,
    loading,
  };
}
