export const textes = {
  fr: {
    marque: "IDhome",
    nom_entreprise_footer: "IDhome",
    facebook: "https://www.facebook.com/idhome.io/",
    instagram: "https://www.instagram.com/idhome.ch/",
    linkedin: "https://www.linkedin.com/company/idtravaux/",
    url_site: "https://idhome.io",
    url_app: "https://idhome.app",
    email_site: "info@idhome.io",
    email_devis: "devis@idhome.app",
    avec_menu_login: true,
    hero_login: "BONJOUR",
    hero_login_soustitre:
      "Booster votre activité commerciale et recevez des opportunités de travaux !",
    login_texte_formulaire: "Vos nouveaux clients à portée de clic",
    url_app_android:
      "https://play.google.com/store/apps/details?id=com.idhome.idhome_mobile",
    url_app_ios: "https://apps.apple.com/fr/app/idhome-pro/id1590148715",
    nom_app_mobile: "IDhome.io PRO",
    aide_en_ligne: "Aide en Ligne",
    entreprise: "Nom de l'entreprise",
    nom: "Mon nom",
    prenom: "Mon prénom",
    email: "Email",
    forme_juridique: "Forme juridique",
    siret: "N° d'immatriculation",
    annee_creation: "Année de création",
    telephone: "Téléphone",
    ville: "Ville",
    adresse: "Adresse",
    code_postal: "Code postal",
    description_courte: "Description de mon activité",
    site_internet: "Site Internet",
    score: "Avis des Utilisateurs",
    facture_entreprise: "IDhome.app - KODE Sàrl",
    facture_adresse_1: "Route Tsingale 4",
    facture_adresse_2: "CH - 1046 Rueyres",
    facture_url: "www.idhome.app",
    facture_tva: "TVA CH-550.1.160.828-8",
    titre_notification: "IDhome.io Pro",
    cta_achat_par_stripe: "Paiement par", // mettre false si non autorisé
    cta_achat_par_virement: "Paiement par virement",
    nom_fichier_cgu_fournisseur: "https://www.idhome.io/cgu-pro/",
    nom_fichier_cgu_client: "https://www.idhome.io/cgu.html",
    nom_fichier_confidentialite: "/protection_des_donnees.pdf",
    nom_fichier_manuel_utilisateur: "/manuel.pdf",
    desactivation_choix_prestation: false,
    titre_ecran_factures: "Factures",
    soustitre_ecran_factures: "Téléchargez vos factures",
    economie_pack_business: "Save 13.5%",
    economie_pack_premium: "Save 36%",
    nom_pack_business: "Packs crédits",
    nom_pack_premium: "Packs Premium",
    detail_offres_web_url: "https://web.idhome.io/",
    cgv_offres_web_url: "https://web.idhome.io/cgv-website/",
  },
};
