import React from "react";
import { Row, Col } from "antd";
import { useMobile } from "../../helpers/helpers";
import CarteFaq from "./CarteFaq";

const imgBackground = { width: "100%", height: "auto" };

const Faqs = ({ titre, faqs, image, gauche }) => {
  const { isMobile } = useMobile();
  return (
    <Row>
      {gauche && !isMobile && (
        <Col xs={24} xl={12}>
          {" "}
          <img alt={titre} src={image} style={imgBackground} />{" "}
        </Col>
      )}

      <Col xs={24} xl={12}>
        <CarteFaq titre={titre} faqs={faqs} />
      </Col>

      {!gauche && !isMobile && (
        <Col xs={24} xl={12}>
          {" "}
          <img alt={titre} src={image} style={imgBackground} />{" "}
        </Col>
      )}
    </Row>
  );
};

export default Faqs;


