import React from "react";
import Cover from "../layout/Cover";
import { t } from "../../Internationalisation/traduction";

export const PacksCover = () => (
  <Cover
    img="/assets/banner-pack-credits.jpg"
    hauteur_top_em="2"
    positionTexte="droite"
    titre={<span className="titre-pack">
      Créditer facilement votre compte
      <br />
      <img
        src="/assets/logo-paiement.png"
        alt="Paiement par CB et Bancontact" />
    </span>}
    extra={<span className="soustitre-pack">
      Attribuez des crédits {t("fr", "marque")} sur votre compte
      <br />
      Participez aux demandes clients pour votre entreprise
      <br />
      <br />
      L'attribution des crédits se fait instantanément après paiement
    </span>} />
);
