import { useStore } from "../store/store";
import { useJsqel } from "./jsqel";

const useCredit = (updateNow) => {
  const { state, dispatch } = useStore();

  const callback = ({ results }) => {
    if (results && results.length) {
      // Attention, le solde est une chaîne de caracactères
      dispatch({ type: "UPDATE_CREDITS", payload: results[0] });
    }
  };

  const [, refresh] = useJsqel("inscription/get_credits", {
    sendItNow: updateNow,
    callback,
  });

  return [refresh, state.solde, state.solde_refus, state.achat_website];
};

export { useCredit };

// Usage : const [refresh, credit] = useCredit()
