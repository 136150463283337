import React from "react";
import { Result } from "antd";
import { t } from "../../../../Internationalisation/traduction";

const LienSite = () => {
  return (
    <span>
      Nous vous invitons à publier votre demande sur notre site{" "}
      <a href={t("fr", "url_site")} alt={t("fr", "marque")}>
        {t("fr", "marque")}
      </a>
    </span>
  );
};

const RepublierAnnonce = () => (
  <Result
    title="Vous avez une nouvelle annonce à publier ?"
    subTitle={<LienSite />}
    style={{ height: "60vh" }}
  />
);

export default RepublierAnnonce;
