import React from 'react';
import AideEnLigne from './AideEnLigne'

// Ce serait mieux de placer ces données en BD
const aideEnLigneClient = [
    { id:5,  gauche:false, image:"/assets/faq-fonctionnement-pro.jpg"},
    { id:6,  gauche:true, image:"/assets/faq-relation-client-pro.jpg"},
    { id:7,  gauche:false, image:"/assets/faq-compte-utilisateur.jpg"},
    { id:8,  gauche:true, image:"/assets/faq-prix-pro.jpg"},
]

const AideEnLigneClient = ({callback}) => <AideEnLigne data={aideEnLigneClient} menu={false} callback={callback} />

export default AideEnLigneClient

