import React from "react";
import { Button } from "antd";
import { useStore } from "../../../store/store";

const BoutonPasInteresse = ({ actions, prix, loading }) => {
  const { state } = useStore();
  const solde = state.solde;
  const solde_refus = state.solde_refus;

  return (
    <Button
      loading={loading}
      disabled={Number.parseFloat(solde) < 0.1 && solde_refus < 1 }
      onClick={() => actions.refreshIgnoreLeads()}
      className="bouton-refus"
    >
      Passer
    </Button>
  );
};

export default BoutonPasInteresse;
