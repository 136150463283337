import React from 'react';
import { useJsqel } from "../../../api/jsqel";
import { useClient } from './Controller/useClient';
import ClientLayout from "./Presentation/ClientLayout"


const Client = ({ match }) => {
    return (
        <ClientLayout {...useClient({ match, useJsqel })} />
    );
};

export default Client;

