import { useStore } from "../store/store";
import { useJsqel } from "./jsqel";

const useLeads = (updateNow = true, callbackLeads) => {
  const { dispatch } = useStore();

  const callback = ({ results }) => {
    if (results && results.length) {
      dispatch({ type: "UPDATE_DASHBOARD", payload: results[0] });
    }
  };

  const callbackEtatDevis = ({ results }) => {
    if (results && results.length) {
      // Results :
      // [{"nb_devis":"1","etat":"achete"},{"nb_devis":"1","etat":"devis_envoye"},{"nb_devis":"1","etat":"selectionne"},{"nb_devis":"1","etat":"refus"}]
      dispatch({
        type: "UPDATE_DASHBOARD",
        payload: { achete: 0, devis_envoye: 0, selectionne: 0, refus: 0 },
      });
      results.forEach(({ nb_devis, etat }) => {
        const payload = { [etat]: nb_devis };
        dispatch({ type: "UPDATE_DASHBOARD", payload });
      });
    }
  };

  // requêtes permettant d'obtenir les indicateurs
  const [, refreshDevis] = useJsqel("dashboard/devis", {
    sendItNow: false,
    callback,
  });

  // Actualisation du store avec les devis par état ("Mes contacts")
  const [, refreshDevisParEtat] = useJsqel("dashboard/nb_devis", {
    sendItNow: false,
    callback: callbackEtatDevis,
  });

  const [, refreshTotalDemandes] = useJsqel("dashboard/total_demandes_recues", {
    sendItNow: false,
    callback: callback,
  });

  const refresh = () => {
    refreshDevis();
    refreshDevisParEtat();
    refreshTotalDemandes();
    callbackLeads && callbackLeads();
  };

  // Mise à jour des matchings (expirés, hors échéance)
  // Il faut l'appeler avant d'afficher le dashboard...)
  const [leads, refreshDashboard] = useJsqel("leads/get_leads", {
    sendItNow: updateNow,
    callback: refresh,
  });

  return [leads, refreshDashboard];
};

export { useLeads };

// Usage : const [leads, refresh] = useLeads()
