import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, Typography, Card, Button } from "antd";

const TexteProtectionDonnees = ({ modale, history }) => (
  <div>
    {!modale && <h2> Données personnelles</h2>}

    <p className="c6">
      <span className="c5">Date de prise d&rsquo;effet : 25 Mai 2019</span>
    </p>
    <h3 className="c0">
      <span className="c2">Introduction </span>
    </h3>
    <p className="c0">
      <span className="c5">
        Les services de la Plateforme, de l&rsquo;application IDhome.app sous
        laquelle les Prestataires et les Utilisateurs ont un acc&egrave;s est
        h&eacute;berg&eacute;e dans un centre de donn&eacute;es
        s&eacute;curis&eacute; en Suisse.
      </span>
    </p>
    <p className="c0">
      <span>
        En tant que responsable de traitement des donn&eacute;es recueillies sur
        sa Plateforme, la soci&eacute;t&eacute; KODE s&agrave;rl, inscrite au
        registre du commerce suisse sous le num&eacute;ro d&rsquo;identification
        CH-550.1.160.828-8
      </span>
      <span className="c15">&nbsp;</span>
      <span className="c5">
        s&rsquo;engage &agrave; en assurer la protection par des moyens
        ad&eacute;quats et s&eacute;curis&eacute;s.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        La Plateforme se conforme &agrave; la l&eacute;gislation en vigueur sur
        le traitement des donn&eacute;es et tout particuli&egrave;rement
        &agrave; la loi suisse sur la protection des donn&eacute;es suisse
        (LPD), et sur la (RGPD) pour les Utilisateurs et Prestataires de
        l&rsquo;union europ&eacute;enne.{" "}
      </span>
    </p>
    <p className="c0">
      <span>
        La pr&eacute;sente politique de confidentialit&eacute; s&rsquo;applique
        &agrave; toute personne physique particulier ou professionnel visitant
        ou s&rsquo;inscrivant via l&rsquo;un des sites partenaires sur
        l&rsquo;appication IDhome.app
      </span>
      <span className="c18">&nbsp;</span>
      <span className="c5">
        et &agrave; pour but de pr&eacute;senter, d&rsquo;une fa&ccedil;on
        claire et transparente, l&rsquo;utilisation faite des donn&eacute;es
        personnelles recueillies.{" "}
      </span>
    </p>
    <p className="c0">
      <span>
        La pr&eacute;sente politique de confidentialit&eacute; fait partie
        int&eacute;grante des Conditions g&eacute;n&eacute;rales
        d&rsquo;utilisation pour l&rsquo;Utilisateur ou le Prestataire.
      </span>
      <span>
        L&rsquo;Utilisateur d&eacute;clare avoir pris connaissance et
        accept&eacute; la pr&eacute;sente Politique de Confidentialit&eacute;
        pour utiliser les services de la Plateforme.
      </span>
    </p>

    <h3>1. D&eacute;finitions</h3>

    <p className="c0">
      <span className="c5">
        &ldquo;Utilisateurs&rdquo; : particuliers, artisans, architectes,
        g&eacute;rance immobili&egrave;res qui recherchent un ou plusieurs
        Prestataires pour faire r&eacute;aliser des travaux.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        &ldquo;Prestataires&rdquo;: professionnels, artisans, entreprises
        pouvant r&eacute;pondre &agrave; une demande de devis &eacute;mise par
        un Utilisateur.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        &ldquo;Appel d&rsquo;offres&rdquo; : d&eacute;signe une demande de devis
        effectu&eacute;e par un Utilisateur sur la Plateforme dans le but de
        recevoir des Offres des Prestataires.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        &ldquo;Offres&rdquo; d&eacute;signe les devis effectu&eacute;s par les
        Prestataires en r&eacute;ponse aux appels d&rsquo;offres
        d&eacute;pos&eacute;s par les Utilisateurs.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        &ldquo;Plateforme (s)&rdquo;: d&eacute;signe le site web IDhome.app, sur
        lequel sont enregistr&eacute;es toutes les demandes et donn&eacute;es
        des Prestataires.
      </span>
    </p>
    <h3 className="c0">
      <span className="c2">
        2. Collecte des donn&eacute;es &agrave; caract&egrave;res personnelles
        des Utilisateurs
      </span>
    </h3>
    <p className="c0">
      <span>
        Dans le but de d&#39;accomplir son service de mise en relation entre
        Utilisateurs et Prestataires, la Plateforme,{" "}
      </span>
      <span className="c5">
        se r&eacute;serve le droit de collecter des donn&eacute;es nominatives
        relatives aux Utilisateurs, notamment les donn&eacute;es personnelles
        suivantes :
      </span>
    </p>
    <ul className="c11 lst-kix_qypg3xnin5en-0 start">
      <li className="c0 c4">
        <span className="c3">&agrave; caract&egrave;re personnel :</span>
        <span className="c5">
          &nbsp;civilit&eacute;, nom, pr&eacute;nom, adresse postale, email,
          num&eacute;ro de t&eacute;l&eacute;phone ;
        </span>
      </li>
      <li className="c0 c4">
        <span className="c3">
          relatives &agrave; l&rsquo;Appel d&rsquo;offres :
        </span>
        <span className="c5">
          &nbsp;type de travaux, statut d&rsquo;occupation du logement, type de
          bien immobilier, type et &eacute;tat du bien, d&eacute;lai de
          r&eacute;alisation, personnalit&eacute; juridique,
          photographie&hellip;
        </span>
      </li>
      <li className="c0 c4">
        <span className="c3">
          relatives aux aspects s&eacute;curitaires et fonctionnels de la
          Plateforme :
        </span>
        <span className="c5">donn&eacute;es de connexion, adresse IP</span>
      </li>
    </ul>
    <p className="c0">
      <span className="c5">
        Aucunes donn&eacute;es bancaires et financi&egrave;res ni donn&eacute;es
        dites sensibles (r&eacute;v&eacute;lant l&rsquo;origine raciale, les
        opinions politiques et religieuses, des donn&eacute;es concernant la
        sant&eacute; ou l&rsquo;orientation sexuelle de la personne) ne sont
        collect&eacute;es.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        L&rsquo;Utilisateur est inform&eacute; que s&rsquo;il ne souhaite pas
        fournir les donn&eacute;es personnelles demand&eacute;es, la Plateforme
        ne pourra pas ex&eacute;cuter sa fonction de service.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        Les donn&eacute;es peuvent &ecirc;tre collect&eacute;es depuis les
        formulaires de la Plateformes (desktop, et/ou mobile), via ses
        plateformes internet, par t&eacute;l&eacute;phone et
        &eacute;ventuellement par tout autre moyen de communication.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        L&rsquo;acc&egrave;s &agrave; la page des offres &eacute;mises par les
        Prestataires &agrave; destination du l&rsquo;Utilisateur ne requiert pas
        de mot de passe. Un lien unique de connexion (token), est
        d&eacute;livr&eacute; par email &agrave; l&rsquo;Utilisateur pointant
        sur une page en protocole HTTPS. Ce lien unique de connexion &agrave;
        dur&eacute;e limit&eacute;e mentionn&eacute;e dans la page.
      </span>
    </p>
    <h3 className="c0">
      <span className="c2">3. Utilisation des donn&eacute;es personnelles</span>
    </h3>
    <p className="c0">
      <span className="c5">
        La Plateforme utilise les donn&eacute;es personnelles pour fournir et
        am&eacute;liorer ses services et servent strictement &agrave;
        l&rsquo;ex&eacute;cution des finalit&eacute;s suivantes :{" "}
      </span>
    </p>
    <ul className="c11 lst-kix_h4yjrukr42u1-0 start">
      <li className="c6 c4">
        <span className="c3">Identification et authentification</span>
        <span className="c5">
          : La Plateforme utilise les informations d&rsquo;identification pour
          v&eacute;rifier l&rsquo;identit&eacute; de l&rsquo;Utilisateur ou du
          Prestataire lorsqu&rsquo;il acc&egrave;de &agrave; leur compte pour
          utiliser nos services.{" "}
        </span>
      </li>
      <li className="c6 c4">
        <span className="c3">
          Gestion et traitement de l&rsquo;Appel d&rsquo;offres :
        </span>
        <span className="c5">
          &nbsp;La Plateforme traite les donn&eacute;es personnelles pour
          fournir les services sollicit&eacute;s par l&rsquo;Utilisateur.
        </span>
      </li>
      <li className="c6 c4">
        <span className="c3">Am&eacute;lioration des Services</span>
        <span className="c5">
          : La Plateforme analyse les donn&eacute;es sur la mani&egrave;re dont
          nos services sont utilis&eacute;s, pour am&eacute;liorer et adapter
          l&rsquo;exp&eacute;rience d&rsquo;utilisation de nos Utilisateurs.
        </span>
      </li>
      <li className="c6 c4">
        <span className="c3">Communication</span>
        <span className="c5">
          : La Plateforme utilise les donn&eacute;es personnelles &agrave; des
          fins de communication avec ses Utilisateurs et Prestataires. Par
          exemple : pour toute question, informations ou notifications email ou
          SMS.
        </span>
      </li>
      <li className="c6 c4">
        <span className="c3">Marketing : </span>
        <span>La Plateforme </span>
        <span className="c5">
          utiliser les donn&eacute;es personnelles pour mieux comprendre et
          cibler les pr&eacute;f&eacute;rences et les besoins de ses
          Utilisateurs et envoyer des informations et/ou publicit&eacute;s sur
          ses services et offres, notamment sur les r&eacute;seaux sociaux et
          les moteurs de recherches.
        </span>
      </li>
      <li className="c6 c4">
        <span className="c3">Exercice de nos droits:</span>
        <span>
          &nbsp;nous pouvons utiliser vos informations personnelles pour exercer
          nos droits l&eacute;gaux lorsqu&rsquo;il est n&eacute;cessaire de le
          faire, par exemple pour identifier, pr&eacute;venir et r&eacute;pondre
          aux demandes frauduleuses, aux demandes enfreignant la
          propri&eacute;t&eacute; intellectuelle ou aux infractions &agrave; la
          loi ou &agrave; nos{" "}
        </span>
        <span>Conditions d&rsquo;utilisation.</span>
      </li>
      <li className="c4 c6">
        <span className="c3">Respect de nos obligations</span>
        <span className="c5">
          : nous pouvons traiter vos informations personnelles pour, par
          exemple, effectuer des v&eacute;rifications de pr&eacute;vention des
          fraudes ou respecter d&rsquo;autres exigences l&eacute;gales ou
          r&eacute;glementaires, lorsque cela est explicitement requis en vertu
          de la loi.
        </span>
      </li>
      <li className="c6 c4">
        <span className="c3">Personnalisation de votre exp&eacute;rience</span>
        <span className="c5">
          : lorsque vous utilisez la Plateforme, nous pouvons avoir recours
          &agrave; vos informations personnelles pour am&eacute;liorer votre
          exp&eacute;rience, tel qu&rsquo;en vous fournissant des
          &eacute;l&eacute;ments interactifs ou personnalis&eacute;s sur les
          Services et un contenu bas&eacute; sur vos centres
          d&rsquo;int&eacute;r&ecirc;t ;
        </span>
      </li>
    </ul>
    <h3 className="c12" id="h.ihpu9074lumd">
      <span className="c2">
        4. Mode et dur&eacute;e de conservation des donn&eacute;es personnelles
      </span>
    </h3>
    <p className="c6">
      <span className="c5">
        Lorsque l&rsquo;Utilisateur soumet une demande de devis, ses
        donn&eacute;es personnelles sont conserv&eacute;es 3 ans &agrave;
        compter de la date de cr&eacute;ation de la demande.
      </span>
    </p>
    <p className="c6">
      <span className="c5">
        Lorsque un Prestataire cr&eacute;e un compte, ses donn&eacute;es
        professionnelles sont conserv&eacute;es jusqu&rsquo;&agrave; ce
        qu&rsquo;il en fasse la suppression depuis son acc&egrave;s.
      </span>
    </p>
    <p className="c6">
      <span className="c5">
        Ses donn&eacute;es peuvent &eacute;galement &ecirc;tre supprim&eacute;es
        d&rsquo;office par la Plateforme &agrave; l&rsquo;issue d&rsquo;une
        p&eacute;riode d&rsquo;inactivit&eacute; de plus de 6 mois.
      </span>
    </p>
    <p className="c6">
      <span>
        La Plateforme veille &agrave; s&eacute;curiser les donn&eacute;es
        personnelles des Utilisateurs de mani&egrave;re ad&eacute;quate et
        appropri&eacute;e et a pris les pr&eacute;cautions utiles afin de
        pr&eacute;server la s&eacute;curit&eacute; et la confidentialit&eacute;
        des donn&eacute;es et notamment emp&ecirc;cher qu&rsquo;elles ne soient
        d&eacute;form&eacute;es, endommag&eacute;es ou communiqu&eacute;es
        &agrave; des personnes non autoris&eacute;es.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        La Plateforme peut &eacute;galement communiquer les donn&eacute;es
        personnelles afin de coop&eacute;rer avec les autorit&eacute;s
        administratives et judiciaires.
      </span>
    </p>
    <p className="c0 c8">
      <span className="c5"></span>
    </p>
    <p className="c0 c8">
      <span className="c5"></span>
    </p>
    <h3 className="c0">
      <span className="c2">5. Sous-traitants</span>
    </h3>
    <p className="c0">
      <span className="c5">
        Les donn&eacute;es personnelles des Utilisateurs sont trait&eacute;es
        par la Plateforme et par les prestataires qui soutiennent
        l&#39;activit&eacute; de la Plateforme notamment :
      </span>
    </p>
    <ul className="c11 lst-kix_p97i75fs5k37-0 start">
      <li className="c0 c4">
        <span className="c5">
          l&rsquo;h&eacute;bergeur des donn&eacute;es : La soci&eacute;t&eacute;
          Tizzo S&agrave;rl, Chemin de la Marni&egrave;re 29, 2068 Hauterive -
          Suisse, n&deg; de registre du commerce CH-645.4.095.794-8, agissant en
          tant que sous-traitants.
        </span>
      </li>
      <li className="c0 c4">
        <span className="c5">
          la soci&eacute;t&eacute; de d&eacute;veloppement : Camillab.co
          Herv&eacute; Rincent, 3 Rue de Franche Compte Cherbourg, 50100
          Cherbourg en Cotentin - France, n&deg; de SIRET 825 172 462 00036,
          agissant en tant que sous-traitants
        </span>
      </li>
    </ul>
    <p className="c0">
      <span className="c5">
        Dans le cadre de leur relation contractuelle, chaque sous-traitant
        s&rsquo;engage &agrave; respecter la r&eacute;glementation en vigueur au
        titre de l&#39;article 28.1 (EU RGPD &ldquo;Sous-traitant&rdquo;, et
        &agrave; agir selon les instructions &eacute;nonc&eacute;es.
      </span>
    </p>

    <h3 className="c0">
      <span className="c13">6. </span>
      <span className="c2">
        Obligations des Utilisateurs et des Prestataires
      </span>
    </h3>
    <p className="c0">
      <span className="c5">
        L&rsquo;Utilisateur et le Prestataire reconna&icirc;t que les
        donn&eacute;es personnelles qu&rsquo;il transmet sont valides,
        compl&egrave;tes, &agrave; jour et conformes au service qu&rsquo;il
        requiert.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        L&rsquo;Utilisateur et le Prestataire s&rsquo;engagent &agrave; ne pas
        porter atteinte &agrave; la vie priv&eacute;e et &agrave; la protection
        des donn&eacute;es personnelles de toute personne tierce et ainsi
        &agrave; ne pas communiquer &agrave; la Plateforme les donn&eacute;es de
        personnes tierces sans leur consentement.
      </span>
    </p>

    <h3 className="c0">
      <span className="c2">7. Droits des Utilisateurs</span>
    </h3>
    <p className="c6">
      <span className="c5">
        A tout moment, l&rsquo;Utilisateur dispose de certains droits concernant
        les informations personnelles que nous poss&eacute;dons &agrave; son
        &eacute;gard. Il peut notamment :{" "}
      </span>
    </p>
    <ul className="c11 lst-kix_h4yjrukr42u1-0">
      <li className="c6 c4">
        <span className="c5">
          Acc&eacute;der &agrave; l&rsquo;ensemble de ses donn&eacute;es ;
        </span>
      </li>
      <li className="c6 c4">
        <span className="c5">
          Modifier, supprimer, actualiser ses donn&eacute;es ;{" "}
        </span>
      </li>
      <li className="c6 c4">
        <span className="c5">
          Limiter un ou plusieurs traitement dans le sens des C.G de la
          Plateforme ;.
        </span>
      </li>
      <li className="c6 c4">
        <span className="c5">
          Demander la portabilit&eacute; de ses donn&eacute;es ;{" "}
        </span>
      </li>
    </ul>
    <p className="c0">
      <span className="c5">
        Pour exercer ces droits, nous invitons l&rsquo;Utilisateur &agrave; nous
        contacter en pr&eacute;cisant son nom, pr&eacute;nom, adresse mail et
        adresse postale :
      </span>
    </p>
    <ul className="c11 lst-kix_bbsrpixdtzxz-0 start">
      <li className="c0 c4">
        <span className="c5">
          par email, &agrave; l&rsquo;adresse suivante : support@idhome.app
        </span>
      </li>
      <li className="c9 c4">
        <span className="c5">
          par courrier postal &agrave; l&rsquo;adresse suivante :
        </span>
      </li>
    </ul>
    <p>IDhome.app - KODE S&agrave;rl</p>
    <p className="c9">
      <span className="c5">
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Route de la Tsingale 4
      </span>
    </p>
    <p className="c9">
      <span className="c5">
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1046 Rueyres - Suisse
      </span>
    </p>
    <p className="c9 c8">
      <span className="c5"></span>
    </p>

    <h3 className="c12" id="h.v7ecx1jrouoi">
      <span className="c2">8. Utilisation des cookies</span>
    </h3>
    <p className="c0">
      <span className="c5">
        La Plateforme informe les Utilisateurs que nous utilisons des cookies et
        marqueurs tags afin de collecter des informations sur les utilisateurs
        de la Plateforme, les destinataires de nos communications
        &eacute;lectroniques, ainsi que tout autre individu qui a montr&eacute;
        un int&eacute;r&ecirc;t particulier pour nos produits et services.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        Certains cookies pr&eacute;sents sur la Plateforme sont plac&eacute;s
        directement sous notre contr&ocirc;le, d&rsquo;autres cookies et
        marqueurs tags peuvent toutefois d&eacute;pendre d&rsquo;entit&eacute;s
        tierces.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        Les cookies servent &agrave; am&eacute;liorer l&rsquo;utilisation et le
        fonctionnement de la Plateforme, mais aussi &agrave; mieux comprendre
        comment la Plateforme est utilis&eacute; par les Utilisateurs et les les
        Prestataires.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        Un message d&rsquo;alerte demande &agrave; chaque personne visitant la
        Plateformes, au pr&eacute;alable, si elle souhaite accepter les cookies.
        Ces cookies ne contiennent pas d&rsquo;informations confidentielles
        concernant les Utilisateurs.
      </span>
    </p>
    <p className="c0">
      <span>Les </span>
      <span className="c5">cookies servent :</span>
    </p>
    <ul className="c11 lst-kix_pk0zqv9mwa2d-0 start">
      <li className="c0 c4">
        <span className="c5">
          &agrave; ne pas re-saisir vos identifiant et mot de passe pour vous
          connecter
        </span>
      </li>
      <li className="c0 c4">
        <span className="c5">analyser le trafic de notre site web</span>
      </li>
      <li className="c0 c4">
        <span className="c5">
          &agrave; m&eacute;moriser les informations relatives, par exemple,
          &agrave; un formulaire que vous avez rempli ou &agrave; un service ou
          une information que vous avez choisis,
        </span>
      </li>
      <li className="c0 c4">
        <span className="c5">
          &eacute;valuer les m&eacute;thodes d&rsquo;efficacit&eacute; des
          offres cibl&eacute;es
        </span>
      </li>
      <li className="c0 c4">
        <span className="c5">
          de m&eacute;moriser les pr&eacute;f&eacute;rences d&rsquo;affichage de
          votre terminal (langue utilis&eacute;e, param&egrave;tres
          d&rsquo;affichage de votre terminal) et d&rsquo;en tenir compte
          lorsque de vos visites sur notre site et nos services selon la charte
          graphique et les logiciels de visualisation ou de lecture que comporte
          votre terminal
        </span>
      </li>
      <li className="c0 c4">
        <span className="c5">
          r&eacute;aliser des statistiques anonymes de visites
        </span>
      </li>
      <li className="c0 c4">
        <span className="c5">
          effectuer des publicit&eacute;s cibl&eacute;es sur les moteurs de
          recherches et les r&eacute;seaux sociaux en fonction des
          pr&eacute;f&eacute;rences des Utilisateurs
        </span>
      </li>
    </ul>
    <p className="c0">
      <span className="c5">
        Les Utilisateurs ont le choix d&rsquo;accepter ou de refuser leur
        utilisation en cliquant sur le lien pr&eacute;sent sur dans bandeau
        d&rsquo;information lors de l&rsquo;utilisation de la Plateforme.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        Les Utilisateurs peuvent &agrave; tout moment choisir de
        d&eacute;sactiver les cookies et autres traceurs.{" "}
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        Leur navigateur peut &ecirc;tre param&eacute;tr&eacute; pour signaler
        les cookies qui sont d&eacute;pos&eacute;s dans leur terminal et de les
        accepter ou non.
      </span>
    </p>
    <p className="c0">
      <span className="c5">
        La configuration de chaque navigateur est diff&eacute;rente. Elle est
        d&eacute;crite dans le menu d&#39;aide du navigateur, qui permettra
        &agrave; l&rsquo;Utilisateur de savoir de quelle mani&egrave;re modifier
        ses souhaits en mati&egrave;re de cookies.
      </span>
    </p>
    <p className="c0 c8">
      <span className="c5"></span>
    </p>
    <p className="c0">
      <span className="c5">
        Pour la gestion des cookies et des choix, les Utilisateurs peuvent
        &eacute;galement consulter le menu d&rsquo;aide de leur navigateur :
      </span>
    </p>
    <ul className="c11 lst-kix_h9j32ytol16v-0 start">
      <li className="c0 c4">
        <span>Microsoft Internet Explorer :</span>
        <span>
          <a
            className="c1"
            href="https://www.google.com/url?q=http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies&amp;sa=D&amp;ust=1570554032727000"
          >
            &nbsp;
          </a>
        </span>
        <span className="c10">
          <a
            className="c1"
            href="https://www.google.com/url?q=http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies&amp;sa=D&amp;ust=1570554032727000"
          >
            http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
          </a>
        </span>
      </li>
    </ul>
    <ul className="c11 lst-kix_kz5lc2yztqgo-0 start">
      <li className="c0 c4">
        <span>Google Chrome :</span>
        <span>
          <a
            className="c1"
            href="https://www.google.com/url?q=http://support.google.com/chrome/bin/answer.py?hl%3Dfr%26hlrm%3Den%26answer%3D95647&amp;sa=D&amp;ust=1570554032728000"
          >
            &nbsp;
          </a>
        </span>
        <span className="c10">
          <a
            className="c1"
            href="https://www.google.com/url?q=http://support.google.com/chrome/bin/answer.py?hl%3Dfr%26hlrm%3Den%26answer%3D95647&amp;sa=D&amp;ust=1570554032729000"
          >
            http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647
          </a>
        </span>
      </li>
      <li className="c0 c4">
        <span>Safari :</span>
        <span>
          <a
            className="c1"
            href="https://www.google.com/url?q=http://docs.info.apple.com/article.html?path%3DSafari/3.0/fr/9277.html&amp;sa=D&amp;ust=1570554032729000"
          >
            &nbsp;
          </a>
        </span>
        <span className="c10">
          <a
            className="c1"
            href="https://www.google.com/url?q=http://docs.info.apple.com/article.html?path%3DSafari/3.0/fr/9277.html&amp;sa=D&amp;ust=1570554032730000"
          >
            http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html
          </a>
        </span>
      </li>
      <li className="c0 c4">
        <span>Firefox :</span>
        <span>
          <a
            className="c1"
            href="https://www.google.com/url?q=http://support.mozilla.org/fr/kb/Activer%2520et%2520d%25C3%25A9sactiver%2520les%2520cookies&amp;sa=D&amp;ust=1570554032730000"
          >
            &nbsp;
          </a>
        </span>
        <span className="c10">
          <a
            className="c1"
            href="https://www.google.com/url?q=http://support.mozilla.org/fr/kb/Activer%2520et%2520d%25C3%25A9sactiver%2520les%2520cookies&amp;sa=D&amp;ust=1570554032731000"
          >
            http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
          </a>
        </span>
      </li>
      <li className="c0 c4">
        <span>Op&eacute;ra :</span>
        <span>
          <a
            className="c1"
            href="https://www.google.com/url?q=http://help.opera.com/Windows/10.20/fr/cookies.html&amp;sa=D&amp;ust=1570554032731000"
          >
            &nbsp;
          </a>
        </span>
        <span className="c10">
          <a
            className="c1"
            href="https://www.google.com/url?q=http://help.opera.com/Windows/10.20/fr/cookies.html&amp;sa=D&amp;ust=1570554032732000"
          >
            http://help.opera.com/Windows/10.20/fr/cookies.html
          </a>
        </span>
      </li>
    </ul>
    <h3 className="c0">
      <span className="c13">
        9. S&eacute;curit&eacute; et stockage des donn&eacute;es
      </span>
    </h3>
    <p className="c17">
      <span>La Plateforme </span>
      <span className="c5">
        met en &oelig;uvre des mesures de s&eacute;curit&eacute; techniques et
        organisationnelles destin&eacute;es &agrave; prot&eacute;ger les
        informations personnelles trait&eacute;es dans le cadre des services.{" "}
      </span>
    </p>
    <p className="c0">
      <span>
        Les serveurs d&rsquo;h&eacute;bergement sur lesquels la Plateforme
        traite et stocke les bases de donn&eacute;es sont exclusivement
        situ&eacute;s en Suisse, aupr&egrave;s de l&rsquo;h&eacute;bergeur Tizoo
        S&agrave;rl.{" "}
      </span>
    </p>
    {history && <Button onClick={() => history.goBack()}>OK</Button>}
  </div>
);

const ProtectionDonnees = ({ modale = false, history }) => {
  const { Text } = Typography;
  const afficheModal = () =>
    Modal.info({
      title: "Données personnelles",
      width: "70%",
      content: <TexteProtectionDonnees modale={true} />,
      onOk() {},
    });

  if (modale)
    return (
      <Text underline onClick={() => afficheModal()}>
        Données personnelles
      </Text>
    );
  else
    return (
      <div style={{ marginTop: "3rem" }}>
        <Card>
          {" "}
          <TexteProtectionDonnees modale={false} history={history} />
        </Card>
      </div>
    );
};

export default withRouter(ProtectionDonnees);
