import React, { useState } from "react";
import { Modal, Card, Button, Row, Col, Input } from "antd";
import { monetaire } from "../../helpers/helpers";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useLocation, useHistory } from "react-router-dom";
import SafetyCertificateOutlined from "@ant-design/icons/SafetyCertificateOutlined";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const StripeCheckout = ({
  id,
  intentSecret,
  feedback,
  amount,
  currency,
  receipt_email,
  entreprise,
  libelle,
  type,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  // http://localhost:3000/packs?payment_intent=xxxx&payment_intent_client_secret=xxxx&redirect_status=succeeded
  let query = useQuery();
  let history = useHistory();
  if (query.get("payment_intent") && parseInt(query.get("pack")) === id) {
    const status = query.get("redirect_status");
    if (status === "succeeded") {
      feedback &&
        feedback({ error: false, text: "Votre paiement a été enregistré." });
    } else {
      feedback &&
        feedback({
          error: true,
          text: "Le paiement a échoué. Merci de contacter le support.",
        });
    }
    history.push("/packs");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Received unknown parameter: payment_method_data[billing_details][receipt_email]
    let result = {
      error: {
        message: "Le paiement a échoué. Merci de contacter le support.",
      },
    };

    if (type === "card") {
      result = await stripe
        .confirmCardPayment(intentSecret, {
          receipt_email,
          payment_method: {
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
              name: entreprise,
            },
          },
        })
        .catch((e) => console.log(e));
    }

    if (type === "bancontact") {
      result = await stripe
        .confirmBancontactPayment(intentSecret, {
          receipt_email,
          payment_method: {
            billing_details: {
              name: entreprise,
            },
          },
          return_url: `${window.location.origin}/packs?pack=${id}`,
        })
        .catch((e) => console.log(e));
    }

    setLoading(false);
    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      feedback && feedback({ error: true, text: result.error.message });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        feedback &&
          feedback({ error: false, text: "Votre paiement a été enregistré." });
      }
    }
  };

  return (
    <Modal
      centered
      title={
        <span style={{ color: "white" }}>
          <SafetyCertificateOutlined />
          &nbsp;{" "}
          {type === "bancontact"
            ? "Paiement par Bancontact"
            : "Secure payment by Stripe"}
        </span>
      }
      visible={intentSecret}
      closable
      footer={null}
      onCancel={() => feedback({ text: null })}
    >
      <p>
        Pack {libelle} :
        <b>
          &nbsp;
          {monetaire(amount / 100)} {currency}.
        </b>
      </p>
      <p>Votre reçu sera envoyé par mail à : {receipt_email}</p>
      <p>
        Votre facture au format PDF sera disponible dans le menu "Factures".
      </p>
      {type === "bancontact" && (
        <p>
          <Row>
            <Col span={4}>
              <img
                src="/assets/icon-bancontact-paiement.png"
                alt="Bancontact"
              />{" "}
            </Col>
            <Col span={20}>
              <small>
                Vous allez être redirigé chez Bancontact
                <br />
                Votre paiement est sécurisé conforme à la norme PCI DSS
              </small>
            </Col>
          </Row>
        </p>
      )}
      {type === "card" && (
        <p>
          <Row align="middle">
            <Col span={8}>
              <img src="/assets/icon-stripe-paiement.png" alt="Card" />{" "}
            </Col>
            <Col span={16}>
              <small>
                Vos données sont chiffrées avec les standards de sécurité PCI
                DSS
              </small>
            </Col>
          </Row>
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <Card style={{ marginBottom: "1rem" }}>
          {type === "card" && (
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => setIsComplete(e.complete)}
            />
          )}
          {type === "bancontact" && (
            <>
              <p>Nom et prénom du détenteur de la carte :</p>
              <Input
                id="name"
                name="name"
                required
                onChange={(e) => setIsComplete(!!e.target.value)}
              />
            </>
          )}
        </Card>
        <Row justify="center">
          <Button
            type="primary"
            htmlType="submit"
            disabled={!stripe || !isComplete}
            size="large"
            loading={loading}
          >
            Payer {monetaire(amount / 100)} {currency}
          </Button>
        </Row>
      </form>
    </Modal>
  );
};

export default StripeCheckout;
