import React from "react";
import { Popconfirm, Button } from "antd";
import {
  QuestionCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { pad } from "../../../../helpers/helpers";

const styleBoutonValiderOffre = {
  background: "#0a84b7",
  borderColor: "#0a84b7",
};
const styleBoutonOffreValidee = {
  background: "#82b53e",
  borderColor: "#82b53e",
};
const styleBoutonOffreRefusee = {
  background: "#ff4d4f",
  borderColor: "#ff4d4f",
};

const ValiderOffre = ({
  mettreAJourChoixClient,
  client,
  devis,
  etat,
  lectureSeule,
}) => {
  switch (etat) {
    case "selectionne":
      return (
        <Button type="primary" style={styleBoutonOffreValidee}>
          <CheckCircleOutlined /> Offre validée
        </Button>
      );
    case "refus":
      return (
        <Button type="primary" style={styleBoutonOffreRefusee}>
          <CloseCircleOutlined /> Offre refusée
        </Button>
      );
    case "achete":
      return (
        <Button type="primary" disabled>
          <CheckCircleOutlined /> Offre en cours
        </Button>
      );
    case "devis_envoye":
      if (lectureSeule) {
        return (
          <Button type="primary" style={styleBoutonValiderOffre}>
            <DoubleRightOutlined /> Offre proposée
          </Button>
        );
      }
      return (
        <Popconfirm
          title={`Vous allez sélectionner l'offre n° ${pad(devis, 5)}. 
                                Etes-vous sûr de votre choix ?`}
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={() =>
            mettreAJourChoixClient({ client: client, devis: devis })
          }
          cancelText="Non"
        >
          <Button type="primary" style={styleBoutonValiderOffre}>
            <DoubleRightOutlined /> Valider cette offre{" "}
          </Button>
        </Popconfirm>
      );
    default:
      return (
        <Button disabled>
          <CloseCircleOutlined /> Offre non sélectionnable{" "}
        </Button>
      );
  }
};

export default ValiderOffre;
