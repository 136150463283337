import React from "react";
import { Redirect } from "react-router-dom";
import { Table, Row, Col, Button, Card, List } from "antd";
import FiltrePrestations from "./FiltrePrestations";
import FiltreContactVilles from "./FitresContactVille";
import BoutonOffre from "./BoutonOffre";
import BoutonArchive from "./BoutonArchive";
import DetailLeadsAvecCoordonnees from "./DetailLeadsAvecCoordonnees";
import TitreSection from "../../layout/TitreSection";
import CustomExpandIcon from "../../layout/CustomExpandIcon";
import { useMobile } from "../../../helpers/helpers";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import DemandesAttentes from "../../demandes/Presentation/DemandesAttentes"

const ContactsLayout = (props) => {
  const {
    actions,
    loading,
    error,
    dataSourceFiltre,
    choix_prestations,
    afficheDetailMobile,
  } = props;
  const { isMobile } = useMobile();
  const columns = [
    {
      title: "Statut",
      render: (text, record) => (
        <BoutonOffre devis_id={record.devis_id} etat={record.etat_devis} />
      ),
      width: "25%",
    },
    {
      title: "Secteur",
      dataIndex: "prestation",
      width: "25%",
    },
    {
      title: "Nom",
      dataIndex: "nom",
      width: "11.2%",
    },
    {
      title: "Prénom",
      dataIndex: "prenom",
      width: "11.2%",
    },
    {
      title: "Ville",
      dataIndex: "ville_intervention",
      width: "20%",
    },

    {
      title: "Échéance client",
      dataIndex: "echeance",
      sorter: (a, b) => {
        const c =
          new Date(a.echeance).valueOf() - new Date(b.echeance).valueOf();
        return c;
      },
      render: (v) => new Date(v).toLocaleDateString(),
      width: "20%",
    },
    {
      title: "",
      render: (text, record) => (
        <BoutonArchive
          libelle=""
          icone={<DeleteOutlined />}
          devis_id={record.devis_id}
          actions={actions}
          loading={loading}
        />
      ),
      width: "5%",
    },
  ];

  const rowStyleMargin = { marginTop: "1rem", marginRight: ".25rem" };

  if (error) return <Redirect to="/login" />;

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <Row
        type="flex"
        justify="space-between"
        align="bottom"
        style={{ marginBottom: "1rem" }}
      >
        <Col xs={24} xl={16}>
          <Row gutter={{ md: 4, xl: 16 }} align="bottom">
            <Col xs={24} xl={8} style={rowStyleMargin}>
              <TitreSection
                titre="Mes contacts"
                accroche="Gérez vos contacts"
              />
            </Col>

            <Col xs={24} xl={7} style={rowStyleMargin}>
              <FiltrePrestations
                choix_prestations={choix_prestations}
                callback={(value) => actions.setFiltrePrestation(value)}
              />
            </Col>
            <Col xs={24} xl={7} style={rowStyleMargin}>
              <FiltreContactVilles
                callback={(value) => actions.setFiltre(value.toLowerCase())}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={0} xl={8}>
          <DemandesAttentes />
        </Col>
      </Row>

      <Card>
        {isMobile ? (
          <List
            itemLayout="vertical"
            dataSource={dataSourceFiltre}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    size="small"
                    key="bouton-voir-lead"
                    onClick={() => actions.toggleDetailMobile(item.devis_id)}
                  >
                    {item.devis_id === afficheDetailMobile
                      ? "- Détails"
                      : "+ Détails"}
                  </Button>,
                  <BoutonOffre
                    size="small"
                    key="bouton-offre-lead"
                    devis_id={item.devis_id}
                    etat={item.etat_devis}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={item.prestation}
                  description={
                    item.code_postal_intervention +
                    " - " +
                    item.ville_intervention
                  }
                />
                {item.devis_id === afficheDetailMobile && (
                  <DetailLeadsAvecCoordonnees record={item} />
                )}
              </List.Item>
            )}
          />
        ) : (
          <Table
            columns={columns}
           
            dataSource={dataSourceFiltre}
            expandedRowRender={(record) => (
              <DetailLeadsAvecCoordonnees record={record} />
            )}
            expandIcon={CustomExpandIcon}
            expandIconColumnIndex={6}
            indentSize={0}
            loading={loading}
            rowKey="id"
            style={{ overflowX: "auto" }}
            locale={{ sortTitle: "Trier", emptyText: "Aucune donnée" }}
          />
        )}
      </Card>
    </div>
  );
};

export default ContactsLayout;
