import React from "react";
import { Row, Col, Tooltip } from "antd";
import SectionApp from "./SectionApp";
import DerniersDevis from "./DerniersDevis";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import WalletOutlined from "@ant-design/icons/WalletOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import LigneInfo from "./LigneInfo";
import CarteInfo from "./CarteInfo";
import { t } from "../../../Internationalisation/traduction";
import { MessagesPromotions } from "./MessagesPromotions";
import {complementInfoSpam} from './complementInfoSpam'

function pluralize (texte, nombre) {
  const nombreFormatte = typeof nombre === "number" ? nombre : parseInt(nombre)
  if (nombreFormatte && typeof nombreFormatte === "number" && nombreFormatte > 1 && texte) {
    return `${texte}s`
  }
  return texte
}

const DashboardLayout = (props) => {
  const {
    compte,
    liste_demandes_ecartees,
    loading,
    entreprise,
    nb_demandes_recues,
    solde,
    liste_devis,
    achete,
    messages_promotion,
  } = props;
  return (
    <div>
      <div
        className="container-fluid container-mw-xxl chapter"
        style={{ marginTop: "3rem", marginBottom: "3rem" }}
      >
        <Row gutter={16} align="stretch">
          <Col xs={24} md={12} lg={8}>
            <div className="colonnes-cartes-dashboard">
              <CarteInfo titre="Avez-vous besoin d'aide ?">
                <LigneInfo
                  icon={<ExclamationCircleOutlined />}
                  libelle={"FAQs " + t("fr", "marque")}
                  texteLien="lire les FAQs"
                  lien="/support"
                />
                <LigneInfo
                  icon={<DownloadOutlined />}
                  libelle="Manuel de démarrage"
                  texteLien="télécharger le manuel"
                  lien={t("fr", "nom_fichier_manuel_utilisateur")}
                />
                <LigneInfo
                  icon={<MessageOutlined />}
                  libelle="Support en ligne"
                  texteLien="poser une question"
                  lien="/messages"
                />
              </CarteInfo>

              <DerniersDevis
                results={liste_devis}
                loading={loading}
                link={"/devis"}
                titre={
                  <span>
                    Demandes en attente&nbsp;
                    <Tooltip title="Les demandes en attente nécessitent une action de votre part. Depuis la page “Demandes disponibles”, participez ou refusez une demande proposée pour afficher la suivante.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              />
            </div>
          </Col>
          <Col xs={0} md={12} lg={8}>
            <div className="colonnes-cartes-dashboard">
              <CarteInfo titre="Application pour smartphone">
                <SectionApp />
              </CarteInfo>

              <DerniersDevis
                results={liste_demandes_ecartees}
                loading={loading}
                titre={
                  <span>
                    Demandes écartées&nbsp;
                    <Tooltip title="Vous avez écarté ces demandes, mais elles restent disponibles à l'achat dans votre onglet Demandes écartées. Il n'est jamais trop tard pour gagner un nouveau client!">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              />
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className="colonnes-cartes-dashboard">
              <CarteInfo titre={entreprise || ""} hauteurVariable={true}>
                <LigneInfo
                  libelle="Vos crédits actuels"
                  texteLien={solde + " crédits"}
                />
                <LigneInfo
                  icon={<WalletOutlined />}
                  texteLien="Recharger mon compte"
                  lien="/packs"
                />
                <LigneInfo
                  libelle="Prospects envoyés"
                  texteLien={nb_demandes_recues + pluralize(" demande", nb_demandes_recues)}
                />
                <LigneInfo
                  libelle="Nombre d'achats"
                  texteLien={achete + pluralize(" demande", achete)}
                />
                <LigneInfo
                  libelle="Adresse email"
                  texteLien={compte.email || "non renseignée"}
                  infobulle={complementInfoSpam}
                />
                <LigneInfo
                  icon={<MailOutlined />}
                  texteLien="Modifier mon email"
                  lien="/compte"
                />
                <LigneInfo
                  libelle="Secteurs"
                  texteLien="modifier"
                  lien="/compte"
                  tags={compte.prestations}
                />
                <LigneInfo
                  libelle="Régions"
                  texteLien="modifier"
                  lien="/compte"
                  tags={compte.regions}
                />
              </CarteInfo>
              {messages_promotion && messages_promotion.length ? (
                <CarteInfo titre="Actualité">
                  <MessagesPromotions messages={messages_promotion} />
                </CarteInfo>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardLayout;
