import React from "react";
import { Card, Popconfirm, Button, Space } from "antd";
import { useMobile } from "../../../../helpers/helpers";

const Archiver = ({ archiverLead, lienUnique }) => {
  const { isMobile } = useMobile();
  return (
    <Card style={{ backgroundColor: "#d9d9d9" }}>
      <h4>Suivi de votre demande de devis</h4>
      <p>Que souhaitez-vous faire auprès de nos partenaires ?</p>

      <Space>
        <a href={`/confirmation/${lienUnique}`}>
          <Button className="bouton-participe">
            {isMobile ? "Poursuivre" : "Poursuivre ma demande"}
          </Button>
        </a>
        <Popconfirm
          title="Etes-vous sûr de vouloir retirer votre annonce ?"
          onConfirm={() => archiverLead()}
          onCancel={() => null}
          okText="Oui"
          cancelText="Annuler"
        >
          <Button className="bouton-refus">
            {isMobile ? "Archiver" : "Archiver ma demande"}
          </Button>
        </Popconfirm>
      </Space>
    </Card>
  );
};

export default Archiver;
