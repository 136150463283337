import React, { useState } from "react";
import {
  Popover,
  Col,
  Row,
  Card,
  Input,
  Form,
  Button,
  Tabs,
  Alert,
} from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

import { useJsqel } from "../../../api/jsqel";
import { useFormulaireWebsite } from "../controleur/useFormulaireWebsite";
import UploadGalleryInput from "./UploadGalleryInput";
import InfobulleDns from "./InfobulleDns";
import CopierDansClipboard from "./CopierDansClipboard";
import EtatWebsite from "./EtatWebsite";
import "./infowebsite.scss";
import { nombreMax } from "../controleur/nombreMax";
import { FormulaireInformationsSite } from "./FormulaireInformationsSite";
import { FormulaireCouleursTemplate } from "./FormulaireCouleursTemplate";
import { FormulaireReseaux } from "./FormulaireReseaux";

const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    xl: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 24 },
    xl: { span: 16 },
  },
};

const formItemLayoutFullwidth = {
  wrapperCol: {
    span: 24,
  },
};

const tabItems = [
  { label: <small>Présentation</small>, key: "presentation" },
  { label: <small>A propos</small>, key: "apropos" },
  { label: <small>Services</small>, key: "services" },
  { label: <small>Autres</small>, key: "autres" },
];

const tabItemsMap = {
  presentation: "Présentation :",
  apropos: "A propos :",
  services: "Vos services :",
  autres: "Autres informations:",
};

const InfoWebsite = () => {
  const [ongletTexte, setOngletTexte] = useState("presentation");
  const {
    loading,
    key,
    configuration,
    etat,
    modifie,
    erreur,
    onChange,
    enregistrer,
  } = useFormulaireWebsite(useJsqel);

  const enLigne = etat === "online"

  return (
    <div
      className="container-fluid container-mw-xxl chapter"
      style={{ minHeight: "490px" }}
    >
      <Card>
        <Form
          onFinish={enregistrer}
          {...formItemLayout}
          labelAlign="left"
          onValuesChange={onChange}
          initialValues={configuration}
          key={key}
        >
          <Row className="border-bottom-liseret" gutter={16}>
            <Col span={24} xl={9}>
              Informations du site
            </Col>
            <Col span={0} xl={5}>
              Couleurs et template
            </Col>
            <Col span={0} xl={5}>
              Réseaux sociaux
            </Col>
            <Col span={0} xl={4}>
              URL et DNS
            </Col>
            <Col span={0} xl={1}>
              <Popover content={<InfobulleDns />} title={null}>
                <QuestionCircleOutlined style={{ color: "#a1a8b9" }} />
              </Popover>
            </Col>
          </Row>
          <Row gutter={16} align="top">
            <Col span={24} xl={9}>
              <FormulaireInformationsSite />
            </Col>
            <Col span={24} xl={5}>
              <FormulaireCouleursTemplate enLigne={enLigne}/>
            </Col>

            <Col span={24} xl={5}>
              <FormulaireReseaux />
              <Form.Item {...formItemLayoutFullwidth}>
                <EtatWebsite etat={etat} />
              </Form.Item>
            </Col>
            <Col span={24} xl={5}>
              <Form.Item name="url" {...formItemLayoutFullwidth}>
                <Input placeholder="URL du site" disabled={enLigne}/>
              </Form.Item>

              <Form.Item name="dns1" {...formItemLayoutFullwidth}>
                <Input
                  placeholder="dns1"
                  disabled
                  prefix={<CopierDansClipboard valeur={configuration.dns1} />}
                />
              </Form.Item>
              <Form.Item name="dns2" {...formItemLayoutFullwidth}>
                <Input
                  placeholder="dns2"
                  disabled
                  prefix={<CopierDansClipboard valeur={configuration.dns2} />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="border-bottom-liseret" gutter={16} align="middle">
            <Col span={0} xl={3}>
              Contenus
            </Col>
            <Col span={24} xl={11}>
              <Tabs
                items={tabItems}
                activeKey={ongletTexte}
                onChange={(newKey) => setOngletTexte(newKey)}
                size="small"
                tabBarStyle={{
                  backgroundColor: "white",
                  padding: 0,
                  margin: 0,
                  fontWeight: "300",
                }}
              />
            </Col>
            <Col span={0} xl={10}>
              Logo et images
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={0} xl={3}>
              {tabItemsMap[ongletTexte] || ""}
            </Col>
            <Col span={24} xl={11}>
              <Form.Item
                name="presentation"
                {...formItemLayoutFullwidth}
                hidden={ongletTexte !== "presentation"}
              >
                <Input.TextArea rows={5} placeholder="Entrez votre texte" />
              </Form.Item>
              <Form.Item
                name="apropos"
                {...formItemLayoutFullwidth}
                hidden={ongletTexte !== "apropos"}
              >
                <Input.TextArea rows={5} placeholder="Entrez votre texte" />
              </Form.Item>
              <Form.Item
                name="services"
                {...formItemLayoutFullwidth}
                hidden={ongletTexte !== "services"}
              >
                <Input.TextArea rows={5} placeholder="Entrez votre texte" />
              </Form.Item>
              <Form.Item
                name="autres"
                {...formItemLayoutFullwidth}
                hidden={ongletTexte !== "autres"}
              >
                <Input.TextArea rows={5} placeholder="Entrez votre texte" />
              </Form.Item>
            </Col>
            <Col span={24} xl={10}>
              <Form.Item
                name="images"
                {...formItemLayoutFullwidth}
                rules={[nombreMax(10)]}
              >
                <UploadGalleryInput />
              </Form.Item>
            </Col>
          </Row>
          {modifie ? (
            <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
              <Button
                className="bouton-participe"
                htmlType="submit"
                loading={loading}
              >
                Enregistrer
              </Button>
            </Form.Item>
          ) : null}
          {erreur ? (
            <Alert
              message="Oups, une erreur a empêché d'enregistrer vos modifications."
              type="error"
              showIcon
            />
          ) : null}
        </Form>
      </Card>
    </div>
  );
};

export default InfoWebsite;
