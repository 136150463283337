import React from "react";
import { Form, Input, Button, message } from "antd";
import UserOutlined from "@ant-design/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";

import { useJsqel } from "../../api/jsqel";
import { useStore } from "../../store/store";

const LoginFormulaire = (props) => {
  const { dispatch } = useStore();

  const next = (location) =>
    location &&
    location.search &&
    location.search.length &&
    location.search.startsWith("?next=")
      ? location.search.slice(6)
      : "";

  const connexionValide = async ({ results, error }) => {
    if (results && results.token && results.role) {
      await dispatch({ type: "LOGIN", ...results });
      message.success("Bienvenue !");
      props.history.push("/" + next(props.history.location));
    } else {
      message.error("Informations de connexions non valides");
      clear();
    }
  };

  const [, refresh, clear] = useJsqel("inscription/login", {
    sendItNow: false,
    callback: connexionValide,
  });

  const handleSubmit = (values) => refresh(values);

  return (
    <Form onFinish={handleSubmit} className="form-v1">
      <Form.Item
        name="username"
        rules={[
          { required: true, message: "Entrez votre adresse e-mail" },
          { type: "email", message: "Entrez un e-mail valide" },
        ]}
      >
        <Input
          size="large"
          prefix={<UserOutlined style={{ fontSize: 13 }} />}
          placeholder="email@entreprise.com"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Entrez votre mot de passe" }]}
      >
        <Input.Password
          size="large"
          prefix={<LockOutlined style={{ fontSize: 13 }} />}
          placeholder="Mot de passe"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-cta btn-block">
          Se connecter
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginFormulaire;
