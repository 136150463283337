import React from "react";
import { PropositionRepublication } from "./PropositionRepublication";

/*
const OffreExpiree = ({ sources }) => (
  <Result
    title="L'échéance de votre demande est dépassée"
    subTitle={<PropositionRepublication sources={sources} />}
    icon={<FrownOutlined style={{ color: "#fd8200" }} />}
  />
);
*/

export const OffreExpiree = ({
  libelleOffre,
  sources,
}) => (
  <>
    <h3 className="article-title-idhome">
      L'échéance de votre demande est dépassée. 
    </h3>
    <div className="divider border-primary mb-4"></div>
    <p>
      <PropositionRepublication sources={sources} />
      <br/>
      {libelleOffre ? "Ci-dessous les offres qui ont été proposées par les fournisseurs. Vous ne pouvez plus les sélectionner." : ""}
    </p>
  </>
);


export default OffreExpiree;
