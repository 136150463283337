import React from "react";
import { Row, Col } from "antd";
import Loading from "../../../layout/Loading";
import OffreClient from "./OffreClient";
import LayoutPageClient from "../../PresentationCommun/LayoutPageClient";
import OffreExpiree from "./OffreExpiree";
import OffreArchivee from "./OffreArchivee";
import { t } from "../../../../Internationalisation/traduction";

import AideEnLigneClient from "../../../aide/AideEnLigneClient";
import QuestionsFrequentes from "../../PresentationCommun/QuestionsFrequentes";
import DureeValidite from "../../PresentationCommun/DureeValidite";
import ReservezEnLigne from "../../PresentationCommun/ReservezEnLigne";
import Archiver from "./Archiver";
import { HeaderOffres } from "./HeaderOffres";

const ClientLayout = (props) => {
  const {
    etatLead,
    etatAffichageLead,
    prenomLead,
    offres,
    sources,
    echeanceDepasse,
    lien_unique,
    echeance,
    actions,
    aideEnLigne,
    offreValidee,
  } = props;

  if (offres.loading || offres.error) return <Loading />;

  return (
    <LayoutPageClient>
      {etatAffichageLead === "LeadActif" && !offreValidee ? (
        <Row
          style={{ paddingTop: "2.7rem" }}
          gutter={8}
          className="cover-liseret"
        >
          <Col xs={24} xl={16}>
            <h3 className="article-title-idhome">Bonjour {prenomLead}</h3>
            <p>Avez-vous trouvé un professionnel pour votre demande ?</p>
            <p>
              Avec {t("fr", "marque")}, vous pouvez valider une offre
              d'entreprise, poursuivre votre demande si cette dernière est
              toujours d'actualité, ou archiver votre demande afin de stopper sa
              diffusion.
            </p>
          </Col>
          <Col xs={24} xl={8}>
            <Archiver
              archiverLead={actions.archiverLead}
              lienUnique={lien_unique}
            />
            <br />
          </Col>
        </Row>
      ) : null}
      <Row style={{ paddingTop: "1rem" }} gutter={8}>
        <Col xs={24} xl={16}>
          {/* header indiquant le nb d'offres reçues */}
          {/* Si l'échéance est dépassée, on veut quand même continuer à afficher les offres */}
          {/* Mais si le lead est archivé, on ne souhaite plus rien afficher */}

          {etatAffichageLead === "LeadActif" ? (
            <HeaderOffres
              libelleOffre={offres.results.length && offres.results[0].libelle}
            />
          ) : null}

          {/* Offre datant de + de 30 jours -> pseudo 404  */}
          {etatAffichageLead === "LeadArchive" ? (
            <OffreArchivee sources={sources} />
          ) : null}

          {/* Offre expirée */}
          {etatAffichageLead === "LeadObsolete" ? (
            <OffreExpiree
              sources={sources}
              libelleOffre={offres.results.length && offres.results[0].libelle}
            />
          ) : null}

          {/* Liste des offres */}
          {offres.results &&
          offres.results.length &&
          etatAffichageLead !== "LeadArchive"
            ? offres.results
                .sort(actions.triDevis)
                .map((r, i) => (
                  <OffreClient
                    key={i}
                    offre={{ ...r, numero: i + 1, id_client: lien_unique }}
                    actions={actions}
                    lectureSeule={etatAffichageLead === "LeadObsolete"}
                  />
                ))
            : null}
        </Col>

        <Col xs={24} xl={8}>
          {etatLead !== "archive" && (
            <>
              <ReservezEnLigne />
              <br />
              <DureeValidite
                echeanceDepasse={echeanceDepasse}
                echeance={echeance}
              />
              <br />
            </>
          )}
          <QuestionsFrequentes scrollToAide={() => actions.scrollToAide()} />
        </Col>
      </Row>

      <div ref={aideEnLigne}>
        <AideEnLigneClient callback={() => actions.scrollToTop()} />
      </div>
    </LayoutPageClient>
  );
};

export default ClientLayout;
