import download from "downloadjs";
import axios from "axios";
import urlApi from "./url";

function downloadPDF({ fileName, routeAPI }) {
  const token = window.localStorage.getItem("idhome_token");

  axios
    .get(`${urlApi()}${routeAPI}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob", // had to add this one here
    })
    .then((response) => {
      const content = response.headers["content-type"];
      if (content === "application/pdf") {
        download(response.data, fileName, content);
      }
    })
    .catch((error) => console.log(error));
}

export { downloadPDF };
