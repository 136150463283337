import React from "react";
import { Card, Button } from "antd";
import { QuestionCircleOutlined, MailOutlined } from "@ant-design/icons";
import { t } from "../../../Internationalisation/traduction";

const QuestionsFrequentes = ({ scrollToAide }) => {
  return (
    <Card>
      <h4>
        <QuestionCircleOutlined style={{ color: "#82B53F" }} /> Questions
        fréquentes
      </h4>
      <div className="divider  border-primary mb-4"></div>
      <span>
        Comment ça marche ? Comment annuler une offre ? Consulter notre
        <Button
          style={{ padding: 4, height: "inherit" }}
          type="link"
          onClick={() => scrollToAide()}
        >
          {" "}
          aide en ligne
        </Button>
      </span>
      <p>
        <b>Contactez-nous</b>
        <br />
        <MailOutlined /> {t("fr", "email_site")}
      </p>
    </Card>
  );
};

export default QuestionsFrequentes;
