import React from "react";
import { Row, Col } from "antd";
import LayoutPageClient from "../../PresentationCommun/LayoutPageClient";
import Loading from "../../../layout/Loading";
import FormulaireEnquete from "./FormulaireEnquete";
import MessageRemerciement from "./MessageRemerciement";
import MessagePublierAnnonce from "./MessagePublierAnnonce";
import MessageRepublierAnnonce from "./MessageRepublierAnnonce";
import ListeDesOffres from "./ListeDesOffres";
import QuestionsFrequentes from "../../PresentationCommun/QuestionsFrequentes";
import AideEnLigneClient from "../../../aide/AideEnLigneClient";
import { EtatFeedback } from "../controller/EtatFeedback";
import { useScrollToTop } from "../../../aide/useScrollToTop";

const FeedbackComposant = ({
  etat,
  offres,
  devisNote,
  actions,
  lien_unique,
}) => {
  switch (etat) {
    case EtatFeedback.AUCUN_DEVIS_SELECTIONNE:
      return (
        <ListeDesOffres
          offres={offres}
          client={lien_unique}
          actions={actions}
        />
      );
    case EtatFeedback.ENQUETE_SATISFACTION:
      return <FormulaireEnquete offre={devisNote} actions={actions} />;
    case EtatFeedback.ENQUETE_SATISFACTION_RENSEIGNEE:
      return <MessageRemerciement />;
    case EtatFeedback.PROPOSITION_REPUBLICATION:
      return <MessageRepublierAnnonce />;
    case EtatFeedback.INITIALISATION:
    default:
      return <MessagePublierAnnonce />;
  }
};

const FeedbackLayout = (props) => {
  const { etat, loading, offres, devisNote, actions, lien_unique } = props;
  const { scrollToAide, scrollToTop, aideEnLigne } = useScrollToTop();

  if (loading) return <Loading />;

  return (
    <LayoutPageClient>
      <Row style={{ paddingTop: "1rem" }}></Row>
      <Row gutter={16}>
        <Col xs={24} xl={16}>
          <FeedbackComposant
            etat={etat}
            offres={offres}
            devisNote={devisNote}
            actions={actions}
            lien_unique={lien_unique}
          />
        </Col>
        <Col xs={24} xl={8}>
          <QuestionsFrequentes scrollToAide={() => scrollToAide()} />
        </Col>
      </Row>
      <div ref={aideEnLigne}>
        <AideEnLigneClient callback={() => scrollToTop()} />
      </div>
    </LayoutPageClient>
  );
};

export default FeedbackLayout;
