import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import UserOutlined from "@ant-design/icons/UserOutlined";

import { useJsqel } from "../../api/jsqel";

const NouveauPwdFormulaire = (props) => {
  const [merci, setMerci] = useState(null);

  const connexionValide = ({ error }) => {
    if (error) {
      setMerci(
        "Une erreur nous empèche de vous adresser un mail de ré-initialisation. Merci de contacter votre administrateur"
      );
      clear();
    } else {
      setMerci(
        "Nous vous avons adressé un email pour ré-initialiser votre mot de passe. Vous le recevrez dans moins de 5 minutes."
      );
    }
  };

  const [, refresh, clear] = useJsqel("inscription/new_pwd", {
    sendItNow: false,
    callback: connexionValide,
  });

  if (merci) return <p>{merci}</p>;

  return (
    <Form onFinish={refresh} className="form-lost-pwd" layout="vertical">
      <Form.Item
        name="email"
        label="Indiquez votre email pour réinitialiser votre mot de passe :"
        required={false}
        extra="Vérifiez votre boîte email et vos spams, la procédure d'envoi peut durer jusqu'à 5mn avant de redemander un nouveau mot de passe."
        rules={[
          { required: true, message: "Entrez votre adresse e-mail" },
          { type: "email", message: "Entrez un e-mail valide" },
        ]}
      >
        <Input
          size="large"
          prefix={<UserOutlined style={{ fontSize: 13 }} />}
          placeholder="email@entreprise.com"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-cta btn-block">
          Réinitialiser
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NouveauPwdFormulaire;
