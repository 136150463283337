import { useState } from "react";
import { useCredit } from "../../../api/credits";

function useDemandesEcartees({ useJsqel }) {
  // Affichage du detail
  const [idleadDetail, setIdleadDetail] = useState(null);

  // Solde des crédits et des refus
  const [refreshCredits] = useCredit();

  // Liste des leads écartés
  const [leads, refreshLeads] = useJsqel("leads/get_leads_ecartes", {
    sendItNow: true,
    callback: refreshCredits,
  });

  const listeLeads = leads.results;

  const [achat_leads, refreshAchatLeads] = useJsqel("leads/achat_leads", {
    sendItNow: false,
    callback: refreshLeads,
  });

  const actions = {
    refreshLeads,
    refreshAchatLeads: (id) => refreshAchatLeads({ id }),
    clicDetailLead: (idLead) =>
      idLead === idleadDetail ? setIdleadDetail(null) : setIdleadDetail(idLead),
  };

  return {
    actions,
    loading: leads.loading && achat_leads.loading,
    listeLeads,
    error: leads.error,
    idleadDetail,
  };
}

export { useDemandesEcartees };
