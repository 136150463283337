import React from "react";
import { Row, Col, Modal, Alert } from "antd";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import Cadenas from "../../layout/Cadenas";
import HeaderLead from "./HeaderLead";
import { useStore } from "../../../store/store";
import InformationsClients from "./InformationsClients"

const complementInfoDelai = () =>
  Modal.info({
    title: "A quoi correspond ce délai ?",
    content: (
      <div>
        <p>
          Nous incitons nos partenaires à répondre rapidement aux demandes
          arrivées dans le but d’une prise de contact rapide avec le client.
        </p>
        <p>
          Les demandes non répondues dans le temps imparti sont automatiquement
          placées en <b>demandes écartées</b>. Vous pourrez toujours les
          retrouver dans cet onglet pour répondre aux clients.
        </p>
      </div>
    ),
    onOk() {},
    okText: "Compris !",
  });

const complementInfoRefus = (solde_refus) => () =>
  Modal.info({
    title: "A quoi correspondent ces frais ?",
    content: (
      <div>
        <p>
          Votre pack crédit, vous permet de refuser {solde_refus || 0}{" "}
          proposition de clients.
        </p>
        <p>
          Passez cette limite vous serez pénalisé de 0.1 crédit à chaque refus.
        </p>
      </div>
    ),
    onOk() {},
    okText: "Compris !",
  });


const style = {
  titre: {
    color: "rgba(49, 49, 49, 0.85",
    fontWeight: "700",
    padding: "16px 8px",
  },
  header: {
    marginLeft: "-2px",
    marginRight: "-2px",
    background: "#EEEEEE",
    padding: "16px 8px",
    alignItems: "center",
  },
  section: { fontWeight: "700", marginTop: "1rem" },
  compteur: {
    marginTop: "1rem",
    background: "#fafafa",
    padding: "1rem",
    borderRadius: "5px",
    marginRight: "auto",
  },
};
const DetailLeads = ({
  record,
  nbHeuresRestantes,
  alerte = false,
  actions,
  nbJoursRestants,
  loading,
}) => {
  const { state } = useStore();
  const solde_refus = state.solde_refus;
  return (
    <>
      <HeaderLead
        id={record.id}
        prestation={record.prestation}
        logo_prestation={record.vignette}
        code_postal_intervention={record.code_postal_intervention}
        ville_intervention={record.ville_intervention}
        actions={actions}
        credit={record.prix}
        loading={loading}
      />
      <Row>
        <Col xs={24} xl={6}>
          <Row style={style.section} className="couleur-primaire">
            Dates et échéances
          </Row>
          <Row>
            <b>Publication de la demande :</b>{" "}
            {new Date(record.date_validation).toLocaleDateString()}
          </Row>
          <Row>
            <b>Echéance du client :</b>{" "}
            {new Date(record.echeance).toLocaleDateString()}
          </Row>

          <Row style={style.section} className="couleur-primaire">
            Informations
          </Row>
          <Row align="middle">
            <QuestionCircleOutlined
              onClick={complementInfoRefus(solde_refus)}
            />{" "}
            &nbsp; <b>Refus de travaux :</b>
            {solde_refus || 0} refus restants
          </Row>

          <Row style={style.section} className="couleur-primaire">
            Prononcez-vous sur cette demande d'ici :
          </Row>
          <Row>
            <p>
              {nbJoursRestants > 0 && (
                <span>
                  <b>{nbJoursRestants}</b> jours{" "}
                </span>
              )}
              <span>
                <b>{nbHeuresRestantes}</b> heures
              </span>
              &nbsp;
              <QuestionCircleOutlined onClick={complementInfoDelai} />
            </p>
          </Row>
          {alerte ? (
            <Row>
              <Alert
                message="Alerte Information"
                description={
                  <span>
                    Une demande client avec une échéance courte vient d'arriver.
                    <br />
                    Veuillez traiter vos demandes en attente pour la consulter.
                  </span>
                }
                type="error"
              />
            </Row>
          ) : null}
        </Col>

        <Col xs={24} xl={7}>
          <Row style={style.section} className="couleur-primaire">
            Coordonnées du client
          </Row>
          <Row>
            <b>Nom : </b>
            <Cadenas />
          </Row>
          <Row>
            <b>Prénom : </b>
            <Cadenas />
          </Row>
          <Row>
            <b>N° de téléphone : </b>
            <Cadenas />
          </Row>
          <Row>
            <b>Email : </b>
            <Cadenas />
          </Row>
          <Row>
            <b>Adresse : </b>
            <Cadenas />
          </Row>
          {record.ville_destination && (
            <Row>
              <b>Adresse de destination : </b>
              <Cadenas />
            </Row>
          )}
          {record.ville_destination && (
            <Row>
              <b>Ville de destination :</b> {record.ville_destination}
            </Row>
          )}
          {record.code_postal_destination && (
            <Row>
              <b>Code postal de destination : </b>{" "}
              {record.code_postal_destination}
            </Row>
          )}
        </Col>
        <Col xs={24} xl={11}>
          <Row style={style.section} className="couleur-primaire">
            Informations du client
          </Row>
          <Row>
            <Col>
              {record.detail
                ? <InformationsClients detail={record.detail} /> 
                : "Pas de détail"}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DetailLeads;
