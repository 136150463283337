import React, { useState } from "react";
import { Input, Row, Col, Button } from "antd";
import { useJsqel } from "../../api/jsqel";
import MessageOutlined from "@ant-design/icons/MessageOutlined";

const NouveauMessage = ({ fermeture, setFermeture }) => {
  const [{ loading }, refresh] = useJsqel("chat/nouveau_message", {
    sendItNow: false,
  });
  const [message, setMessage] = useState("");

  const handleSend = () => {
    refresh({
      libelle: message,
      callback: ({ error }) => {
        if (!error) {
          setMessage("");
          setFermeture(true);
        }
      },
    });
  };

  if (fermeture) return null;

  return (
    <article className={"tl-item alt"}>
      <div className="tl-body">
        <div className="tl-entry">
          <div className="tl-time">Maintenant</div>
          <div className="tl-icon icon-btn-round icon-btn icon-btn-thin btn-info">
            <MessageOutlined />
          </div>
          <div className="tl-content">
            <h4 className="tl-title text-primary">Nouveau message</h4>
            <p>
              <Input.TextArea
                placeholder="Ecrivez votre message ..."
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                onPressEnter={handleSend}
              />
            </p>
            <Row gutter={16}>
              <Col md={24} xl={12}>
                {" "}
                <Button
                  type="primary"
                  block
                  loading={loading}
                  onClick={handleSend}
                >
                  Envoyer
                </Button>{" "}
              </Col>
              <Col md={24} xl={12}>
                {" "}
                <Button block onClick={() => setFermeture(true)}>
                  Annuler
                </Button>{" "}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </article>
  );
};

export default NouveauMessage;
